import React, { useEffect, useState } from "react";
import CustomerProduct from "./Customer&Product/CustomerProduct";
import axios from "axios";
import { getAllProducts } from "../../../constants/Api/Api";
import { getproducts } from "../../../redux/posSlice/ProductsSlice";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { Bounce, toast } from "react-toastify";
import { BucketManegment } from "../../../redux/posSlice/BucketSlice";
import { gettingListofHoldOrder } from "../../../redux/posSlice/HoldOrderSlice";
import HoldOrder from "./Customer&Product/HoldOrder";
import { BucketMenegeAction } from "../../../redux/posSlice/BucketMenegeSlice";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const PosInitialUI = () => {
  const dispatch = useDispatch();

  const { products, status } = useSelector((a) => a.PosDetail);
  const { bucketId: bucId } = useSelector((a) => a.AboutBucket);
  const ans = useSelector((a) => a.BucketControl);

  useEffect(() => {
    dispatch(getproducts());
  }, []);

  const clickOnEveryVerient = (detail, bucketid) => {
    // console.log(bucketid, "test");
    if (bucketid) {
      dispatch(
        BucketManegment({
          productId: detail?.productId?._id,
          variantId: detail?._id,
          quantity: 1,
          bucketId: bucketid,
        })
      );
    } else {
      dispatch(
        BucketManegment({
          productId: detail?.productId?._id,
          variantId: detail?._id,
          quantity: 1,
        })
      );
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const postsperPage = 8;
  let firstparamsofslice = (currentPage - 1) * postsperPage;
  let secondparamsofslice = postsperPage * currentPage;

  const paginateProducts = products?.slice(
    firstparamsofslice,
    secondparamsofslice
  );

  return (
    <>
      {/* {console.log(products, "chek")} */}
      <div className="outr-rght-content">
        <div className="sls_flx_cntnt">
          <div className="sls_lft_drv">
            <div className="top_drv">
              <div className="top_ps_imgs">
                <button className="top_ps_prt text-center">
                  <div className="ps_prt_pic">
                    <img
                      src={require("../../../assets/images-pos/fd_1.png")}
                      alt="img"
                    />
                  </div>
                  <div className="ps_prt_txt">
                    <span>Food</span>
                  </div>
                </button>
                <button className="top_ps_prt text-center">
                  <div className="ps_prt_pic">
                    <img
                      src={require("../../../assets/images-pos/fd_2.png")}
                      alt="img"
                    />
                  </div>
                  <div className="ps_prt_txt">
                    <span>Food</span>
                  </div>
                </button>
                <button className="top_ps_prt text-center">
                  <div className="ps_prt_pic">
                    <img
                      src={require("../../../assets/images-pos/fd_3.png")}
                      alt="img"
                    />
                  </div>
                  <div className="ps_prt_txt">
                    <span>Food</span>
                  </div>
                </button>
                <button className="top_ps_prt text-center">
                  <div className="ps_prt_pic">
                    <img
                      src={require("../../../assets/images-pos/fd_4.png")}
                      alt="img"
                    />
                  </div>
                  <div className="ps_prt_txt">
                    <span>Food</span>
                  </div>
                </button>
                <button className="top_ps_prt text-center">
                  <div className="ps_prt_pic">
                    <img
                      src={require("../../../assets/images-pos/shp.png")}
                      alt="img"
                    />
                  </div>
                  <div className="ps_prt_txt">
                    <span>Shop</span>
                  </div>
                </button>
                <button className="top_ps_prt text-center">
                  <div className="ps_prt_pic">
                    <img
                      src={require("../../../assets/images-pos/elc.png")}
                      alt="img"
                    />
                  </div>
                  <div className="ps_prt_txt">
                    <span>Electonics</span>
                  </div>
                </button>
                <button className="top_ps_prt text-center">
                  <div className="ps_prt_pic">
                    <img
                      src={require("../../../assets/images-pos/grc.png")}
                      alt="img"
                    />
                  </div>
                  <div className="ps_prt_txt">
                    <span>Grocery</span>
                  </div>
                </button>
                <button className="top_ps_prt text-center">
                  <div className="ps_prt_pic">
                    <img
                      src={require("../../../assets/images-pos/csms.png")}
                      alt="img"
                    />
                  </div>
                  <div className="ps_prt_txt">
                    <span>Cosmetics</span>
                  </div>
                </button>
                <button className="top_ps_prt text-center">
                  <div className="ps_prt_pic">
                    <img
                      src={require("../../../assets/images-pos/expt.png")}
                      alt="img"
                    />
                  </div>
                  <div className="ps_prt_txt">
                    <span>Sports</span>
                  </div>
                </button>
              </div>
              <h6>Search for products</h6>
              <div className="sls_srch_flx">
                <div className="drv_rght_rte">
                  <input type="search" placeholder="Type product name" />
                  <div className="tp_nw_bl">
                    <button className="nw_bl_svg">
                      <img
                        src={require("../../../assets/images-pos/Vector.png")}
                        alt="img"
                      />
                    </button>
                    <button className="nw_bl_svg">
                      <img
                        src={require("../../../assets/images-pos/Vector_1.png")}
                        alt="img"
                      />
                    </button>
                    <button className="nw_bl_svg">
                      <img
                        src={require("../../../assets/images-pos/Vector_2.png")}
                        alt="img"
                      />
                    </button>
                    <button className="nw_bl_svg">
                      <img
                        src={require("../../../assets/images-pos/Vector_3.png")}
                        alt="img"
                      />
                    </button>
                    <button className="nw_bill">
                      New Bill<span>+</span>
                    </button>
                  </div>
                </div>
              </div>

              {status == "loading" && (
                <>
                  {" "}
                  <div className="loader"></div> <h1>PLease Wait ...</h1>
                </>
              )}

              <div className="dscls_ordrs">
                <div className="dscls_ordrs_flx">
                  {paginateProducts?.map((pro, ind) => {
                    // console.log(pro, "pro");
                    return (
                      <>
                        <button
                          style={{
                            opacity: pro.quantity < 1 ? "0.4" : 1,
                          }}
                          key={pro?._id}
                          className="dscls_ordrs_prt"
                          onClick={() => {
                            // if (pro?.quantity < 1) {
                            //   toast.error(
                            //     "Sorry the product is not abailable right now",
                            //     {
                            //       className: "toast-message",
                            //       position: "top-center",
                            //       autoClose: 1000,
                            //       hideProgressBar: false,
                            //       closeOnClick: true,
                            //       pauseOnHover: true,
                            //       draggable: true,
                            //       progress: undefined,

                            //       transition: Bounce,
                            //     }
                            //   );
                            // } else {
                            // }
                            // if (bucId == "") {
                            //   clickOnEveryVerient(pro);
                            // } else {
                            //   clickOnEveryVerient(pro, bucId);
                            // }

                            dispatch(
                              BucketMenegeAction.addNewProductToBucket({
                                proName: pro?.productId?.productInfo?.name,
                                color: pro?.variant?.Color,
                                price: pro?.variant?.Price,
                                sku: pro?.variant?.sku,
                                currentStock: pro?.quantity,
                                variantId: pro?._id,
                                productId: pro?.productId?._id,
                              })
                            );
                          }}
                        >
                          <div className="tp">
                            <div className="tp_lft">
                              {/* <span className="fd">Food</span>
                        <span className="nrml">F00075</span> */}
                            </div>
                            <span className="tp_rght">
                              <FontAwesomeIcon icon={faEllipsis} size="2xl" />
                            </span>
                          </div>
                          <h6>{pro?.productId?.productInfo?.name}</h6>
                          <h4>Colour : {pro?.variant?.Color}</h4>
                          <div className="bttm">
                            <div className="bttm_lft">
                              <label className="dlr_mnt">
                                $ {pro?.variant?.Price}
                              </label>
                            </div>
                            <div className="bttm_rght">
                              <label className="stc">
                                Stock: <span>{pro?.quantity}</span>
                              </label>
                            </div>
                          </div>
                        </button>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <br />
          </div>

          <CustomerProduct />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil(products?.length / postsperPage)}
              color="primary"
              onChange={(e, p) => {
                setCurrentPage(p);
              }}
            />
          </Stack>
        </div>
        <HoldOrder />
      </div>
    </>
  );
};

export default PosInitialUI;
