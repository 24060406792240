import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faMagnifyingGlass,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/Sidebar";

import NavBar from "../../common/Nav/NavBar";
import { allPurchaseOrder } from "../../../redux/features/sellers/sellerPurchaseSlice";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";

const PurchaseOrderList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // const filtredByDate=

  const shortcutsItems = [
    {
      label: "This Week",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("week"), today.endOf("week")];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, "day"), today];
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: "Last Month", // Change the label here
      getValue: () => {
        const today = dayjs();
        const startOfPrevMonth = today.startOf("month").subtract(1, "month"); // Get start of previous month
        return [startOfPrevMonth, startOfPrevMonth.endOf("month")]; // Return start and end of the previous month
      },
    },
    // {
    //   label: "Next Month",
    //   getValue: () => {
    //     const today = dayjs();
    //     const startOfNextMonth = today.endOf("month").add(1, "day");
    //     return [startOfNextMonth, startOfNextMonth.endOf("month")];
    //   },
    // },
    { label: "Reset", getValue: () => [null, null] },
  ];

  const { loading, POList, currency } = useSelector(
    (state) => state.sellerPurchase
  );

  const [searchBy, setSearchBy] = useState("");

  let searchedRecords = POList?.filter((v, i) => {
    return (
      v?.purchaseOrderNumber?.toLowerCase().includes(searchBy.toLowerCase()) ||
      v?.supplierId?.name?.toLowerCase().includes(searchBy.toLowerCase())
    );
  });

  const filtredByDate = searchedRecords?.filter(
    (e, i) =>
      new Date(e?.dateOfPurchaseOrder).getTime() > startDate &&
      new Date(e?.dateOfPurchaseOrder).getTime() < endDate
  );
  if (endDate) {
    searchedRecords = filtredByDate;
  }

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  let records = searchedRecords?.slice(firstIndex, lastIndex);
  // console.log(records, "records");

  const npage =
    searchedRecords?.length > 0 &&
    Math.ceil(searchedRecords.length / recordsPerPage);
  console.log(npage, "npage");
  const numbers = [...Array(npage + 1).keys()].slice(1);
  console.log(numbers, "numbers");

  useEffect(() => {
    dispatch(allPurchaseOrder());
  }, []);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(0, 0);
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
    window.scrollTo(0, 0);
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(0, 0);
  };

  // console.log(records, "abc");

  return (
    <>
      {/* <div className="ttl_mdl"></div>
      <main>
        <section className="total_parent_element">
          <div className="left_parent_element">
            <div className="total_upper_left">
              <div className="logo_area">
                <img
                  style={{ width: "82%", height: "50px", margin: "auto" }}
                  src={require("../../../assets/images/bank.png")}
                />
              </div>
              <div className="nav_area">
                <div className="outr_dashboard_nav">
                  <div className="parent_nav_menu">
                    <Sidebar />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="right_parent_element">
            <NavBar /> */}

      <div className="outr-right-content">
        <div className="oder_history">
          <div className="order_hdr">
            <div className="ordre_lft">
              <h6 style={{ color: "#008080" }}>List Of Purchase Order</h6>
            </div>
            <div className="ordre_rght">
              {/* <div className="ordr_srch_bx">
                      <input type="search" placeholder="Search Order Id" />
                      <span>
                        <FontAwesomeIcon icon={faMagnifyingGlass} size="2xl" />
                      </span>
                    </div> */}
              {/* <div className="ordr_date_bx">
                      <input type="date" />
                    </div> */}
            </div>
          </div>
          <div className="orders">
            <h2
              style={{
                position: "absolute",
                right: "37%",
              }}
            >
              {dayjs(new Date(endDate)).format("MMM D, YYYY") !==
                "Invalid Date" &&
                endDate !== null &&
                dayjs(new Date(startDate)).format("MMM D, YYYY") +
                  " to " +
                  dayjs(new Date(endDate)).format("MMM D, YYYY")}
            </h2>
            <input
              style={{
                position: "absolute",
                right: "4%",
                // top: "10px",
              }}
              value={searchBy}
              onChange={(e) => {
                setSearchBy(e.target.value.trimStart());
              }}
              type="search"
              placeholder="Search "
              className="fs-3 border border-3 border-success d-flex justify-content-end"
            />
            <br />
            <button
              style={{ background: "#008080" }}
              type="submit"
              href="javascript:void(0);"
              className="edit"
              onClick={() => navigate("/purchase/purchase-order")}
            >
              <FontAwesomeIcon icon={faPlus} /> Create Purchase Order
            </button>
            {/* {console.log(POList, "eeeee")} */}
            <div className="ordr_tbl">
              <table>
                <thead>
                  <tr>
                    <th>
                      Date
                      <button
                        type="button"
                        onClick={(e) => {
                          setModal(true);
                        }}
                        className="btn btn-primary"
                      >
                        filter
                      </button>
                      {/* {dayjs(new Date(endDate)).format("MMM D, YYYY") !==
                              "Invalid Date" &&
                              endDate !== null &&
                              dayjs(new Date(startDate)).format("MMM D, YYYY") +
                                " to " +
                                dayjs(new Date(endDate)).format("MMM D, YYYY")} */}
                      {/* {console.log(dayjs(null), "ress")} */}
                    </th>
                    <th>Purchase Order</th>
                    <th>Supplier Name</th>
                    <th>Payment Terms</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Expected Delivery Date</th>
                    <th>Recived Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {records && records.length > 0 ? (
                    records.map((po) => {
                      // console.log(po?.dateOfPurchaseOrder, "res");
                      return (
                        <tr key={po?._id}>
                          <td>
                            <div className="div1">
                              <div className="o_div_txt">
                                <h5>
                                  {po?.dateOfPurchaseOrder
                                    .slice(0, 10)
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                                </h5>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <h5>{po?.purchaseOrderNumber}</h5>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <h5>{po?.supplierId?.name}</h5>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <h5>{po?.paymentTerm}</h5>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              {po.isBilled ? (
                                <h5 style={{ color: "#008080" }}>Billed</h5>
                              ) : (
                                <h5 style={{ color: "red" }}>Not Billed</h5>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              {/* <h5>{  po?.totalAmount}</h5> */}
                              <h5>{currency + po?.totalAmount}</h5>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <h5>
                                {po?.expectedDeliveryDate
                                  .slice(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              </h5>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <h5
                                style={{
                                  color:
                                    po?.isReceived == "notReceived"
                                      ? "red"
                                      : po?.isReceived == "received"
                                      ? "green"
                                      : "orange",
                                }}
                              >
                                {po?.isReceived == "received"
                                  ? "Received"
                                  : po?.isReceived == "partiallyReceived"
                                  ? "Partially Received"
                                  : "Not Received"}
                              </h5>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <span>
                                <FontAwesomeIcon
                                  style={{
                                    cursor: "pointer",
                                    color: "black",
                                  }}
                                  icon={faEye}
                                  size="2xl"
                                  onClick={() =>
                                    navigate(
                                      `/purchase/purchase-order-detail/${po._id}`
                                    )
                                  }
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <h2>No purchase order found</h2>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination */}
          <div className="pagination">
            <a href="javascript:void(0);" onClick={prePage}>
              «
            </a>
            {numbers.map((n, i) => (
              <a
                href="javascript:void(0);"
                key={i}
                onClick={() => changeCPage(n)}
                className={`${currentPage === n ? "active" : ""}`}
              >
                {n}
              </a>
            ))}

            {/* <a href="javascript:void(0);" className="active">
                    02
                  </a>
                  <a href="javascript:void(0);">03</a>
                  <a href="javascript:void(0);">04</a> */}
            <a href="javascript:void(0);" onClick={nextPage}>
              »
            </a>
          </div>
        </div>
      </div>
      {/* </div>
        </section>
      </main> */}
      <Drawer
        open={modal}
        onClose={(e) => {
          setModal(false);
        }}
      >
        <Box sx={{ width: 850 }} role="presentation">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateRangePicker
              onChange={(e) => {
                // console.log(e[0]?.$d?.toISOString(), "res");
                setStartDate(new Date(e[0]?.$d).getTime());
                setEndDate(new Date(e[1]?.$d).getTime() + 86399998);
              }}
              slotProps={{
                shortcuts: {
                  items: shortcutsItems,
                },
                actionBar: { actions: [] },
              }}
              calendars={2}
            />
          </LocalizationProvider>
          {/* <button
            disabled={!endDate}
            className={`fs-3 btn ${endDate ? "btn-primary" : "btn-secondary"}`}
            type="button"
            onClick={(e) => {
              // console.log(endDate - startDate, "se");
              setModal(false);
            }}
          >
            Apply date Filter
          </button> */}
        </Box>
        <button
          className={` fs-1 btn btn-success w-25   `}
          disabled={!endDate}
          onClick={(e) => {
            setModal(false);
          }}
        >
          Search
        </button>
      </Drawer>
    </>
  );
};

export default PurchaseOrderList;
