import React, { useEffect, useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import NavBar from "../../components/common/Nav/NavBar";
import axios from "axios";
import { getDetailsbyNumber } from "../../constants/Api/Api";
import { Bounce, toast } from "react-toastify";
// import ShowOnTen from "../sellers/products/newFeature/ShowOnTen";
import { Outlet, useNavigate } from "react-router-dom";
import PosSideBar from "../posSidebar/PosSideBar";
import PosTopBar from "../PosTopBar/PosTopBar";
import PosInitialUI from "./initial/PosInitialUI";

const PosMainLayout = () => {
  const nav = useNavigate();
  // const [phonenumber, setphonenumber] = useState("");
  // const [Show, setShow] = useState(false);
  // const [userdata, setuserdata] = useState("wait...");

  // const ChangeHandelerOfNumber = async (e) => {
  //   const numbervalue = e.target.value;
  //   if (numbervalue.length === 10) {
  //     setphonenumber(numbervalue);
  //     setShow(true);
  //     try {
  //       const res = await axios.get(`${getDetailsbyNumber}/${numbervalue}`, {
  //         headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  //       });

  //       if (res.data?.message) {
  //         setuserdata(res.data?.message);
  //       } else {
  //         setuserdata(res?.data?.data);
  //       }
  //     } catch (error) {
  //       console.warn(error);
  //       toast.warn("Please type a valid mobule number", {
  //         className: "toast-message",
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //         transition: Bounce,
  //       });
  //     }
  //   } else {
  //     setShow(false);
  //     setuserdata("Please wait...");
  //   }
  // };
  return (
    <>
      <main>
        <section className="ttl_prnt_elmnt">
          <PosSideBar />

          <div className="rght_prnt_elmnt">
            <PosTopBar />
            <Outlet />
          </div>
        </section>
      </main>
    </>
  );
};

export default PosMainLayout;
