import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export const sellerRoute = [
  {
    mainName: "Products",
    sub: [
      {
        subName: "Product List",
        routeLink: "/products/product-list",
      },
    ],
  },
  {
    mainName: "Bills",
    sub: [
      {
        subName: "Suppliers",
        routeLink: "/purchase/supplier-list",
      },
      {
        subName: "Purchase Order List",
        routeLink: "/purchase/purchase-order-list",
      },
      {
        subName: "List Of Bills",
        routeLink: "/purchase/listOfBill",
      },
      {
        subName: "Purchase Receives",
        routeLink: "/purchaseRecives",
      },
    ],
  },
  // {
  //   mainName: "Warehouse",
  //   sub: [
  //     {
  //       subName: "Warehouses",
  //       routeLink: "/warehouse/warehouse-list",
  //     },
  //   ],
  // },
  {
    mainName: "Inventory",
    sub: [
      {
        subName: "Inventory Summary",
        routeLink: "/inventory/inventory-summary",
      },
      {
        subName: "Warehouses",
        routeLink: "/warehouse/warehouse-list",
      },
    ],
  },
];

const SellerSideBar = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleAccordionChange = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleNavLinkClick = () => {
    setExpandedIndex(null); // Collapse the accordion when a sub item is selected
  };
  const navigate = useNavigate();

  return (
    <div className="lft_prnt_elmnt">
      <div className="total_upper_left">
        <div className="logo_area">
          <a href="javascript:void(0);">
            <div className="nav_btm_logo">
              <img
                src={require("../../assets/images-pos/logo.png")}
                alt="logo"
              />
            </div>
          </a>
        </div>
        <div className="nav_area">
          <div className="outr_dashboard_nav">
            <div className="parent_nav_menu">
              <div className="pnmul_ottr">
                <div className="pnmul_one menu" id="menu">
                  {sellerRoute.map((v, i) => (
                    <Accordion
                      key={i}
                      sx={{ boxShadow: "none" }}
                      expanded={expandedIndex === i}
                      onChange={() => handleAccordionChange(i)}
                    >
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-content"
                        id="panel-header"
                      >
                        <div className="pnmul_one menu" id="menu">
                          <h6>
                            {v?.mainName}
                            <span className="onn">
                              <FontAwesomeIcon icon={faCaretDown} />
                            </span>{" "}
                            <span className="nn">
                              <FontAwesomeIcon icon={faCaretUp} />
                            </span>
                          </h6>
                        </div>

                        {/* <h6 style={{ width: "100%" }}>
                          {v.mainName}
                          <span className="onn">
                            <FontAwesomeIcon icon={faCaretDown} size="2xl" />
                          </span>
                          <span className="nn">
                            <FontAwesomeIcon icon={faCaretUp} size="2xl" />
                          </span>
                        </h6> */}
                      </AccordionSummary>
                      <ul className="pnmul lists active" id="ddl">
                        {v.sub.map((vc, ci) => (
                          <AccordionDetails key={ci}>
                            <li
                              className="nav-item"
                              onClick={() => {
                                handleNavLinkClick();
                                navigate(vc?.routeLink);
                              }}
                            >
                              <span className="nav-link">
                                <div className="icon_box">
                                  <img
                                    src={require("../../assets/images-pos/left_i_1.png")}
                                    alt="img"
                                  />
                                  <img
                                    src={require("../../assets/images-pos/left_ii_1.png")}
                                    alt="img"
                                  />
                                </div>
                                <span className="icon_text">{vc?.subName}</span>
                              </span>
                            </li>
                            {/* <NavLink
                              to={vc?.routeLink}
                              onClick={() => {
                                handleNavLinkClick;
                              }}
                            >
                              {vc?.subName}
                            </NavLink> */}
                            {/* <NavLink
                            style={{ color: "black" }}
                            to={vc.routeLink}
                            onClick={handleNavLinkClick} // Collapse on click
                          >
                            <ul className="pnmul lists active" id="ddl">
                              <li className="nav-item">
                                <a className="nav-link">
                                  <div className="icon_box">
                                    {vc.imgLink && (
                                      <img
                                        src={require(`../../assets/images-pos/${vc.imgLink}`)}
                                        alt="img"
                                      />
                                    )}
                                  </div>
                                  <span className="icon_text">
                                    {vc.subName}
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </NavLink> */}
                          </AccordionDetails>
                        ))}
                      </ul>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerSideBar;
