import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { showEachBill } from "../../../redux/GetAllBill";

const EachBill = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [eachBillData, setEachBillData] = useState({});
  useLayoutEffect(() => {
    dispatch(showEachBill(id)).then((a) => {
      setEachBillData(JSON.parse(JSON.stringify(a?.payload?.data)));
    });
  }, []);
  console.log(eachBillData);
  return (
    <>
      <div className="outr-right-content">
        <div className="outr-right-content splr_frm_cntnt nw_prchs_ordr">
          <div className="splr_frm_ttl">
            <div className="splr_frm_main">
              <form>
                <div className="splr_frm_flx">
                  <div className="prch_ordtl_flx">
                    <div className="form-group">
                      <div className="td_hdr">
                        <h3>Bill Details</h3>
                      </div>
                      <h4>
                        balanceDue :<span>{eachBillData?.balanceDue}</span>
                      </h4>
                      <h4>
                        billDate :
                        <span>{eachBillData?.billDate?.slice(0, 10)}</span>
                      </h4>
                      <h4>
                        billNumber :<span>{eachBillData?.billNumber}</span>
                      </h4>
                      <h4>
                        discountRate :<span>{eachBillData?.discountRate}</span>
                      </h4>
                      <h4>
                        discountedAmount :
                        <span>{eachBillData?.discountedAmount}</span>
                      </h4>
                      <h4>
                        dueDate :
                        <span>{eachBillData?.dueDate?.slice(0, 10)}</span>
                      </h4>
                      <h4>
                        paymentTerm :<span>{eachBillData?.paymentTerm}</span>
                      </h4>
                      <h4>
                        purchaseOrderNumber :
                        <span>{eachBillData?.purchaseOrderNumber}</span>
                      </h4>
                      <h4>
                        status :<span>{eachBillData?.status}</span>
                      </h4>

                      <h4>
                        taxDeductedAmount :
                        <span>{eachBillData?.taxDeductedAmount}</span>
                      </h4>
                      <h4>
                        taxRate :<span>{eachBillData?.taxRate}</span>
                      </h4>
                      <h4>
                        taxType :<span>{eachBillData?.taxType}</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="prchs_itm_tbl">
              <table>
                <thead>
                  <tr>
                    <th>ITEMS &amp; DESCRIPTION</th>
                    <th>ORDERED QUANTITY</th>
                    <th>RATE</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {eachBillData?.products?.length > 0 &&
                    eachBillData?.products?.map((product) => {
                      return (
                        <tr key={product?._id}>
                          <td>
                            {product?.productId.productInfo.name}
                            <br />
                            {`Price: ${product?.variantId.variant.Price}`}{" "}
                            {`Color: ${product?.variantId.variant.Color}`}
                          </td>
                          <td>{product?.quantityOrdered}</td>
                          <td>{product?.unitCost}</td>
                          <td>{product?.totalCost}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="prchs_rows">
              <div className="prchs_ordr_bl_ttl">
                <div className="prchs_ordr_bl_top"></div>
                <div className="prchs_ordr_bl_bttm">
                  <div className="prchs_ttl prchs_ordr_bl">
                    <span className="prchs_ordr_bl_left">Total</span>
                    <span className="prchs_ordr_bl_right">
                      ₹{eachBillData?.subTotal}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EachBill;
