import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Router, useNavigate } from "react-router-dom";
import {
  logout,
  delelteExpTime,
  loginSeller,
} from "../redux/features/sellers/sellerLoginSlice";

const PrivateRoute = () => {
  const { exptime } = useSelector((a) => a.loginSeller);
  const authToken = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = localStorage.getItem("user");

  let idofSetTimeOut;

  //   useLayoutEffect(() => {
  //     // clearTimeout(idofSetTimeOut);
  //     const timediff = exptime - new Date().getTime();

  // if (authToken) {
  //   if (timediff <= 0) {
  //     console.log("time end");
  //     localStorage.clear();
  //     navigate("/");
  //   } else if (timediff > 0) {
  //     console.log("timeout");
  //     clearTimeout(idofSetTimeOut);
  //     setTimeout(() => {
  //       navigate("/");
  //       localStorage.clear();
  //       location.reload();
  //     }, timediff);
  //   }
  // }
  //   }, [exptime, authToken]);

  if (user && user == "seller" && authToken) {
    return <Outlet />;
  } else if (user && user == "pos" && authToken) {
    return <Navigate to={"/posContent"} />;
  } else {
    return <Navigate to={"/choseuser"} />;
  }
};

export default PrivateRoute;
