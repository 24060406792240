import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {  deleteOneBill, fixStatusBeforeMap, forMultipleDelete, getAllBillss, showEachBillApi } from "../constants/Api/Api";
import { Bounce, toast } from "react-toastify";
import Swal from "sweetalert2";



export const fetchingAllBils=createAsyncThunk('fetchingallbils',async(data,thunkAPI)=>{
    try {
        const res=await axios.get(`${getAllBillss}`,{
          headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })

        return res?.data
    } catch (error) {
        console.warn(error);
        return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
        
    }
})


export const updateStatusBeforeRender=createAsyncThunk('updateStatusBeforeRender',async(data,thunkAPI)=>{
  try {
    const abc=axios.put(fixStatusBeforeMap,{},{
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },     
    })
    const state=thunkAPI?.getState()
    console.log(state)
    return abc
  } catch (err) {
    console.warn(err);       
    return thunkAPI.rejectWithValue(err.message)
  }
})



export const DeleteOneBillOfList=createAsyncThunk('DeleteOneBillOfList',async(data,thunkAPI)=>{
  try {
  // console.log(data,'abc')

    const deleteee=await axios.delete(`${deleteOneBill}/${data}`,{
      headers:{Authorization:'Bearer '+localStorage.getItem('token')}
    })
    console.log(deleteee?.data,'abc')
      if (deleteee?.data?.success) { 
        console.log(deleteee?.data,'abc')        
        swal(deleteee?.data?.message, {
          icon: "success",
        });
        
        // toast.success(deleteee?.data, {
        //   className: "toast-message",
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   // theme: 'dark',
        //   transition: Bounce,
        // });
        await thunkAPI.dispatch(fetchingAllBils());
      }
    

  } catch (err) {
    toast.error(err?.response?.data?.message, {
      className: "toast-message",
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      // theme: 'dark',
      transition: Bounce,
    });
  }
})


export const deleteMultipleBiils=createAsyncThunk('DeleteMultipleBills',async(data,thunkAPI)=>{
  try {

    const res = await axios.delete(forMultipleDelete, {
      data: { billsToDelete: [...data] }, 
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    });
    console.log(res?.data)
  if (res?.data?.success) {
    swal("Done!", res?.data.message, "success");
    await thunkAPI.dispatch(fetchingAllBils());
    
  } else {
   
  }
  } catch (error) {
    console.warn(error);

     toast.error(error?.response?.data?.message, {
      className: "toast-message",
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      // theme: 'dark',
      transition: Bounce,
    });
  }
})

export const showEachBill=createAsyncThunk('showEachBill',async(data,thunkAPI)=>{
try {
  
  const res=await axios.get(`${showEachBillApi}/${data}`,{headers:{
    Authorization:'Bearer '+localStorage.getItem('token')
  }})
  // console.log(res?.data)
  return res?.data
} catch (error) {
  
}
})

export const AllBillSlicer=createSlice({
    name:'AllBillSlicer',
    initialState:{
        allBillloading:false,
        showEachBillLoading:false,
        AllBilldata:[],
        currencySymbol:'',
        totalPaybles:0,
        dueToday:0,
        Due30days:0,
        overDueBils:0,
    },
    extraReducers: (builder) => {
        builder

          .addCase(fetchingAllBils.pending, (state) =>{
            state.allBillloading = true
      })
          .addCase(fetchingAllBils.fulfilled, (state,action) => {
            // console.log(action?.payload,'iii')
              state.allBillloading = false
              state.AllBilldata=[...action?.payload?.data]
              state.currencySymbol=action?.payload?.currency
              state.totalPaybles=action?.payload?.totalOutstandingPayables
              state.dueToday=action?.payload?.dueToday
              state.Due30days=action?.payload?.dueWithinThirtyDays
              state.overDueBils=action?.payload?.overDueBills        

              
      })
          .addCase(fetchingAllBils.rejected, (state, action) => {
            console.log(action,'abc')
           state.allBillloading = false
      })

      .addCase(updateStatusBeforeRender.pending, (state) =>{
        state.allBillloading = true
  })
      .addCase(updateStatusBeforeRender.fulfilled, (state,action) => {
         state.allBillloading=true
  })
      .addCase(updateStatusBeforeRender.rejected, (state, action) => {
       state.allBillloading = false
  })
  .addCase(showEachBill.pending, (state) =>{
    state.showEachBillLoading = true
})
  .addCase(showEachBill.fulfilled, (state,action) => {
    // console.log(action?.payload)
     state.showEachBillLoading=false
})
  .addCase(showEachBill.rejected, (state, action) => {
   state.showEachBillLoading = false
})
       }
})