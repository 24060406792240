import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generetePurchaseReciveNumber,
  POdetail,
  PurchaseRecive,
} from "../../../redux/features/sellers/sellerPurchaseSlice";
import { useNavigate, useParams } from "react-router-dom";

const ReciveOneBill = () => {
  const navigate = useNavigate();
  const formatToDate = (inputDate) => {
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      // throw new Error("Invalid date format"); // Handle invalid date input
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const dispatch = useDispatch();
  const { id } = useParams();
  useLayoutEffect(() => {
    dispatch(POdetail(id));
    dispatch(generetePurchaseReciveNumber());
  }, []);
  const { loading, purchaseOrderDetail, uniqePurchaseReciveNumber } =
    useSelector((state) => state.sellerPurchase);
  const [qnReciveDetail, setqnReciveDetail] = useState({});
  const [recivedDate, setRecivedDate] = useState(formatToDate(new Date()));
  //   console.log(recivedDate);

  useEffect(() => {
    let res = {};
    purchaseOrderDetail?.products?.forEach((v) => {
      res[v?.variantId?._id] = v?.quantity - v?.quantityReceived;
    });

    setqnReciveDetail({ ...res });
  }, [purchaseOrderDetail]);
  console.log(purchaseOrderDetail);
  //   console.log(qnReciveDetail);

  const dataToSendForPurchaseRecive = {
    purchaseOrderNumber: purchaseOrderDetail?.purchaseOrderNumber,
    purchaseReceiveNumber: uniqePurchaseReciveNumber,
    receivedDate: recivedDate,
    products: purchaseOrderDetail?.products?.map((v) => {
      return {
        productId: v?._id,
        variantId: v?.variantId?._id,
        quantityOrdered: v?.quantity,
        quantityReceived: v?.quantityReceived,
        quantityToReceive: Number(qnReciveDetail[v?.variantId?._id]),
      };
    }),
  };

  return (
    <>
      {/* <h2>
      <div>VenderName:{purchaseOrderDetail?.supplierId?.name}</div>
      <div>Purchase Order:{purchaseOrderDetail?.purchaseOrderNumber}</div>
      <div>Purchase Receive:{uniqePurchaseReciveNumber}</div>
      <div> Receive Date :{}</div>
    </h2> */}
      <>
        <div className="outr-right-content">
          <div className="outr-right-content splr_frm_cntnt nw_prchs_ordr">
            <div className="splr_frm_ttl">
              <div className="splr_frm_main">
                <form>
                  <div className="splr_frm_flx">
                    <div className="prch_ordtl_flx">
                      <div className="form-group">
                        <div className="td_hdr">
                          <h3>PURCHASE RECIVE</h3>
                        </div>

                        <div className="ord_bl_otr">
                          <h4>
                            Supplier Name :
                            {purchaseOrderDetail?.supplierId?.name}{" "}
                          </h4>
                          <h4>
                            Purchase Order :
                            {purchaseOrderDetail?.purchaseOrderNumber}
                          </h4>
                          <h4>Purchase Receive :{uniqePurchaseReciveNumber}</h4>
                          <h4>
                            Received Date:{" "}
                            <input
                              type="date"
                              value={recivedDate}
                              onChange={(e) => {
                                setRecivedDate(formatToDate(e.target.value));
                              }}
                            />
                          </h4>
                        </div>
                      </div>
                      <div className="form-group"></div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="prchs_itm_tbl">
                <table>
                  <thead>
                    <tr>
                      <th>ITEMS &amp; DESCRIPTION</th>
                      <th>ORDERED QUANTITY</th>
                      <th>QUANTITY RECIVED</th>
                      <th>QUANTITY TO RECIVED</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseOrderDetail?.products?.length > 0 &&
                      purchaseOrderDetail?.products
                        ?.filter(
                          (prod) => prod.quantity > prod.quantityReceived
                        )
                        .map((product) => {
                          return (
                            <tr key={product?._id}>
                              <td>
                                {product?.productId.productInfo.name}
                                <br />
                                {`Price: ${product?.variantId.variant.Price}`}{" "}
                                {`Color: ${product?.variantId.variant.Color}`}
                              </td>
                              <td>{product?.quantity}</td>
                              <td>{product?.quantityReceived}</td>
                              <td>
                                <input
                                  type="number"
                                  value={
                                    qnReciveDetail[product?.variantId?._id]
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value >
                                      product?.quantity -
                                        product?.quantityReceived
                                    ) {
                                      alert(
                                        "Quantity recorded cannot be more than quantity ordered."
                                      );
                                    } else {
                                      setqnReciveDetail((pre) => {
                                        return {
                                          ...pre,
                                          [product?.variantId?._id]:
                                            e.target.value,
                                        };
                                      });
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={() => {
              dispatch(
                PurchaseRecive({ dataToSendForPurchaseRecive, navigate })
              );
            }}
            className="edit"
          >
            Save As Received
          </button>
        </div>
      </>
    </>
  );
};

export default ReciveOneBill;
