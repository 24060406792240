import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ShowEachPurchaseRecive } from "../../../redux/features/sellers/PurchaseReciveSlice";

const SeeOnePurchaseReceive = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { dataOfEachPurchaseRecive, loading } = useSelector(
    (a) => a.PurchaseReciveList
  );
  useEffect(() => {
    dispatch(ShowEachPurchaseRecive(id));
  }, []);

  console.log(dataOfEachPurchaseRecive, "abc");
  return (
    <>
      <h1>
        {loading ? (
          <div className="centered-container">
            <div>
              <img src={require("../../../assets/images/bank.png")} />
              <h1 className="text-center">Please wait...</h1>
            </div>
          </div>
        ) : (
          <div className="outr-right-content">
            <div className="outr-right-content splr_frm_cntnt nw_prchs_ordr">
              <div className="splr_frm_ttl">
                <div className="splr_frm_main">
                  <form>
                    <div className="splr_frm_flx">
                      <div className="prch_ordtl_flx">
                        <div className="form-group">
                          <div className="td_hdr">
                            <h3>purchase receive</h3>
                          </div>
                          <h4>
                            Vendor Name :
                            <span>
                              {dataOfEachPurchaseRecive?.supplierId?.name}
                            </span>
                          </h4>
                          <h4>
                            Receive# :
                            <span>
                              {dataOfEachPurchaseRecive?.purchaseReceiveNumber}
                            </span>
                          </h4>
                          <h4>
                            Status :
                            <span>
                              {dataOfEachPurchaseRecive?.isBilled
                                ? "Billed"
                                : "Not Billed"}
                            </span>
                          </h4>
                          <h4>
                            Purchase Order# :
                            <span>
                              {dataOfEachPurchaseRecive?.purchaseOrderNumber}
                            </span>
                          </h4>
                          <h4>
                            Date :
                            <span>
                              {dataOfEachPurchaseRecive?.receivedDate?.slice(
                                0,
                                10
                              )}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="prchs_itm_tbl">
                  <table>
                    <thead>
                      <tr>
                        <th>No .</th>
                        <th>Items & Description</th>
                        <th> Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataOfEachPurchaseRecive?.products?.length > 0 &&
                        dataOfEachPurchaseRecive?.products?.map(
                          (product, i) => {
                            return (
                              <tr key={product?._id}>
                                <td>{i + 1}</td>
                                <td>
                                  {product?.productDetails?.name}
                                  <br />
                                  {`Brand: ${product?.productDetails?.brand}`},
                                  {`Color: ${product?.productDetails?.color}`}
                                </td>
                                <td>{product?.quantityToReceive}</td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                {/* <div className="prchs_rows">
                  <div className="prchs_ordr_bl_ttl">
                    <div className="prchs_ordr_bl_top"></div>
                    <div className="prchs_ordr_bl_bttm">
                      <div className="prchs_ttl prchs_ordr_bl">
                        <span className="prchs_ordr_bl_left">Total</span>
                        <span className="prchs_ordr_bl_right">
                          ₹{dataOfEachPurchaseRecive?.subTotal}
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )}
      </h1>
    </>
  );
};

export default SeeOnePurchaseReceive;
