import React, { useEffect } from "react";
import { gettingListofHoldOrder } from "../../../../redux/posSlice/HoldOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  BucketAction,
  fillBucketbyHoldBucket,
} from "../../../../redux/posSlice/BucketSlice";
import { SelectedCustomerAction } from "../../../../redux/posSlice/SelectedCustomer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { HoldBucketMenegAction } from "../../../../redux/posSlice/HoldBucketMeneg";
import { BucketMenegeAction } from "../../../../redux/posSlice/BucketMenegeSlice";

const HoldOrder = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(gettingListofHoldOrder());
  // }, []);
  const { totalHoldOreder, loading: getholdOrderStatus } = useSelector(
    (a) => a.aboutHoldOrder
  );
  const holdBill = useSelector((a) => a.HoldBilling);
  // console.log(holdBill);
  //   console.log(totalHoldOreder, "check");
  // console.log(totalHoldOreder, "check");
  return (
    <>
      <hr />

      <div className="dscls_ordrs">
        <div className="dscls_ordrs_dwns">
          <h6>
            Open Orders{" "}
            <span>
              ({holdBill?.length == 0 ? "no" : holdBill.length} items)
            </span>
          </h6>
          {getholdOrderStatus && <div className="loaderHOld"></div>}
          <div className="dscls_ordrs_flx">
            {holdBill &&
              holdBill.length > 0 &&
              holdBill.map((val, ind) => {
                // console.log(val, "val");
                let totalPriceOfEachHoldBill = 0;
                let totalQtyOfEachHoldBill = 0;
                for (const eachPro of val?.bucketProduct) {
                  totalPriceOfEachHoldBill += eachPro?.price * eachPro?.qn;
                  totalQtyOfEachHoldBill += eachPro?.qn;
                }
                return (
                  <>
                    <button
                      onClick={async () => {
                        // await dispatch(
                        //   SelectedCustomerAction.addCustomerDetails({
                        //     firstName: val?.userId?.firstName,
                        //     lastName: val?.userId?.lastName,
                        //     phone: val?.userId?.phone,
                        //     userId: val?.userId?._id,
                        //   })
                        // );
                        // const res = await dispatch(
                        //   fillBucketbyHoldBucket(val?._id)
                        // );
                        // await dispatch(gettingListofHoldOrder());

                        dispatch(HoldBucketMenegAction.removeFromHodBill(val));
                        dispatch(
                          BucketMenegeAction.fillBucketBymanypro(
                            val?.bucketProduct
                          )
                        );
                        dispatch(
                          SelectedCustomerAction.addCustomerDetails({
                            firstName: val?.firstName,
                            lastName: val?.lastName,
                            phone: val?.phone,
                            userId: val?.IdofUser,
                          })
                        );
                      }}
                      className="dscls_ordrs_prt"
                    >
                      <div className="tp">
                        <div className="tp_lft">
                          {/* <span className="fd">Food</span>
                        <span className="nrml">F00075</span> */}
                        </div>
                        <span className="tp_rght">
                          <FontAwesomeIcon icon={faEllipsis} />
                        </span>
                      </div>
                      <h6>{val?.firstName + " " + val?.lastName}</h6>
                      <h4>Phone no : {val?.phone}</h4>
                      <div className="bttm">
                        <div className="bttm_lft">
                          <label className="dlr_mnt">
                            $ {totalPriceOfEachHoldBill}
                          </label>
                        </div>
                        <div className="bttm_rght">
                          <label className="stc">
                            Qty: <span>{totalQtyOfEachHoldBill}</span>
                          </label>
                        </div>
                      </div>
                    </button>
                  </>
                );
              })}
          </div>
        </div>
      </div>

      {/* <h1>List of Hold bill</h1>
      <hr />
      {getholdOrderStatus && <div className="loaderHOld"></div>}

      <div className="dscls_ordrs">
        <div className="dscls_ordrs_flx">
          {totalHoldOreder.length < 1 && (
            <h2>No Hold bill is available right now.</h2>
          )}

          {totalHoldOreder &&
            totalHoldOreder.length > 0 &&
            totalHoldOreder.map((val, ind) => {
              return (
                <>
                  <button
                    key={ind}
                    className="dscls_ordrs_prt"
                    onClick={async () => {
                      dispatch(
                        SelectedCustomerAction.addCustomerDetails({
                          firstName: val?.userId?.firstName,
                          lastName: val?.userId?.lastName,
                          phone: val?.userId?.phone,
                          userId: val?.userId?._id,
                        })
                      );
                      const res = await dispatch(
                        fillBucketbyHoldBucket(val?._id)
                      );
                  
                      dispatch(gettingListofHoldOrder());
                    }}
                  >
                    <div className="tp">
                      <div className="tp_lft">
                
                      </div>
                      <span className="tp_rght">
                        <FontAwesomeIcon icon={faEllipsis} size="2xl" />
                      </span>
                    </div>
                    <h6>
                      {val?.userId?.firstName + " " + val?.userId?.lastName}
                    </h6>
                    <h4>Phone no : {val?.userId?.phone}</h4>
                    <div className="bttm">
                      <div className="bttm_lft">
                        <label className="dlr_mnt">$ {val?.bucketTotal}</label>
                      </div>
                      <div className="bttm_rght">
                        <label className="stc">
                          Stock: <span></span>
                        </label>
                      </div>
                    </div>
                  </button>
                </>
              );
            })}
        </div>
      </div> */}
    </>
  );
};

export default HoldOrder;
