import { createSlice } from "@reduxjs/toolkit";

 export const SelectedCustomer=createSlice({
    name:'SelectedCustomer',
    initialState:{
        customerDetails:{
            firstName:'',
            lastName:'',
            phone:'',
            userId:''
        }
    },
    reducers:{        
        addCustomerDetails:(state,action)=>{                
    return {...state,firstName:action.payload.firstName,lastName:action.payload.lastName,phone:action.payload.phone,userId:action.payload.userId}        
    },
    deleteCustomer:(st,ac)=>{
        return st={
            firstName:'',
            lastName:'',
            phone:'',
            userId:''
        }
    }
}
})

const SelectedCustomerAction=SelectedCustomer.actions;


export { SelectedCustomerAction }