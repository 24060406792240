import axios from "axios";
import React, { useEffect, useLayoutEffect, useReducer, useState } from "react";
import {
  addeduser,
  BASE_URL,
  forCheckOut,
  forStoringInRadis,
  putOnHold,
  removeFromBucket,
  SearchByNumberAndName,
} from "../../../../constants/Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { SelectedCustomerAction } from "../../../../redux/posSlice/SelectedCustomer";
import { useFormik } from "formik";
import * as rules from "yup";
import { Bounce, toast } from "react-toastify";
import { bottomNavigationActionClasses } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faStar,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  faEllipsis,
  faPhone,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  BucketAction,
  BucketManegment,
  deletefromBucket,
  updateBucketQun,
  viewBucket,
} from "../../../../redux/posSlice/BucketSlice";
import Swal from "sweetalert2";
import { getproducts } from "../../../../redux/posSlice/ProductsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { gettingListofHoldOrder } from "../../../../redux/posSlice/HoldOrderSlice";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { BucketMenegeAction } from "../../../../redux/posSlice/BucketMenegeSlice";
import ToastAlert from "../../../../components/ToastAlert";
import {
  getPreviousholdBill,
  HoldBucketMenegAction,
} from "../../../../redux/posSlice/HoldBucketMeneg";

const CustomerProduct = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { products, status } = useSelector((a) => a.PosDetail);
  // console.log(products);
  const totalBucket = useSelector((a) => a.BucketControl);

  let finalPrice = 0;
  for (let i = 0; i < totalBucket.length; i++) {
    finalPrice += totalBucket[i].price * totalBucket[i].qn;
  }
  const {
    loading,
    BucketItems,
    totalPrice,
    bucketId: bucId,
  } = useSelector((res) => res.AboutBucket);
  const { totalHoldOreder, status: getholdOrderStatus } = useSelector(
    (a) => a.aboutHoldOrder
  );

  useEffect(() => {
    // dispatch(viewBucket());
    dispatch(getPreviousholdBill());
  }, []);

  // console.log(totalBucket, "chek");

  // console.log(BucketItems, "chekkkkkkk");
  const forSendingDataonCheckOut = BucketItems?.map((item, ind) => {
    // console.log(item, "chek");
    return {
      productId: item?.productId,
      variantId: item?.variantId,
      quantity: item?.quantity,
      subTotal: item?.subTotal,
    };
  });

  // const onUpdateQuatity = () => {};

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const holdBill = useSelector((a) => a.HoldBilling);

  const [InputValue, SetInputValue] = useState("");
  const [Customer, SetCustomer] = useState([]);
  const [flag, setflag] = useState(false);
  const [userSelect, setUserSelect] = useState(false);
  const [addUser, SetaddUser] = useState(false);
  const [check, setcheck] = useState(false);
  const {
    firstName,
    lastName,
    phone,
    userId: IdofUser,
  } = useSelector((res) => res.TheCustomer);

  const checkOUt = async (status) => {
    setcheck(true);
    const forSendingDataonCheckOut = totalBucket?.map((item, ind) => {
      // console.log(item, "chek");
      return {
        productId: item?.productId,
        variantId: item?.variantId,
        quantity: item?.qn,
        subTotal: Number(item?.price) * item?.qn,
      };
    });
    // console.log(IdofUser, "chekid");
    console.log(forSendingDataonCheckOut, "chek");
    if (forSendingDataonCheckOut) {
      try {
        const res = await axios.post(
          `${forCheckOut}`,
          {
            userId: IdofUser,
            products: [...forSendingDataonCheckOut],
            totalAmount: forSendingDataonCheckOut.reduce((i, e) => {
              return (i += e?.subTotal);
            }, 0),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res?.data?.success) {
          console.log(res.data, "check");
          toast.success(res?.data?.message, {
            className: "toast-message",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Bounce,
          });
          dispatch(getproducts());
          dispatch(BucketAction.makeEmptyBucket());
          dispatch(SelectedCustomerAction.deleteCustomer());
          dispatch(BucketMenegeAction.makeBucketEmpty());
          nav("/posContent/checkOut");
        } else {
          console.warn("some thing wrong ");
        }
        setcheck(false);
      } catch (error) {
        console.warn(error);
        setcheck(false);
      }
    }
  };

  const onHoldClick = async () => {
    const customerWithBucket = {
      firstName,
      lastName,
      phone,
      IdofUser,
      bucketProduct: totalBucket,
    };
    dispatch(HoldBucketMenegAction.addtoHoldBucket(customerWithBucket));
    dispatch(BucketMenegeAction.makeBucketEmpty());
    dispatch(SelectedCustomerAction.deleteCustomer());
    // try {
    //   const res = await axios.put(
    //     `${putOnHold}/${bucId}`,
    //     { userId: IdofUser },
    //     {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     }
    //   );
    //   dispatch(BucketAction.makeEmptyBucket());
    //   dispatch(gettingListofHoldOrder());
    //   dispatch(SelectedCustomerAction.deleteCustomer());
    //   if (res?.data?.success) {
    //     console.log(res.data, "check");
    //   } else {
    //     console.log(res.data, "check");
    //   }
    // } catch (error) {
    //   console.warn(error);
    // }
  };

  // useEffect(() => {
  //   const handleTabClose = async (event) => {
  //     event.preventDefault();

  //     onHoldClick();

  //     event.returnValue = ""; // For most browsers
  //   };
  //   window.addEventListener("beforeunload", handleTabClose);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleTabClose);
  //   };
  // }, []);

  const onCheckOutClick = async (e) => {
    // Swal.fire({
    //   title: "Are you sure for checkout?",
    //   showDenyButton: true,
    //   showCancelButton: true,
    //   confirmButtonText: "yes",
    //   denyButtonText: `no`,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     checkOUt();
    //     Swal.fire("Successfully order created!", "", "success");
    //   } else if (result.isDenied) {
    //     Swal.fire("Oreder is not created !", "", "info");
    //   }
    // });
    if (e.target.innerHTML == "Check Out") {
      checkOUt("paid");
    } else {
      checkOUt("hold");
    }
  };

  const validationSchema = rules.object().shape({
    fn: rules
      .string()
      .required("Pls type first name")
      .min(3, "3 charecters needed"),
    ln: rules
      .string()
      .required("Pls type last name")
      .min(3, "3 charecters needed"),
    ph: rules
      .number()
      .typeError("must be 10 digits")
      .required("Pls type phone number"),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues: { fn: "", ln: "", ph: "" },
    validationSchema,
    onSubmit: async ({ fn: firstName, ln: lastName, ph: phone }, action) => {
      try {
        const res = await axios.post(
          addeduser,
          { firstName, lastName, phone },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        // console.log(res.data, "userid");

        if (res?.data?.success) {
          dispatch(
            SelectedCustomerAction.addCustomerDetails({
              firstName,
              lastName,
              phone,
              userId: res?.data?.newUserId,
            })
          );
          action.resetForm();
          // setUserSelect(true);
          setOpen(false);
          SetInputValue("");
          toast.success("New Seller added ", {
            className: "toast-message",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: 'dark',
            transition: Bounce,
          });
        } else {
          console.log(res.data, "chek");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          className: "toast-message",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          // theme: 'dark',
          transition: Bounce,
        });
      }
    },
  });
  useEffect(() => {
    const innerFuntion = async (val) => {
      setflag(true);
      try {
        const res = await axios.get(
          `${SearchByNumberAndName}?userInfo=${val}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setflag(false);
        if (res?.data?.success) {
          SetCustomer([...res.data?.data]);
        } else {
          SetCustomer([]);
          SetaddUser(true);
        }
      } catch (error) {
        console.warn(error);
      }
    };
    if (InputValue.length > 2) {
      innerFuntion(InputValue);
    } else {
      // SetInputValue("");
      SetCustomer([]);
    }
  }, [InputValue]);

  const location = useLocation();

  const handleBeforeUnload = (d) => {
    if (firstName && totalBucket.length >= 1) {
      dispatch(
        HoldBucketMenegAction.addtoHoldBucket({
          firstName,
          lastName,
          phone,
          IdofUser,
          bucketProduct: totalBucket,
        })
      );

      const dataToSend = {
        bucketData: JSON.parse(
          JSON.stringify([
            ...holdBill,
            {
              firstName,
              lastName,
              phone,
              IdofUser,
              bucketProduct: totalBucket,
            },
          ])
        ),
        token: localStorage.getItem("token"),
      };
      const blob = new Blob([JSON.stringify(dataToSend)], {
        type: "application/json",
      });
      // const url = `${BASE_URL}/api/order/testPostAPI`;
      navigator.sendBeacon(forStoringInRadis, blob);
      event.returnValue = "";
    } else {
      // localStorage.setItem("hh", JSON.stringify(holdBill));
      const dataToSend = {
        bucketData: JSON.parse(JSON.stringify(holdBill)),
        token: localStorage.getItem("token"),
      };
      const blob2 = new Blob([JSON.stringify(dataToSend)], {
        type: "application/json",
      });
      // const url = `${BASE_URL}/api/order/testPostAPI`;
      navigator.sendBeacon(forStoringInRadis, blob2);
      event.returnValue = "";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [firstName, lastName, phone, IdofUser, totalBucket]);

  return (
    <>
      <div className="sls_rght_drv">
        <div className="rcsnt_trnsct">
          <div className="pos_rght_top">
            <h6>Search customer</h6>
            <div className="sls_srch_flx">
              <div className="drv_rght_rte">
                <input
                  value={InputValue.trim()}
                  type="search"
                  placeholder="Type customer name or phone number"
                  onChange={(e) => {
                    SetInputValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {InputValue.length > 2 && Customer.length < 1 && (
            <h2>No user found !</h2>
          )}

          {InputValue.length > 2 && (
            <button onClick={handleClickOpen} className="prj_stl">
              Add new user
            </button>
          )}
          {flag && <div className="loader"></div>}

          {InputValue.length > 2 && Customer.length > 0 && (
            <div
              className="pos_cstmr_new"
              style={{ height: "140px", overflow: "auto" }}
            >
              {
                // !flag &&
                //   !userSelect &&
                //   Customer.length > 0 &&
                Customer.map((user, ind) => {
                  // console.log(user, "userid");
                  return (
                    <>
                      <button
                        onClick={() => {
                          const sameCustomer = holdBill.find(
                            (v) => v?.phone == user?.phone
                          );
                          if (sameCustomer) {
                            ToastAlert(
                              "error",
                              "This Customer is already in the hold billing "
                            );
                          } else {
                            SetInputValue("");
                            setUserSelect(true);
                            dispatch(
                              SelectedCustomerAction.addCustomerDetails({
                                firstName: user?.firstName,
                                lastName: user?.lastName,
                                phone: user?.phone,
                                userId: user?._id,
                              })
                            );
                          }
                        }}
                        key={ind}
                        style={{
                          padding: "10px 15px",
                          border: "1px solid #59acac",
                          background: "#fff",
                          cursor: "pointer",
                        }}
                        className="pos_cstmr_id"
                      >
                        <div className="cstmr_id_lft">
                          <div className="cstmr_usr_txt">
                            <h5>{user?.firstName + " " + user?.lastName}</h5>
                            <label>
                              - <span>{user?.phone}</span>
                            </label>
                          </div>
                        </div>
                      </button>
                    </>
                  );
                })
              }
            </div>
          )}

          {/* <div className="pos_cstmr_tbl">
            <div className="pos_tbl_prt">
            <label>
            Table In<span>01</span>
            </label>
            </div>
            <div className="pos_tbl_prt">
            <label>
            Date<span>Tue, 02, 2024</span>
            </label>
            </div>
            <div className="pos_tbl_prt">
            <label>
            Invoice No<span>#000125</span>
            </label>
            </div>
            </div> */}

          {firstName && (
            <>
              <div className="pos_cstmr_id">
                <div className="cstmr_id_lft">
                  <div className="cstmr_usr">
                    {/* <i className="fas fa-user"></i> */}
                    <FontAwesomeIcon icon={faUser} size="2xl" />
                  </div>
                  <div className="cstmr_usr_txt">
                    <h5>{firstName + " " + lastName}</h5>
                    <label>{/* Customer ID - <span>00012456</span> */}</label>
                  </div>
                </div>
                <div className="elps_bck">
                  <button className="tp_rght">
                    {/* <i className="fa fa-ellipsis"></i> */}
                    <FontAwesomeIcon icon={faEllipsis} size="2xl" />
                  </button>
                </div>
              </div>
              <div className="pos_cstmr_cntct">
                <div className="pos_cntct_flx">
                  <div className="pos_cntct_flx_lft">
                    {/* <i className="fas fa-phone-alt"></i> */}
                    <FontAwesomeIcon icon={faPhone} size="2xl" />
                  </div>
                  <div className="pos_cntct_flx_rght">
                    <span className="lght">Phone No:</span>
                    <br />
                    <a href="javascript:void(0);" className="dpp">
                      {phone}
                    </a>
                  </div>
                </div>
                <div className="pos_cntct_flx">
                  <div className="pos_cntct_flx_lft">
                    {/* <i className="fas fa-envelope"></i> */}
                    <FontAwesomeIcon icon={faEnvelope} size="2xl" />
                  </div>
                  <div className="pos_cntct_flx_rght">
                    {/* <span className="lght">Email:</span>
                    <br />
                    <a href="javascript:void(0);" className="emll">
                      info@mjones@gmail.com
                    </a> */}
                  </div>
                </div>
              </div>
            </>
          )}

          <>
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <h1 id="responsive-dialog-title">{"Create new Customer"}</h1>

              <h1 style={{ width: "100%" }}>
                <form onSubmit={handleSubmit} className="dialog-form">
                  <div className="dialog-form-flx">
                    <label for="recipient-name" className="col-form-label">
                      First Name :
                    </label>
                    <div className="col-form_inpbx">
                      <input
                        name="fn"
                        value={values.fn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        // className="form-control"
                        id="recipient-name"
                      />
                      <div className="text-danger err">
                        {errors.fn && touched.fn ? errors.fn : null}
                      </div>
                    </div>
                  </div>
                  <div className="dialog-form-flx">
                    <label for="recipient-name" className="col-form-label">
                      Last Name :
                    </label>
                    <div className="col-form_inpbx">
                      <input
                        name="ln"
                        value={values.ln}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        // className="form-control"
                        id="recipient-name"
                      />
                      <div className="text-danger err">
                        {errors.ln && touched.ln ? errors.ln : null}
                      </div>
                    </div>
                  </div>
                  <div className="dialog-form-flx">
                    <label for="recipient-name" className="col-form-label">
                      Phone no :
                    </label>
                    <div className="col-form_inpbx">
                      <input
                        name="ph"
                        value={values.ph}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        minLength={10}
                        maxLength={10}
                        // className="form-control"
                        id="recipient-name"
                      />
                      <div className="text-danger err">
                        {errors.ph && touched.ph ? errors.ph : null}
                      </div>
                    </div>
                  </div>
                  <div className="dlg_bttn_flx">
                    <button
                      className="btn btn-danger err_dwn_bttn"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary err_dwn_bttn"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </h1>
              {/* <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={()=>{}} autoFocus>
                  Save
                </Button>
              </DialogActions> */}
            </Dialog>
          </>

          <div className="pos_ordr_dtls">
            <div className="pos_ordr_dtls_uppr">
              <div className="ordr_dtls_top">
                <h6>Order details</h6>
                <div className="ordr_dtls_top_icns">
                  <button className="nw_bl_svg">
                    <img
                      src={require("../../../../assets/images-pos/printer.png")}
                      alt="img"
                    />
                  </button>
                  <button className="nw_bl_svg">
                    <img
                      src={require("../../../../assets/images-pos/dwnd.png")}
                      alt="img"
                    />
                  </button>
                </div>
              </div>

              {totalBucket?.length > 0 ? (
                totalBucket?.map((item, ind) => {
                  const theItem = products?.filter(
                    (a) => a?.variant?.sku == item?.sku
                  );
                  return (
                    <div key={ind} className="pos_prdt_flx">
                      <div className="pos_prdt_flx_lft">
                        <span>1.</span>
                        <div className="pos_prdt_nme">
                          <label>{item?.proName}</label>
                          <div className="pos_prdt_size">
                            <div className="pos_prdt_size_slct">
                              <span className="side">
                                Color : <h3>{item?.color}</h3>
                              </span>

                              {/* <select>
                                  <option value="grm">500g</option>
                                  <option value="grm">500g</option>
                                </select> */}
                            </div>
                            <div className="pos_prdt_size_slct side">
                              <span>Qty : </span>
                              {/* <select>
                              <option value="Qty">1</option>
                              <option value="Qty">1</option>
                            </select> */}
                              <select
                                onChange={(e) => {
                                  const quantity = Number(e.target.value);
                                  // const theItem = products.filter(
                                  //   (a) => a?._id == item?.variantId
                                  // );
                                  // console.log(theItem[0], "chek");
                                  // if (quantity > theItem[0]?.quantity) {
                                  // if (quantity > theItem[0]?.quantity) {
                                  //   alert(
                                  //     `Sorry only ${theItem[0]?.quantity} products is abilable`
                                  //   );
                                  // } else {
                                  //   if (bucId == "") {
                                  //     dispatch(
                                  //       updateBucketQun({
                                  //         // productId: item?.productId,
                                  //         variantId: item?.variantId,
                                  //         quantity,
                                  //       })
                                  //     );
                                  //   } else {
                                  //     dispatch(
                                  //       updateBucketQun({
                                  //         // productId: item?.productId,
                                  //         variantId: item?.variantId,
                                  //         quantity,
                                  //         bucketid: bucId,
                                  //       })
                                  //     );
                                  //   }
                                  // }
                                  dispatch(
                                    BucketMenegeAction.updateQuentityofBucket({
                                      obj: item,
                                      num: quantity,
                                    })
                                  );
                                }}
                              >
                                <option
                                  value="1"
                                  disabled={theItem?.[0]?.quantity < 1}
                                  selected={item?.qn == 1}
                                >
                                  1
                                </option>
                                <option
                                  value="2"
                                  disabled={theItem?.[0]?.quantity < 2}
                                  selected={item?.qn == 2}
                                >
                                  2
                                </option>
                                <option
                                  value="3"
                                  disabled={theItem?.[0]?.quantity < 3}
                                  selected={item?.qn == 3}
                                >
                                  3
                                </option>
                                <option
                                  disabled={theItem?.[0]?.quantity < 4}
                                  value="4"
                                  selected={item?.qn == 4}
                                >
                                  4
                                </option>
                                <option
                                  value="5"
                                  disabled={theItem?.[0]?.quantity < 5}
                                  selected={item?.qn == 5}
                                >
                                  5
                                </option>
                                <option
                                  value="6"
                                  disabled={theItem?.[0]?.quantity < 6}
                                  selected={item?.qn == 6}
                                >
                                  6
                                </option>
                                <option
                                  value="7"
                                  disabled={theItem?.[0]?.quantity < 7}
                                  selected={item?.qn == 7}
                                >
                                  7
                                </option>
                                <option
                                  value="8"
                                  disabled={theItem?.[0]?.quantity < 8}
                                  selected={item?.qn == 8}
                                >
                                  8
                                </option>
                                <option
                                  value="9"
                                  disabled={theItem?.[0]?.quantity < 9}
                                  selected={item?.qn == 9}
                                >
                                  9
                                </option>
                                <option
                                  value="10"
                                  disabled={theItem?.[0]?.quantity < 10}
                                  selected={item?.qn == 10}
                                >
                                  10
                                </option>
                              </select>
                            </div>
                          </div>
                          <h5 style={{ color: "#005e5a", fontWeight: "500" }}>
                            {theItem?.[0]?.quantity} Item available
                          </h5>
                          <div className="pos_prdt_off">
                            <label className="off_lbl fs-3">
                              {/* ${loading ? "calculating..." : item?.subTotal} */}
                              {/* {item?.subTotal} */}
                              {item?.price * item?.qn}
                            </label>
                            {/* <span>$45.00</span>
                          <label className="off_lbl red">55% off</label> */}
                          </div>
                        </div>
                      </div>
                      <div className="pos_prdt_flx_rght">
                        <button
                          onClick={() => {
                            // dispatch(
                            //   deletefromBucket({
                            //     vi: item?.variantId,
                            //     bucId: bucId,
                            //   })
                            // );
                            // fetch(`${removeFromBucket}/${item?.variantId}`, {
                            //   method: "PUT",
                            //   headers: {
                            //     Authorization:
                            //       "Bearer " + localStorage.getItem("token"),
                            //   },
                            // })
                            //   .then((res) => res.json())
                            //   .then((res) => {
                            //     console.log(res, "chek");
                            //   })
                            //   .catch((err) => {
                            //     console.warn(err);
                            //   });
                            dispatch(
                              BucketMenegeAction.deleteProductFromBucket(item)
                            );
                          }}
                          className="rmv"
                        >
                          <FontAwesomeIcon icon={faTrash} size="2xl" />
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h2>Your bucket is empty.</h2>
              )}

              {/* <>
                <div className="pos_prdt_flx">
                  <div className="pos_prdt_flx_lft">
                    <span>1.</span>
                    <div className="pos_prdt_nme">
                      <label>Product Name goes here</label>
                      <div className="pos_prdt_size">
                        <div className="pos_prdt_size_slct">
                          <span>Size</span>
                          <select>
                            <option value="grm">500g</option>
                            <option value="grm">500g</option>
                          </select>
                        </div>
                        <div className="pos_prdt_size_slct">
                          <span>Qty</span>
                          <select>
                            <option value="Qty">1</option>
                            <option value="Qty">1</option>
                          </select>
                        </div>
                      </div>
                      <div className="pos_prdt_off">
                        <label className="off_lbl">$25.00</label>
                        <span>$45.00</span>
                        <label className="off_lbl red">55% off</label>
                      </div>
                    </div>
                  </div>
                  <div className="pos_prdt_flx_rght">
                    <button className="rmv">
                      <FontAwesomeIcon icon={faTrash} size="2xl" />
                    </button>
                  </div>
                </div>
                <div className="pos_prdt_flx">
                  <div className="pos_prdt_flx_lft">
                    <span>1.</span>
                    <div className="pos_prdt_nme">
                      <label>Product Name goes here</label>
                      <div className="pos_prdt_size">
                        <div className="pos_prdt_size_slct">
                          <span>Size</span>
                          <select>
                            <option value="grm">500g</option>
                            <option value="grm">500g</option>
                          </select>
                        </div>
                        <div className="pos_prdt_size_slct">
                          <span>Qty</span>
                          <select>
                            <option value="Qty">1</option>
                            <option value="Qty">1</option>
                          </select>
                        </div>
                      </div>
                      <div className="pos_prdt_off">
                        <label className="off_lbl">$25.00</label>
                        <span>$45.00</span>
                        <label className="off_lbl red">55% off</label>
                      </div>
                    </div>
                  </div>
                  <div className="pos_prdt_flx_rght">
                    <button className="rmv">
                      <FontAwesomeIcon icon={faTrash} size="2xl" />
                    </button>
                  </div>
                </div>
                <div className="pos_prdt_flx">
                  <div className="pos_prdt_flx_lft">
                    <span>1.</span>
                    <div className="pos_prdt_nme">
                      <label>Product Name goes here</label>
                      <div className="pos_prdt_size">
                        <div className="pos_prdt_size_slct">
                          <span>Size</span>
                          <select>
                            <option value="grm">500g</option>
                            <option value="grm">500g</option>
                          </select>
                        </div>
                        <div className="pos_prdt_size_slct">
                          <span>Qty</span>
                          <select>
                            <option value="Qty">1</option>
                            <option value="Qty">1</option>
                          </select>
                        </div>
                      </div>
                      <div className="pos_prdt_off">
                        <label className="off_lbl">$25.00</label>
                        <span>$45.00</span>
                        <label className="off_lbl red">55% off</label>
                      </div>
                    </div>
                  </div>
                  <div className="pos_prdt_flx_rght">
                    <button className="rmv">
                     
                      <FontAwesomeIcon icon={faTrash} size="2xl" />
                    </button>
                  </div>
                </div>
              </> */}
            </div>

            <div className="pos_ordr_dtls_lwr">
              <div className="pos_ordr_ttl_dtls">
                <label>
                  {/* Total - <span>${totalPrice ?? 0}</span> */}
                  Total -{" "}
                  {/* <span>${loading ? "Calculating" : totalPrice ?? 0}</span> */}
                  <span>$ {finalPrice}</span>
                </label>
              </div>
            </div>
            <div className="pos_ordr_dtls_bttm">
              {/* {!userSelect || BucketItems.length < 1 ? (
                <h2>
                  Please select customer and add an item to bucket for Check
                  out...
                </h2>
              ) : (
                <button
                  onClick={() => {
                    onCheckOutClick(5);
                  }}
                  className="chk_ot"
                >
                  Check Out
                </button>
              )} */}
              <button
                title="set customer & products both"
                onClick={() => {
                  onHoldClick();
                }}
                className="chk_ot"
                style={{
                  opacity: !firstName || totalBucket?.length < 1 ? "0.4" : "1",
                }}
                disabled={!firstName || totalBucket?.length < 1}
              >
                Hold billing
              </button>
              <button
                title="set customer & products both"
                style={{
                  opacity: !firstName || totalBucket?.length < 1 ? "0.4" : "1",
                }}
                onClick={onCheckOutClick}
                className="chk_ot"
                disabled={!firstName || totalBucket?.length < 1}
              >
                Check Out
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}

      {/* <div
        className="modal fade fs-1"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        {userSelect ? (
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  New seller Added successfully <br />
                  <h1>Thank you </h1>
                </h5>
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>


          </div>
        ) : (
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  User Detail form
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label for="recipient-name" className="col-form-label">
                      First Name :
                    </label>
                    <input
                      name="fn"
                      value={values.fn}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className="form-control"
                      id="recipient-name"
                    />
                    <span className="text-danger fs-5">
                      {errors.fn && touched.fn ? errors.fn : null}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label for="recipient-name" className="col-form-label">
                      Last Name :
                    </label>
                    <input
                      name="ln"
                      value={values.ln}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className="form-control"
                      id="recipient-name"
                    />
                    <span className="text-danger fs-5">
                      {errors.ln && touched.ln ? errors.ln : null}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label for="recipient-name" className="col-form-label">
                      Phone number :
                    </label>
                    <input
                      name="ph"
                      value={values.ph}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      minLength={10}
                      maxLength={10}
                      className="form-control"
                      id="recipient-name"
                    />
                    <span className="text-danger fs-5">
                      {errors.ph && touched.ph ? errors.ph : null}
                    </span>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                
              </div>
            </div>
          </div>
        )}
      </div> */}
    </>
  );
};

// export { handleBeforeUnload };
export default CustomerProduct;
