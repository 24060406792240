import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import ToastAlert from "../../components/ToastAlert"
import axios from "axios";
import { getprevHoldoredr } from "../../constants/Api/Api";

const findHoldBill=(s,a)=>{
    let res=-2;
    if (s.length>0) {
        res=s.findIndex(r=>r?.phone==a.phone);
    }
    return res
}


export const getPreviousholdBill=createAsyncThunk('getPreviousholdBill',async(arg,thunkAPI)=>{
  try {
    const res=await axios.get(getprevHoldoredr,{
        headers:{Authorization:'Bearer '+localStorage.getItem('token')}
    })
    console.log(res?.data,'ccc')
    if (res?.data?.success) {
        return res?.data?.data
    }else{

    }
  } catch (error) {
    console.warn(error);  
  }
})

const HoldBucketMeneg = createSlice({
    name:'HoldBucketMeneg',
    initialState:()=>{return JSON.parse(localStorage.getItem('holdBill'))||[]},
    // initialState:[],
    reducers:{
         addtoHoldBucket:(st,ac)=>{  
              
                    
    
            if (st.length>=4) {
                ToastAlert('error,','you cant keep more then 4 customer on hold bill')
            }else{
                localStorage.setItem('holdBill',JSON.stringify([...st,{...ac.payload}]))
                return [...st,{...ac.payload}];                
            }
         },
         removeFromHodBill:(st,ac)=>{
           const flag=findHoldBill(st,ac?.payload);
           st.splice(flag,1);
           localStorage.setItem('holdBill',JSON.stringify(st))
           return st
         },
         makeEmptyHOldBill: (st, ac) => {
            st = [];
            localStorage.setItem('holdBill',JSON.stringify(st))
            return st
             }
    },

    extraReducers: (builder) => {
        builder
          .addCase(getPreviousholdBill.pending, (state) => {
            // state.loading = true
          })
          .addCase(getPreviousholdBill.fulfilled, (state, action) => {
            if (action?.payload) {
              console.log(action?.payload,'check')
             return state = action?.payload
                          }
            // state.loading = false;
          })
          .addCase(getPreviousholdBill.rejected, (state, action) => {
            // state.loading = false;

           
          })}
})
const HoldBucketMenegAction=HoldBucketMeneg.actions
export  {HoldBucketMeneg,HoldBucketMenegAction}