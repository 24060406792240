import React, { useEffect, useState } from "react";
import Sidebar from "../components/common/Sidebar";
import NavBar from "../components/common/Nav/NavBar";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMultipleBiils,
  DeleteOneBillOfList,
  fetchingAllBils,
  showEachBill,
  updateStatusBeforeRender,
} from "../redux/GetAllBill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faEllipsis,
  faEllipsisV,
  faPen,
  faSort,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy, faEye } from "@fortawesome/free-regular-svg-icons";
import { Box, Dialog, Drawer, Pagination, Stack } from "@mui/material";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
// import "antd/dist/antd.css";
const ListOfBill = () => {
  const { RangePicker } = DatePicker;
  const {
    allBillloading,
    AllBilldata,
    currencySymbol,
    totalPaybles,
    dueToday,
    Due30days,
    overDueBils,
  } = useSelector((a) => a.AllBills);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const funtionforcalllatter = async () => {
  //   const one = await dispatch(updateStatusBeforeRender());
  //   const two = await dispatch(fetchingAllBils());
  // };
  useEffect(() => {
    const logintime = localStorage.getItem("loginTime");
    const loginDate = new Date(Number(logintime)).getDate();
    const futereDate = new Date();
    futereDate.setDate(loginDate + 1);
    futereDate.setHours(0, 0, 0, 0);
    if (futereDate.getTime() - new Date().getTime() <= 0) {
      dispatch(updateStatusBeforeRender()).then((a) => {
        dispatch(fetchingAllBils());
        console.log(2);
      });
    } else {
      dispatch(fetchingAllBils());
      console.log(1);
    }
  }, []);
  // console.log(AllBilldata, "iii");
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };

  const [searchBy, setSearchBy] = useState("");
  const [theStatus, setStatus] = useState("all");
  const [perPage, setPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState("1900-10-10");
  const [endDate, setEndDate] = useState(new Date());
  const [DateDialog, setDateDialog] = useState(false);
  const [selectedBill, setSelectedBill] = useState([]);
  // console.log(
  //   new Date(AllBilldata[0]?.billDate).getTime(),
  //   new Date("2024-10-23").getTime(),
  //   "abc"
  // );
  // console.log(new Date(AllBilldata[0]?.billDate), "abc");
  const filteredByDate = AllBilldata?.filter((each) => {
    // console.log(startDate, endDate, "abc");
    return (
      new Date(each?.billDate).getTime() >= new Date(startDate) &&
      new Date(each?.billDate).getTime() <= new Date(endDate)
    );
  });
  // console.log(filteredByDate, "abc");

  const filtredByStatus = filteredByDate?.filter((val, ind) => {
    if (theStatus === "all") {
      return val;
    } else {
      return val?.status?.split(" ")[0] == theStatus;
    }
  });
  const totalPageNumber = Math.ceil(filtredByStatus.length / perPage);
  // console.log(AllBilldata[0]?.billDate, "abc");
  // console.log(AllBilldata, "eee");
  // console.log(filtredByStatus, "eee");
  // console.log(perPage, "dsf");

  const todayy = new Date();
  const c = dayjs();

  const filterBySupplierName = filtredByStatus.filter((v, i) => {
    return v?.supplierId?.name
      ?.toLowerCase()
      ?.includes(searchBy.toLocaleLowerCase());
  });

  // console.log(filterBySupplierName, "abc");

  const paginateddata = filterBySupplierName?.slice(
    (pageNumber - 1) * perPage,
    perPage * pageNumber
  );

  if (allBillloading) {
    return (
      <div className="centered-container">
        <div>
          <img src={require("../assets/images/bank.png")} />
          <h1 className="text-center">Please wait...</h1>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {/* <div className="ttl_mdl"></div>
        <main>
          <section className="total_parent_element">
            <div className="left_parent_element">
              <div className="total_upper_left">
                <div className="logo_area">
                  <img
                    style={{ width: "82%", height: "50px", margin: "auto" }}
                    src={require("../assets/images/bank.png")}
                  />
                </div>
                <div className="nav_area">
                  <div className="outr_dashboard_nav">
                    <div className="parent_nav_menu">
                      <Sidebar />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right_parent_element">
              <NavBar /> */}

        <main>
          <section className="total_parent_element bl_scrn_sec">
            <div className="right_parent_element">
              <div className="outr-right-content">
                <div className="bIlls_page">
                  <div className="top_drv">
                    <div className="top_billchk_top">
                      <div className="billchk_brdcrm" aria-label="breadcrumb">
                        <ol className="breadcrumb bliiN">
                          <li className="breadcrumb-item">
                            <a href="javascript:void(0);">POS</a>
                          </li>
                          <li className="breadcrumb-item active">
                            <a href="javascript:void(0);">Bills</a>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="top_bill_postn">
                  <div className="bill_postn_flx">
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Total Payables</span>
                      <h5 className="bill_prt_hdng">
                        {currencySymbol +
                          " " +
                          totalPaybles
                            ?.toLocaleString("hi-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                            .slice(1, totalPaybles.length)}
                      </h5>
                    </div>
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Due Today</span>
                      <h5 className="bill_prt_hdng">
                        {currencySymbol +
                          " " +
                          dueToday
                            ?.toLocaleString("hi-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                            .slice(1, dueToday.length)}
                      </h5>
                    </div>
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Due within 30 days</span>
                      <h5 className="bill_prt_hdng">
                        {currencySymbol +
                          " " +
                          Due30days.toLocaleString("hi-IN", {
                            style: "currency",
                            currency: "INR",
                          }).slice(1, Due30days.length)}
                      </h5>
                    </div>
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Over Due Bills</span>
                      <h5 className="bill_prt_hdng">
                        {currencySymbol +
                          " " +
                          overDueBils
                            ?.toLocaleString("hi-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                            .slice(1, overDueBils.length)}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="blls_drive">
                  <div className="blls_drive_lft">
                    <select
                      onChange={(e) => {
                        setStatus(e.target.value);
                        setPageNumber(1);
                      }}
                    >
                      <option value="all">All Bills</option>
                      <option value="open">Open Bills</option>
                      <option value="paid">Paid Bills</option>
                      <option value="overdue">Overdue Bills</option>
                      <option value="partially">Partially Paid Bills</option>
                    </select>

                    <div className="drv_rght_rte">
                      <input
                        type="search"
                        placeholder="Search vendor name"
                        onChange={(e) => {
                          setSearchBy(e.target.value);
                        }}
                      />
                    </div>

                    {/* {selectedBill?.length > 0 && (
                      <span>
                        <button
                          className="btn btn-primary fs-1"
                          onClick={() => {
                            dispatch(deleteMultipleBiils([...selectedBill]));
                          }}
                        >
                          Delete
                        </button>
                      </span>
                    )} */}
                    {/* <button class="drv_lst_img">
                        <span class="drv_lst_pic">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </span>
                      </button> */}

                    {selectedBill?.length > 0 && (
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-popover"
                      >
                        {(popupState) => (
                          <>
                            <button
                              className="drv_lst_img"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <span class="drv_lst_pic">
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </span>
                            </button>
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <Typography
                                onClick={() => {
                                  dispatch(
                                    deleteMultipleBiils([...selectedBill])
                                  );
                                  popupState.close();
                                }}
                                sx={{ fontSize: "17px", p: 1 }}
                              >
                                Delete
                              </Typography>
                              <Typography
                                onClick={() => {
                                  popupState.close();
                                }}
                                sx={{ fontSize: "17px", p: 1 }}
                              >
                                Option 2
                              </Typography>
                            </Popover>
                          </>
                        )}
                      </PopupState>
                    )}
                  </div>
                  <div className="blls_drive_rght">
                    <div className="drive_rght_flx">
                      <div className="drv_rght_lft">
                        <button className="nw_cstmr">
                          Create New Bill
                          <span>+</span>
                        </button>
                      </div>
                      <div className="drv_rght_rte">
                        <div className="dt_rnge_ttl">
                          <span className="dt_rng">Date Range:</span>
                          <select
                            id="filterBillDate"
                            onChange={(e) => {
                              let sd = new Date();
                              let ed = new Date();
                              ed.setHours(23, 59, 59, 999);
                              switch (e.target.value) {
                                case "today":
                                  sd.setHours(0, 0, 0, 0);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;
                                case "thisWeek":
                                  sd.setDate(
                                    todayy.getDate() - todayy.getDay()
                                  );
                                  sd.setHours(0, 0, 0, 0);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;
                                case "thisMonth":
                                  sd.setDate(1);
                                  sd.setHours(0, 0, 0, 0);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;
                                case "30":
                                  sd.setDate(todayy.getDate() - 30);
                                  sd.setHours(0, 0, 0, 0);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;
                                case "custom":
                                  setStartDate(new Date(1900, 1, 1));
                                  setDateDialog(true);
                                  break;
                                case "7":
                                  sd.setDate(todayy.getDate() - 7);
                                  sd.setHours(0, 0, 0, 0);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;
                                case "reset":
                                  sd.setFullYear(1900);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;

                                default:
                                  // Code to be executed if no case matches
                                  break;
                              }
                            }}
                            className="dt_rnge_optn"
                          >
                            <option value="">Select date</option>
                            <option value="today">Today</option>
                            <option value="thisWeek">This Week</option>
                            <option value="thisMonth">This month</option>
                            <option value="30">Last 30 days</option>
                            <option value="7">Last 7 days</option>
                            <option value="reset">Reset</option>
                            <option value="custom">Custom</option>
                          </select>
                        </div>
                      </div>
                      <div className="drv_lst_lst">
                        <button className="drv_lst_img">
                          <span className="drv_lst_pic">
                            {/* <img src="./images/prnt.png" alt="img" /> */}
                            <img
                              src={require("../assets/images/prnt.png")}
                              alt=""
                            />
                          </span>
                        </button>
                        <button className="drv_lst_img">
                          <span className="drv_lst_pic">
                            {/* <img src="./images/bl_cp.png" alt="img" />  */}
                            <img
                              src={require("../assets/images/bl_cp.png")}
                              alt=""
                            />
                          </span>
                        </button>
                        <button className="drv_lst_img">
                          <span className="drv_lst_pic">
                            {/* <img src="./images/bl_stng.png" alt="img" /> */}
                            <img
                              src={require("../assets/images/bl_stng.png")}
                              alt=""
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="blls_drive_tbl_ottr">
                    <table className="blls_drive_tbl_main">
                      <thead>
                        <tr>
                          <th>
                            {/* <p style={{ color: "#005e5a" }}>
                              {selectedBill?.length} item selected
                            </p> */}
                            Bill No.
                          </th>
                          <th>Po No.</th>
                          <th>Vendor Name</th>
                          <th>Billing Date</th>
                          <th>Due Date</th>
                          <th>Status</th>
                          <th>Total Amount</th>
                          <th>Due Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginateddata && paginateddata.length > 0 ? (
                          paginateddata.map((eachbill, eachind) => {
                            // console.log(eachbill, "abc");
                            return (
                              <tr key={eachbill?._id}>
                                <td>
                                  <div className="bl_no_flx">
                                    <input
                                      type="checkbox"
                                      className="bl_no_inpt"
                                      onChange={(e) => {
                                        setSelectedBill((pre) => {
                                          const res = [...pre];
                                          const indexofId = res.indexOf(
                                            eachbill?._id
                                          );
                                          // console.log(indexofId, "abc");
                                          if (indexofId === -1) {
                                            return [...res, eachbill?._id];
                                          } else {
                                            res.splice(indexofId, 1);
                                            return res;
                                          }
                                        });
                                      }}
                                    />
                                    <span className="bl_no_spn">
                                      {eachbill?.billNumber}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {eachbill?.purchaseOrderNumber}
                                  </span>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {eachbill?.supplierId?.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {new Date(eachbill?.billDate)
                                      ?.toLocaleDateString("en-GB", options)
                                      .replaceAll("/", "-")}
                                  </span>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {new Date(eachbill?.dueDate)
                                      ?.toLocaleDateString("en-GB", options)
                                      .replace(/\//g, "-")}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className="bl_no_spn grn"
                                    style={
                                      eachbill?.status == "paid" ||
                                      eachbill?.status == "partially paid"
                                        ? { color: "green" }
                                        : eachbill?.status == "open"
                                        ? { color: "blue" }
                                        : { color: "red" }
                                    }
                                  >
                                    {eachbill?.status.toUpperCase()}
                                  </span>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {currencySymbol +
                                      " " +
                                      eachbill?.totalAmount?.toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {currencySymbol +
                                      " " +
                                      eachbill?.balanceDue?.toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  <div className="drv_tbl_icns dropdown">
                                    <button
                                      className="dropdown-toggle"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <FontAwesomeIcon icon={faSort} />
                                    </button>
                                    <ul className="dropdown-menu">
                                      <li>
                                        <button className="dpdn_btn_icns pen">
                                          <FontAwesomeIcon icon={faPen} />
                                          Edit
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="dpdn_btn_icns trash"
                                          onClick={(e) => {
                                            dispatch(
                                              DeleteOneBillOfList(eachbill?._id)
                                            );
                                          }}
                                        >
                                          {/* <i className="fa fa-trash-alt" />{" "} */}
                                          <FontAwesomeIcon icon={faTrash} />
                                          Delete
                                        </button>
                                      </li>
                                      <li>
                                        <button className="dpdn_btn_icns copy">
                                          {/* <i className="fa fa-copy" /> */}
                                          <FontAwesomeIcon icon={faCopy} />
                                          Marge
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="dpdn_btn_icns eye"
                                          onClick={() => {
                                            navigate(
                                              `/eachBill/${eachbill?._id}`
                                            );
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEye} />
                                          View
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <h1
                            style={{
                              textAlign: "center",
                              padding: "25px",
                            }}
                          >
                            There are no bill.
                          </h1>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="bttm_drv">
                    <div className="bttm_drv_lft">
                      <div className="bttm_drv_pg_slct">
                        <select
                          onChange={(e) => {
                            setPerPage(e.target.value);
                            setPageNumber(1);
                          }}
                        >
                          <option value={5}>5 per page</option>
                          <option value={10}>10 per page</option>
                          <option value={15}>15 per page</option>
                          <option value={20}>20 per page</option>
                        </select>
                      </div>
                    </div>
                    <div className="bttm_drv_rght">
                      <div aria-label="Page navigation example">
                        {/* <ul className="pagination bttm_drv_pgtn">
                              <li className="page-item">
                                <button className="page-link prv">
                                  <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                              </li>
                              {Array.from({ length: totalPageNumber }).map(
                                (v, i) => {
                                  return (
                                    <li className="page-item active">
                                      <button className="page-link" >
                                        {i + 1}
                                      </button>
                                    </li>
                                  );
                                }
                              )}

                              <li className="page-item">
                                <button className="page-link nxt" href="#">
                                  <i className="fa fa-chevron-right" />
                                  <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                              </li>
                            </ul> */}
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          width="100%"
                        >
                          <Stack
                            spacing={2}
                            style={{
                              justifyContent: "flex-end",
                              display: "flex",
                            }}
                          >
                            <Pagination
                              count={totalPageNumber}
                              onChange={(e, v) => {
                                setPageNumber(v);
                              }}

                              // sx={{
                              //   "& .MuiPaginationItem-root": {
                              //     color: "#008080", // Default color for pagination items
                              //   },
                              //   "& .Mui-selected": {
                              //     backgroundColor: "#008080", // Background color for the selected page
                              //     color: "#ffffff", // Text color for the selected page
                              //   },
                              //   "& .MuiPaginationItem-ellipsis": {
                              //     color: "#008080", // Color for ellipsis if present
                              //   },
                              //   "&:hover .MuiPaginationItem-root": {
                              //     backgroundColor: "rgba(0, 128, 128, 0.1)", // Background color on hover
                              //   },
                              // }}
                            />
                          </Stack>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="right_notifictaion">
                  <div className="outr_notifictaion">
                    <div className="outr_notifictaion">
                      <div className="innr_top">
                        <div className="ntfcn_hdr">
                          <h6>Notifications</h6>
                        </div>
                        <div className="ntfcn_divs">
                          <div className="ntfcn_div">
                            <div className="nt_icn">
                              <span>
                                <i className="fa-solid fa-bug" />
                              </span>
                            </div>
                            <div className="nt_txt">
                              <h6>You fixed a bug.</h6>
                              <p>Just now</p>
                            </div>
                          </div>
                          <div className="ntfcn_div">
                            <div className="nt_icn">
                              <span>
                                <i className="fa-regular fa-user" />
                              </span>
                            </div>
                            <div className="nt_txt">
                              <h6>New user registered.</h6>
                              <p>59 minutes ago</p>
                            </div>
                          </div>
                          <div className="ntfcn_div">
                            <div className="nt_icn">
                              <span>
                                <i className="fa-solid fa-bug" />
                              </span>
                            </div>
                            <div className="nt_txt">
                              <h6>You fixed a bug.</h6>
                              <p>12 hours ago</p>
                            </div>
                          </div>
                          <div className="ntfcn_div">
                            <div className="nt_icn">
                              <span>
                                <i className="fa-solid fa-tower-broadcast" />
                              </span>
                            </div>
                            <div className="nt_txt">
                              <h6>Andi Lane subscribed to you.</h6>
                              <p>Today, 11:59 AM</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="innr_ordr">
                        <div className="orde-hdr">
                          <h6>Online Orders</h6>
                          <h6>
                            Rs - <span>230</span> - zomato - 10 min
                          </h6>
                        </div>
                        <div className="order_btns">
                          <div className="btn">
                            <a href="javascript:void(0);">Cooking</a>
                          </div>
                          <div className="btn">
                            <a href="javascript:void(0);">Delivered</a>
                          </div>
                        </div>
                      </div>
                      <div className="innr_tbl_bkn">
                        <div className="orde-hdr">
                          <h6>Table Bookings</h6>
                          <h6>26 Dec Manish Sethia 7:30 pm</h6>
                        </div>
                        <div className="order_btns tbl">
                          <div className="btn">
                            <span>
                              <i className="fa-solid fa-check" />
                            </span>
                            <a href="javascript:void(0);">Approve</a>
                          </div>
                          <div className="btn">
                            <span>
                              <i className="fa-solid fa-xmark" />
                            </span>
                            <a href="javascript:void(0);">Decline</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
            {/* <div class="right_sidebar_content">
    <div class="outr_rightsbcntnt">
      <div class="sdbr_oprtr_inpt">
        <div class="sdbr_oprtr_inpt_top">
          <div class="oprt_inpt_info">
            <label>Operator :</label>
            <input type="text" placeholder="Owner" />
            <span><img src="images/sdbr_arw.png" alt="arrow" /></span>
          </div>
          <div class="oprt_inpt_info">
            <label>Client :</label>
            <input type="text" placeholder="Cash Sale" />
            <span><img src="images/sdbr_arw.png" alt="arrow" /></span>
          </div>
        </div>
        <div class="oprt_inpt_info_thrd">
          <span>TYPE</span> <span>POINTS/ITEMS :</span>
          <span>$ VALUE: </span>
        </div>
      </div>
      <div class="sdbr_oprtr_inpt_btm">
        <div class="oprt_inpt_info oprt_inpt_info_thrd">
          <div class="brcd">
            <label>Barcode/Quantity :</label> <br />
            <input type="text" placeholder="" />
          </div>
          <div class="brcd oprt_inpt_info">
            <label>Total :</label>
            <span><img src="images/total_arw.png" alt="arrow" /></span>
          </div>
          <input type="text" placeholder="$91.50" />
        </div>
      </div>
    </div>
  </div> */}
          </section>
        </main>
        {/* </div>
          </section>
        </main> */}
        <Dialog
          anchor="right"
          open={DateDialog}
          onClose={(e) => {
            setDateDialog(false);
            document.getElementById("filterBillDate").value = "";
          }}
        >
          <h1>
            Select start date and end date for filter list of bill
            <br />
            <RangePicker
              onChange={(e) => {
                const startd = new Date(e[0]);
                const endDate = new Date(e[1]);
                endDate.setHours(23, 59, 59, 999);
                setStartDate(startd);
                setEndDate(endDate);
                setDateDialog(false);
                document.getElementById("filterBillDate").value = "";
              }}
              dropdownClassName="forIncreaseZIndex"
            />
            <button
              className="btn btn-danger"
              onClick={(e) => {
                setDateDialog(false);
                document.getElementById("filterBillDate").value = "";
              }}
            >
              cancel
            </button>
          </h1>
        </Dialog>
      </>
    );
  }
};

export default ListOfBill;
