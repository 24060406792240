import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAllProducts } from "../../constants/Api/Api";

 export  const getproducts=createAsyncThunk('products',async(arg, { rejectWithValue }) => {
    // Perform the asynchronous operation
    try {
      const response = await axios.get(`${getAllProducts}`,{
        headers:{
            Authorization:"Bearer "+ localStorage.getItem('token')
        }
      })
  
return response.data.data
    //   return response.data; // The value returned here will be passed to the fulfilled action
    } catch (error) {
      return rejectWithValue(error.message); // The value returned here will be passed to the rejected action
    }
  })

const ProductsSlice=createSlice({
    name:'Products',
    initialState:{
        products:[],
        status:''
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getproducts.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getproducts.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.products=action.payload
        })
        .addCase(getproducts.rejected, (state, action) => {
          state.status = 'failed';
          // Use the custom error payload here
          state.error = action.payload.message;
        });
    }
})

export default ProductsSlice.reducer
