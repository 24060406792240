import React from "react";

const LoaderImage = () => {
  return (
    <>
      <div className="centered-container">
        <>
          {/* <img src={require("../../assets/images/bank.png")} /> */}

          <div
            className="spinner-border"
            style={{ color: "#005e5a", fontSize: "200px" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
          {/* <span className="text-center">Please wait...</span> */}
        </>
      </div>
    </>
  );
};

export default LoaderImage;
