import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faEye,
  faMagnifyingGlass,
  faPlus,
  faSort,
  faStar,
  faTrash,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import ordr_img1 from "../../../assets/images/ordr_img1.png";

import { Bounce, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/Sidebar";
import {
  logout,
  sellerProduct,
} from "../../../redux/features/sellers/sellerLoginSlice";
import { allProducts } from "../../../redux/features/sellers/sellerProductSlice";
import axios from "axios";
import NavBar from "../../common/Nav/NavBar";
import {
  allSuppliers,
  updateStatus,
} from "../../../redux/features/sellers/sellerPurchaseSlice";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const SupplierList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [toggle, setToggle] = useState(false);
  const [toggleClick, setToggleClick] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [statusDetail, setStatusDetail] = useState();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const { loading, suppliers } = useSelector((state) => state.sellerPurchase);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = suppliers?.slice(firstIndex, lastIndex);
  console.log(records, "records");

  const npage =
    suppliers?.length > 0 && Math.ceil(suppliers.length / recordsPerPage);
  console.log(npage, "npage");
  const numbers = [...Array(npage + 1).keys()].slice(1);
  console.log(numbers, "numbers");

  useEffect(() => {
    dispatch(allSuppliers());
  }, []);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(0, 0);
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
    window.scrollTo(0, 0);
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(0, 0);
  };

  const changeStatus = (props) => {
    setStatusDetail(props);
  };

  const toggleForChangeStatus = () => {
    setModal(!modal);
    dispatch(updateStatus(statusDetail));
  };

  return (
    <>
      <div className="ttl_mdl">
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          backdrop
          className="nw_ttl_mdl"
        >
          {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
          <ModalBody>Do you want to change status ?</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle} className="edit">
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              onClick={toggleForChangeStatus}
              className="blu_edit"
            >
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div className="outr-right-content">
        <div className="oder_history">
          <div className="order_hdr">
            <div className="ordre_lft">
              <h6>Supplier List</h6>
            </div>
            <div className="ordre_rght"></div>
          </div>

          <button
            type="submit"
            href="javascript:void(0);"
            className="edit"
            onClick={() => navigate("/purchase/add-supplier")}
          >
            <FontAwesomeIcon icon={faPlus} /> New Supplier
          </button>

          <div className="blls_drive_tbl_ottr">
            <table className="blls_drive_tbl_main">
              <thead>
                <tr>
                  <th>Supplier Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {records && records?.length > 0 ? (
                  records?.map((supplier) => {
                    return (
                      <tr key={supplier?._id}>
                        <td>
                          <span className="bl_no_spn">{supplier?.name}</span>
                        </td>
                        <td>
                          <span className="bl_no_spn">{supplier?.email}</span>
                        </td>{" "}
                        <td>
                          <span className="bl_no_spn">
                            {supplier?.phoneNumber}
                          </span>
                        </td>{" "}
                        <td>
                          <div className="div2 sl-list-status">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={supplier?.status?.isActive}
                                onChange={() =>
                                  changeStatus({
                                    statusActive: supplier.status.isActive,
                                    id: supplier._id,
                                  })
                                }
                                onClick={toggle}
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="drv_tbl_icns dropdown">
                            <button
                              className="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <FontAwesomeIcon icon={faSort} />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <button className="dpdn_btn_icns trash">
                                  <FontAwesomeIcon icon={faTrash} />
                                  Delete
                                </button>
                              </li>

                              <li>
                                <NavLink
                                // to={`/products/product-detail/${product?._id}`}
                                >
                                  <button className="dpdn_btn_icns eye">
                                    <FontAwesomeIcon icon={faEye} />
                                    View
                                  </button>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <h1 style={{ fontSize: "40px" }}>No supplier found</h1>
                )}
              </tbody>
            </table>
          </div>

          {/* pagination */}
          <div className="pagination">
            <a href="javascript:void(0);" onClick={prePage}>
              «
            </a>
            {numbers.map((n, i) => (
              <a
                href="javascript:void(0);"
                key={i}
                onClick={() => changeCPage(n)}
                className={`${currentPage === n ? "active" : ""}`}
              >
                {n}
              </a>
            ))}

            <a href="javascript:void(0);" onClick={nextPage}>
              »
            </a>
          </div>
        </div>
      </div>
      {/* </div>
        </section>
      </main> */}

      {/* moumi 12.2.24 */}
    </>
  );
};

export default SupplierList;
