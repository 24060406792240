import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteOnePurchaseReceive,
  PurchaseReciveSlice,
} from "../../../redux/features/sellers/PurchaseReciveSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faEllipsisV,
  faEye,
  faPen,
  faSort,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import {
  Autocomplete,
  Drawer,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { allSuppliers } from "../../../redux/features/sellers/sellerPurchaseSlice";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import LoaderImage from "../../common/LoaderImage";

const PurchaseReciveList = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((a) => a.PurchaseReciveList);
  const { suppliers } = useSelector((a) => a.sellerPurchase);
  const [selectedBill, setSelectedBill] = useState([]);
  const [openDrower, setOpenDrower] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({
    purchaseReciveNumber: "",
    purchaseOrderNumver: "",
    vendorName: "",
    datee: "",
    billStatus: "",
  });

  // console.log(data?.data, "abc");
  useEffect(() => {
    dispatch(PurchaseReciveSlice());
    dispatch(allSuppliers());
  }, []);
  const defaultProps = {
    options: suppliers,
    getOptionLabel: (option) => option.name,
  };

  // console.log(data?.data, "abc");
  console.log(searchCriteria, "abc");

  const afterSearch = data?.data?.filter((v) => {
    if (searchCriteria?.billStatus === "") {
      return (
        v?.purchaseOrderNumber
          ?.toLowerCase()
          .includes(searchCriteria?.purchaseOrderNumver?.toLowerCase()) &&
        v?.purchaseReceiveNumber
          ?.toLowerCase()
          .includes(searchCriteria?.purchaseReciveNumber?.toLowerCase()) &&
        v?.supplierId?.name
          ?.toLowerCase()
          .includes(searchCriteria?.vendorName?.toLowerCase())
      );
    } else {
      // console.log(searchCriteria?.billStatus, "abc");
      return (
        v?.purchaseOrderNumber
          ?.toLowerCase()
          .includes(searchCriteria?.purchaseOrderNumver?.toLowerCase()) &&
        v?.purchaseReceiveNumber
          ?.toLowerCase()
          .includes(searchCriteria?.purchaseReciveNumber?.toLowerCase()) &&
        v?.isBilled === Boolean(Number(searchCriteria?.billStatus)) &&
        v?.supplierId?.name
          ?.toLowerCase()
          .includes(searchCriteria?.vendorName?.toLowerCase())
      );
    }
  });

  // console.log(afterSearch, "abc");
  return (
    <>
      {loading ? (
        <>
          <LoaderImage />
          {/* <div className="centered-container">
          <div>
            <img src={require("../../../assets/images/bank.png")} />
            <h1 className="text-center">Please wait...</h1>
          </div>
        </div> */}
        </>
      ) : (
        <>
          {selectedBill?.length > 0 ? (
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <>
                  <button
                    className="drv_lst_img"
                    variant="contained"
                    {...bindTrigger(popupState)}
                  >
                    <span class="drv_lst_pic">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </span>
                  </button>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Typography
                      onClick={() => {
                        dispatch(DeleteOnePurchaseReceive([...selectedBill]));
                        popupState.close();
                      }}
                      sx={{ fontSize: "17px", p: 1 }}
                    >
                      Delete
                    </Typography>
                    <Typography
                      onClick={() => {
                        popupState.close();
                      }}
                      sx={{ fontSize: "17px", p: 1 }}
                    >
                      Option 2
                    </Typography>
                  </Popover>
                </>
              )}
            </PopupState>
          ) : (
            <h1>All Purchase Receives</h1>
          )}
          <div className="blls_drive_tbl_ottr">
            <table className="blls_drive_tbl_main">
              <thead>
                <tr>
                  <th>
                    <p style={{ color: "#005e5a" }}>
                      {selectedBill?.length} item selected
                    </p>
                    Date.
                  </th>
                  <th>Purchase Receive.</th>
                  <th>Purchase Order.</th>
                  <th>Vendor Name</th>
                  {/* <th>Receive Status</th> */}
                  <th>Bill Status</th>
                  <th>Quantity</th>
                  <th>Action</th>
                  <th
                    onClick={() => {
                      setOpenDrower(true);
                    }}
                  >
                    <img
                      src={require("../../../assets/images-pos/magnifying-glass.png")}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {afterSearch?.map((eachReceive, eachind) => (
                  <tr key={eachReceive?._id}>
                    <td>
                      <div className="bl_no_flx">
                        <input
                          type="checkbox"
                          className="bl_no_inpt"
                          checked={selectedBill.includes(eachReceive?._id)}
                          onChange={(e) => {
                            setSelectedBill((pre) => {
                              const res = [...pre];
                              const indexofId = res.indexOf(eachReceive?._id);
                              // console.log(indexofId, "abc");
                              if (indexofId === -1) {
                                return [...res, eachReceive?._id];
                              } else {
                                res.splice(indexofId, 1);
                                return res;
                              }
                            });
                          }}
                        />
                        <span className="bl_no_spn">
                          {eachReceive?.receivedDate?.slice(0, 10)}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span className="bl_no_spn">
                        {eachReceive?.purchaseReceiveNumber}
                      </span>
                    </td>
                    <td>
                      <span className="bl_no_spn">
                        {eachReceive?.purchaseOrderNumber}
                      </span>
                    </td>
                    <td>
                      <span className="bl_no_spn">
                        {eachReceive?.supplierId?.name}
                      </span>
                    </td>
                    {/* <td>
                    <span className="bl_no_spn"></span>
                  </td> */}
                    <td>
                      <span
                        className="bl_no_spn grn"
                        style={{
                          color: eachReceive?.isBilled ? "green" : "red",
                        }}
                      >
                        {eachReceive?.isBilled ? "BILLED" : "NOT BILLED"}
                      </span>
                    </td>
                    <td>
                      <span className="bl_no_spn">{eachReceive?.quantity}</span>
                    </td>
                    <td>
                      <div className="drv_tbl_icns dropdown">
                        <button
                          className="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <FontAwesomeIcon icon={faSort} />
                        </button>
                        <ul className="dropdown-menu">
                          {/* <li>
                          <button className="dpdn_btn_icns pen">
                            <FontAwesomeIcon icon={faPen} />
                            Edit
                          </button>
                        </li> */}
                          <li>
                            <button
                              onClick={() => {
                                dispatch(
                                  DeleteOnePurchaseReceive([eachReceive?._id])
                                );
                              }}
                              className="dpdn_btn_icns trash"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                              Delete
                            </button>
                          </li>
                          {/* <li>
                          <button className="dpdn_btn_icns copy">
                            <FontAwesomeIcon icon={faCopy} />
                            Marge
                          </button>
                        </li> */}
                          <li>
                            <NavLink
                              to={`/ShowEachPurchaseReceive/${eachReceive?._id}`}
                            >
                              <button className="dpdn_btn_icns eye">
                                <FontAwesomeIcon icon={faEye} />
                                View
                              </button>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {openDrower && (
        <Drawer
          anchor="top"
          open={openDrower}
          sx={{
            "& .MuiDrawer-paper": {
              width: "40%", // Adjust the width as needed
              margin: "0 auto",
              height: "450px",
            },
          }}
        >
          <h1 className="ms-5">
            <table>
              <tbody>
                <tr>
                  <td>Purchase Receive# : </td>
                  <td>
                    <input
                      name="purchaseReciveNumber"
                      type="search"
                      value={searchCriteria?.purchaseReciveNumber}
                      onChange={(e) => {
                        setSearchCriteria((pre) => {
                          let res = { ...pre };
                          return { ...res, [e.target.name]: e.target.value };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Purchase Order# </td>
                  <td>
                    <input
                      name="purchaseOrderNumver"
                      type="search"
                      value={searchCriteria?.purchaseOrderNumver}
                      onChange={(e) => {
                        setSearchCriteria((pre) => {
                          let res = { ...pre };
                          return { ...res, [e.target.name]: e.target.value };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Vendor :</td>
                  <td>
                    <Autocomplete
                      name="vendorName"
                      onChange={(event, newValue) => {
                        // console.log(newValue?.name, "abc");
                        setSearchCriteria((pre) => {
                          let res = { ...pre };
                          return {
                            ...res,
                            ["vendorName"]: newValue?.name ?? "",
                          };
                        });
                      }}
                      sx={{
                        "& .MuiInputBase-root": { fontSize: "28px" },
                        "& .MuiAutocomplete-option": { fontSize: "28px" },
                      }}
                      {...defaultProps}
                      id="disable-close-on-select"
                      // disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select vendor name"
                          variant="standard"
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Status :</td>
                  <td>
                    <select
                      name="billStatus"
                      onChange={(e) => {
                        setSearchCriteria((pre) => {
                          let res = { ...pre };
                          return {
                            ...res,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                    >
                      <option value="">Select Status</option>
                      <option value={1}>Billed</option>
                      <option value={0}>Not Billed</option>
                    </select>
                  </td>
                </tr>{" "}
                {/* <tr>
                  <td> Receive Date :</td>
                  <td>
                    <input />
                  </td>
                </tr> */}
                <tr>
                  <td colSpan="2" style={{ textAlign: "right" }}>
                    <button
                      className="btn btn-primary fs-1"
                      onClick={() => {
                        setOpenDrower(false);
                      }}
                    >
                      Search
                    </button>
                    <button
                      onClick={() => {
                        setOpenDrower(false);
                        setSearchCriteria({
                          purchaseReciveNumber: "",
                          purchaseOrderNumver: "",
                          vendorName: "",
                          datee: "",
                          billStatus: "",
                        });
                      }}
                      className="btn btn-danger ms-2 fs-1"
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </h1>
        </Drawer>
      )}
    </>
  );
};

export default PurchaseReciveList;
