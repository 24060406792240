import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAllHoldingOrder } from "../../constants/Api/Api";

export const gettingListofHoldOrder=createAsyncThunk(
    'gettingListofHoldOrder', // Action type prefix
    async (arg, thunkAPI) => {
        try {
         const res=await   axios.get(getAllHoldingOrder,{
                headers:{Authorization:'Bearer '+ localStorage.getItem('token')}
            })

if (res?.data?.success) {
    return res.data?.data
}

        } catch (error) {
            console.warn(error,'check')
        }
    
    }
);

const holdOrder=createSlice({
    name:'holdOrder',
    initialState:{
        loading:false,
        totalHoldOreder:[]
    },
    

    extraReducers: (builder) => {
        builder
          .addCase(gettingListofHoldOrder.pending, (state) => {
            state.loading = true
          })
          .addCase(gettingListofHoldOrder.fulfilled, (state, action) => {

            if (action?.payload) {
              console.log(action?.payload,'check')
              state.totalHoldOreder=[...action.payload]
                          }
            state.loading = false;
          })
          .addCase(gettingListofHoldOrder.rejected, (state, action) => {
            state.loading = false;

           
          })}
})


export default holdOrder.reducer