import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faMagnifyingGlass,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/Sidebar";

import NavBar from "../../common/Nav/NavBar";
import { allPurchaseOrder } from "../../../redux/features/sellers/sellerPurchaseSlice";
import {
  getStock,
  inventoryDetail,
  sellerInventoryAction,
} from "../../../redux/features/sellers/sellerInventorySlice";
import {
  BASE_URL,
  getlistofwarehouse,
  sendingWarehousename,
} from "../../../constants/Api/Api";
import axios from "axios";
import { getTotalWarehouse } from "../../../redux/features/WarehouseSlice";

const InventorySummery = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [listofwarehouse, setlistofwarehouse] = useState([]);

  const { listofwarehouse } = useSelector((a) => a.Warehouse);

  useEffect(() => {
    dispatch(getTotalWarehouse());
  }, []);

  const [modal, setModal] = useState(false);

  const { loading, inventory, stock, indexx } = useSelector(
    (state) => state.sellerInventory
  );
  console.log(stock, "stock");

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  // console.log(inventory, "chek");
  const records = inventory?.slice(firstIndex, lastIndex);
  console.log(records, "records");

  const npage =
    inventory?.length > 0 && Math.ceil(inventory.length / recordsPerPage);
  console.log(npage, "npage");

  const numbers = [...Array(npage + 1).keys()].slice(1);
  console.log(numbers, "numbers");

  useEffect(() => {
    dispatch(inventoryDetail());
  }, []);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(0, 0);
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
    window.scrollTo(0, 0);
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(0, 0);
  };
  // console.log(stock, "dd");
  return (
    <>
      <div className="ttl_mdl"></div>

      {/* <main>
        <section className="total_parent_element">
          <div className="left_parent_element">
            <div className="total_upper_left">
              <div className="logo_area">
                <img
                  style={{ width: "82%", height: "50px", margin: "auto" }}
                  src={require("../../../assets/images/bank.png")}
                />
              </div>
              <div className="nav_area">
                <div className="outr_dashboard_nav">
                  <h4>Dashboards</h4>
                  <div className="parent_nav_menu">
                    <Sidebar />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="right_parent_element">
            <NavBar /> */}

      <div className="outr-right-content">
        <div className="oder_history">
          <div className="order_hdr">
            <div className="ordre_lft">
              <h6>Inventory Summery</h6>
            </div>
            <div className="ordre_rght">
              {/* <div className="ordr_srch_bx">
                      <input type="search" placeholder="Search Order Id" />
                      <span>
                        <FontAwesomeIcon icon={faMagnifyingGlass} size="2xl" />
                      </span>
                    </div> */}
              {/* <div className="ordr_date_bx">
                      <input type="date" />
                    </div> */}
            </div>
          </div>
          <div className="orders">
            {/* <input
                    type="search"
                    placeholder="search here"
                    className="fs-3 "
                  /> */}

            {/* <button
                    type="submit"
                    href="javascript:void(0);"
                    className="edit"
                    onClick={() => navigate("/purchase/purchase-order")}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Create Purchase Order
                  </button> */}
            <div className="ordr_tbl">
              <table>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>SKU</th>
                    <th>Reorder Level</th>
                    <th>Quantity Ordered</th>
                    {/* <th>Quantity In</th>
                          <th>Quantity Out</th> */}
                    <th>Stock On Hand</th>
                    {/* <th>Warehouse</th> */}
                    <th>Warehouse List</th>
                  </tr>
                </thead>
                <tbody>
                  {records &&
                    records.length > 0 &&
                    records.map((invent, i) => {
                      console.log(invent, "chekk");

                      return (
                        <tr key={invent.variant.sku}>
                          <td>
                            <div className="div1">
                              <div className="o_div_txt">
                                <h5>
                                  {invent?.productId?.productInfo?.name}
                                  <br />
                                  {`Price: ${invent?.variant?.Price} Color: ${invent.variant.Color}`}
                                </h5>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <h5>{invent.variant.sku}</h5>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <h5>{invent.variant.Reordered_Level}</h5>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <h5>{invent.quantityOrdered}</h5>
                            </div>
                          </td>
                          {/* <td>
                                  <div className="div2">
                                    <h5>{invent.quantityIn}</h5>
                                  </div>
                                </td> */}
                          {/* <td>
                                  <div className="div2">
                                    <h5>{invent.quantityOut}</h5>
                                  </div>
                                </td> */}
                          <td>
                            <div className="div2">
                              {invent.variant.Reordered_Level >
                              invent.quantity ? (
                                <span
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <h5
                                    style={{
                                      // color: "red",
                                      marginRight: "30px",
                                    }}
                                  >
                                    {invent.quantity}
                                  </h5>
                                  <h5 style={{ color: "red" }}>(Low Stock)</h5>
                                </span>
                              ) : (
                                <h5>{invent.quantity}</h5>
                              )}
                            </div>
                          </td>
                          <td>
                            <select
                              className="fs-3"
                              // name="dropdownName"
                              onChange={async (e) => {
                                // console.log(e.target.value, "chek");

                                // if (
                                //   e.target.value !=
                                //   "Select form drop down"
                                // ) {
                                // }
                                dispatch(
                                  sellerInventoryAction.clearStockData(i)
                                );
                                dispatch(
                                  getStock({
                                    warehouseid: e.target.value,
                                    variantId: invent._id,
                                    productId: invent.productId._id,
                                  })
                                );
                                // console.log(response.data, "chek");
                              }}
                            >
                              <option value="" disabled selected>
                                Select form drop down
                              </option>
                              {listofwarehouse.map((v) => (
                                <option key={v._id} value={v._id}>
                                  {v?.warehouseName}
                                </option>
                              ))}
                            </select>
                            <br />
                            {/* <h4>
                                    {loading
                                      ? "calculating..."
                                      : stock[invent?._id] >= 0 &&
                                        `stock : ${stock[invent?._id]}`}
                                  </h4> */}

                            <h4>
                              {/* {stock[invent?._id] >= 0 &&
                                      `stock : ${
                                        loading
                                          ? "calculating..."
                                          : stock[invent?._id]
                                      }`} */}
                              {/* {i == indexx && loading
                                      ? "wait..."
                                      : stock[invent?._id] >= 0 &&
                                        "stock :" + stock[invent?._id]} */}
                              {stock[invent?._id] >= 0
                                ? loading && i == indexx
                                  ? "wait..."
                                  : "stock :" +
                                    `${
                                      stock[invent?._id] <= 0
                                        ? "Empty stock"
                                        : `${
                                            stock[invent?._id]
                                          } items available`
                                    }`
                                : loading && i == indexx
                                ? "wait..."
                                : null}
                            </h4>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination */}
          <div className="pagination">
            <a href="javascript:void(0);" onClick={prePage}>
              «
            </a>
            {numbers.map((n, i) => (
              <a
                href="javascript:void(0);"
                key={i}
                onClick={() => changeCPage(n)}
                className={`${currentPage === n ? "active" : ""}`}
              >
                {n}
              </a>
            ))}

            {/* <a href="javascript:void(0);" className="active">
                    02
                  </a>
                  <a href="javascript:void(0);">03</a>
                  <a href="javascript:void(0);">04</a> */}
            <a href="javascript:void(0);" onClick={nextPage}>
              »
            </a>
          </div>
        </div>
      </div>
      {/* </div>
        </section>
      </main> */}
    </>
  );
};

export default InventorySummery;
