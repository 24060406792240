import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AddToBucket, refillBucket, removeFromBucket, updateBucket, viewBucketItem } from "../../constants/Api/Api";
import { Bounce, toast } from "react-toastify";



export const BucketManegment=createAsyncThunk(
    'BucketManegment', // Action type prefix
    async (arg, thunkAPI) => {
     try {
         const response = await axios.post(`${AddToBucket}`,arg,{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
         })
        //  console.log(response?.data,'check')
        if (response?.data?.success) {
            toast.success(response?.data?.message, {
                className: "toast-message",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // theme: 'dark',
                transition: Bounce,
              });
              // console.log(response?.data,'testing')
              return response?.data?.bucketData
        }else{
            toast.error("Some thing went wrong", {
                className: "toast-message",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // theme: 'dark',
                transition: Bounce,
              });
        }

     } catch (error) {
      // console.log(error)        
      toast.error(error?.response?.data?.message, {
        className: "toast-message",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme: 'dark',
        transition: Bounce,
      });
     }
    }
);

export const updateBucketQun=createAsyncThunk(
  'updateBucketQun', // Action type prefix
  async ({variantId,quantity,bucketid}, thunkAPI) => {
    // console.log(arg,'check')
   try {
  
       const response = await axios.put(`${updateBucket}/${bucketid}`,{variantId,quantity},{
          headers:{Authorization:'Bearer '+localStorage.getItem('token')}
       })
      if (response?.data?.success) {
          toast.success(response?.data?.message, {
              className: "toast-message",
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              // theme: 'dark',
              transition: Bounce,
            });
            return response?.data?.bucketData
      }else{
          toast.error("Some thing wrong", {
              className: "toast-message",
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              // theme: 'dark',
              transition: Bounce,
            });
      }

   } catch (error) {

      console.warn(error);
      
   }
  }
);


export const deletefromBucket=createAsyncThunk(
  'deletefromBucket', // Action type prefix
  async (arg, thunkAPI) => {
    console.log(arg,'checkk')

    // fetch(`${removeFromBucket}/${arg?.vi}`,{
    //   method:'PUT',
    //   headers:{Authorization:'Bearer '+localStorage.getItem('token')}
    // }).then(res=>res.json()).then(res=>{
    //   console.log(res?.bucketData,'chek')
    //   // return res?.bucketData
    // }).catch(err=>{
    //   console.warn(err);
      
    // })

   
   try {
  
       const response = await axios.put(`${removeFromBucket}/${arg?.bucId}`,{variantId:arg?.vi},{
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token"),
        }
       })
      if (response?.data?.success) {
          toast.success(response?.data?.message, {
              className: "toast-message",
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              // theme: 'dark',
              transition: Bounce,
            });
            return response?.data?.bucketData
      }else{
          toast.error("Some thing wrong", {
              className: "toast-message",
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              // theme: 'dark',
              transition: Bounce,
            });
      }

   } catch (error) {
      console.warn(error);      
   }
  }
);



export const viewBucket=createAsyncThunk(
    'viewBucket', // Action type prefix
    async (arg, thunkAPI) => {
      console.log('get')
     try {
    
         const response = await axios.get(`${viewBucketItem}`,{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
         })
         console.log(response?.data,'chek get')
        if (response?.data?.success) {
            toast.success(response?.data?.message, {
                className: "toast-message",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // theme: 'dark',
                transition: Bounce,
              });
              // console.log(response.data,'chek')
              return response?.data?.bucketData
        }else{
            toast.error("Some thing wrong", {
                className: "toast-message",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // theme: 'dark',
                transition: Bounce,
              });
        }

     } catch (error) {
        console.warn(error);
        
     }
    }
);


export const fillBucketbyHoldBucket=createAsyncThunk(
  'fillBucketbyHoldBucket', // Action type prefix
  async (arg, thunkAPI) => {
    console.log('get')
   try {
  
       const response = await axios.get(`${refillBucket}/${arg}`,{
          headers:{Authorization:'Bearer '+localStorage.getItem('token')}
       })
      //  console.log(response?.data,'chek get')
      if (response?.data?.success) {
          toast.success(response?.data?.message, {
              className: "toast-message",
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              // theme: 'dark',
              transition: Bounce,
            });
            // console.log(response.data,'check')
            return response?.data?.bucketData
      }else{
          toast.error("Some thing wrong", {
              className: "toast-message",
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              // theme: 'dark',
              transition: Bounce,
            });
      }

   } catch (error) {
      console.warn(error);
      
   }
  }
);



const BucketSlice=createSlice({
    name:'aboutBucket',
    initialState:{
        loading:false,
        BucketItems:[],
        totalPrice:0,
        bucketId:''

    },
    reducers:{makeEmptyBucket:(st,ac)=>{
      return st={
        loading:false,
        BucketItems:[],
        totalPrice:0,
         bucketId:'',
    }
    },
  fillBucket:(st,ac)=>{
    console.log(ac.payload)
  }
  }
    ,
    extraReducers: (builder) => {
        builder
          .addCase(BucketManegment.pending, (state) => {
            state.loading = true
          })
          .addCase(BucketManegment.fulfilled, (state, action) => {

            // console.log(action.payload,'chek')
            if (action?.payload) {
              // console.log(action?.payload,'check')
              state.totalPrice=action?.payload?.bucketTotal;
              state.BucketItems=action.payload?.bucketProducts;
              state.bucketId=action?.payload?.bucketId;
                          }
            state.loading = false;
          })
          .addCase(BucketManegment.rejected, (state, action) => {
            state.loading = false;           
          });

          builder
          .addCase(viewBucket.pending, (state) => {
            state.loading = true
          })
          .addCase(viewBucket.fulfilled, (state, action) => {
            // console.log(action.payload,'check')
            state.totalPrice=action?.payload?.bucketTotal;
            state.BucketItems=action.payload?.bucketProducts
            state.bucketId=action?.payload?.bucketId
            state.loading = false;
           
          })
          .addCase(viewBucket.rejected, (state, action) => {
            state.loading = false;     
          });
        

          builder
          .addCase(updateBucketQun.pending, (state) => {
            state.loading = true
          })
          .addCase(updateBucketQun.fulfilled, (state, action) => {
            state.totalPrice=action?.payload?.bucketTotal;
            state.BucketItems=action.payload?.bucketProducts
            state.loading = false;
           
          })
          .addCase(updateBucketQun.rejected, (state, action) => {
            state.loading = false;           
          });

          builder
          .addCase(deletefromBucket.pending, (state) => {
            state.loading = true
          })
          .addCase(deletefromBucket.fulfilled, (state, action) => {
            if (action.payload) {              
              state.totalPrice=action?.payload?.bucketTotal;
              state.BucketItems=action.payload?.bucketProducts
              state.loading = false;
            }
           
          })
          .addCase(deletefromBucket.rejected, (state, action) => {
            state.loading = false;           
          });


             builder
          .addCase(fillBucketbyHoldBucket.pending, (state) => {
            state.loading = true
          })
          .addCase(fillBucketbyHoldBucket.fulfilled, (state, action) => {
            // console.log(action.payload,'check')
            state.totalPrice=action?.payload?.bucketTotal;
            state.BucketItems=action.payload?.bucketProducts
            state.bucketId=action?.payload?.bucketId
            state.loading = false;
           
          })
          .addCase(fillBucketbyHoldBucket.rejected, (state, action) => {
            state.loading = false;     
          });


      }
    
})

const BucketAction=BucketSlice.actions;
export { BucketAction }
export default BucketSlice.reducer



