import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faEye,
  faMagnifyingGlass,
  faPlus,
  faStar,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ordr_img1 from "../../../assets/images/ordr_img1.png";

import { Bounce, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/Sidebar";
import {
  logout,
  sellerProduct,
} from "../../../redux/features/sellers/sellerLoginSlice";
import { allProducts } from "../../../redux/features/sellers/sellerProductSlice";
import axios from "axios";
import NavBar from "../../common/Nav/NavBar";
import { addSupplierInfo } from "../../../redux/features/sellers/sellerPurchaseSlice";
import { useFormik } from "formik";
import * as rules from "yup";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const AddSupplier = () => {
  const [value, setValue] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [supplierInfo, setSupplierInfo] = useState({
  //   name: "",
  //   email: "",
  //   countryCode: 0,
  //   phoneNumber: 0,
  //   type: "",
  //   street: "",
  //   city: "",
  //   state: "",
  //   zipCode: "",
  //   country: "",
  //   isActive: false,
  // });

  // const changeHandler = (e) => {
  //   if (e.target.type === "text") {
  //     setSupplierInfo({ ...supplierInfo, [e.target.name]: e.target.value });
  //   } else if (e.target.type === "email") {
  //     setSupplierInfo({ ...supplierInfo, [e.target.name]: e.target.value });
  //   } else if (e.target.type === "number") {
  //     setSupplierInfo({ ...supplierInfo, [e.target.name]: e.target.value });
  //   } else if (e.target.type === "checkbox") {
  //     setSupplierInfo({ ...supplierInfo, isActive: !supplierInfo.isActive });
  //   }
  // };

  // const addSupplier = (e) => {
  //   e.preventDefault();
  //   // console.log(supplierInfo, "supplierInfo");
  //   dispatch(addSupplierInfo({ supplierInfo, navigate }));
  //   // navigate("/purchase/supplier-list");
  // };

  const validationSchema = rules.object().shape({
    name: rules
      .string()
      .required("Please type Supplier name")
      .min(3, "3 charecters needed")
      .matches(/^[A-Za-z\s]+$/, "Name must only contain alphabetic characters"),
    email: rules
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    countryCode: rules.string().required("Please type Supplier name"),
    phoneNumber: rules
      .string()
      .matches(
        /^(?:\(\d{3}\)\s?|\d{3}[-.\s]?)(\d{3})[-.\s]?(\d{4})$/,
        "Invalid phone number format"
      )
      .required("Phone number is required"),
    typee: rules.string().required("Please type Address type ."),
    street: rules.string().required("Please type the name of street"),
    city: rules.string().required("Please type the name of city"),
    state: rules.string().required("Please type the name of state"),
    country: rules.string().required("Please type the name of country"),
    zipCode: rules
      .string()
      .matches(/^\d{6}$/, "Zip code must be exactly 6 digits")
      .required("Zip code is required"),
    isActive: rules.boolean(),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      countryCode: "",
      phoneNumber: "",
      typee: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      isActive: false,
    },
    validationSchema,
    onSubmit: async (val) => {
      console.log(val, "supp");
      dispatch(addSupplierInfo({ supplierInfo: val, navigate }));
    },
  });
  console.log(value, "abc");
  return (
    <>
      {/* <main>
        <section className="total_parent_element">
          <div className="left_parent_element">
            <div className="total_upper_left">
              <div className="logo_area">
                <img
                  style={{ width: "82%", height: "50px", margin: "auto" }}
                  src={require("../../../assets/images/bank.png")}
                />
              </div>
              <div className="nav_area">
                <div className="outr_dashboard_nav">
                  <h4>Dashboards</h4>
                  <div className="parent_nav_menu">
                    <Sidebar />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="right_parent_element">
            <NavBar /> */}

      <div className="outr-right-content">
        <div className="outr-right-content splr_frm_cntnt">
          <div className="splr_frm_ttl">
            <div className="splr_frm_main">
              <form onSubmit={handleSubmit}>
                <div className="splr_frm_flx">
                  <div className="form-group">
                    {/* <PhoneInput
                            // defaultCountry="IN"
                            value={value}
                            onChange={setValue}
                          />
                          {value} */}
                    <br />
                    <label>Supplier Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.name && touched.name ? errors.name : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Email Id</label>
                    <input
                      // type="email"
                      name="email"
                      className="form-control"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.email && touched.email ? errors.email : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Country Code</label>
                    <input
                      // type="number"
                      name="countryCode"
                      className="form-control"
                      value={values.countryCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.countryCode && touched.countryCode
                        ? errors.countryCode
                        : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="number"
                      name="phoneNumber"
                      className="form-control"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.phoneNumber && touched.phoneNumber
                        ? errors.phoneNumber
                        : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Address Type</label>
                    <input
                      type="text"
                      name="typee"
                      className="form-control"
                      value={values.typee}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.typee && touched.typee ? errors.typee : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Street</label>
                    <input
                      type="text"
                      name="street"
                      className="form-control"
                      value={values.street}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.street && touched.street ? errors.street : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.city && touched.city ? errors.city : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      name="state"
                      className="form-control"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.state && touched.state ? errors.state : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>ZipCode</label>
                    <input
                      type="text"
                      name="zipCode"
                      className="form-control"
                      value={values.zipCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.zipCode && touched.zipCode
                        ? errors.zipCode
                        : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      type="text"
                      name="country"
                      className="form-control"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.country && touched.country
                        ? errors.country
                        : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Status</label>
                    <div className="sppl_tgls">
                      <div className="sppl_mn_tgl">
                        <span>Inactive</span>
                        <label className="switch">
                          <input
                            name="isActive"
                            type="checkbox"
                            value={values.isActive}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div className="sppl_mn_tgl">
                        <span>Active</span>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  href="javascript:void(0);"
                  className="btn_add_supplier"
                  //   onClick={() => navigate("/purchase/add-supplier")}
                  //   onClick={addSupplier}
                >
                  Add Supplier
                </button>
              </form>

              {/* <form onSubmit={(e) => addSupplier(e)}>
                      <div className="splr_frm_flx">
                        <div className="form-group">
                          <label>Supplier Name</label>
                          <input
                            required
                            type="text"
                            name="name"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="form-group">
                          <label>Email Id</label>
                          <input
                            required
                            type="email"
                            name="email"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="form-group">
                          <label>Country Code</label>
                          <input
                            type="number"
                            name="countryCode"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input
                            type="number"
                            name="phoneNumber"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="form-group">
                          <label>Address Type</label>
                          <input
                            type="text"
                            name="type"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="form-group">
                          <label>Street</label>
                          <input
                            type="text"
                            name="street"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="form-group">
                          <label>State</label>
                          <input
                            type="text"
                            name="state"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="form-group">
                          <label>ZipCode</label>
                          <input
                            type="text"
                            name="zipCode"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="form-group">
                          <label>Country</label>
                          <input
                            type="text"
                            name="country"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div>
                      
                        <div className="form-group">
                          <label>Status</label>
                          <div className="sppl_tgls">
                            <div className="sppl_mn_tgl">
                              <span>Inactive</span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={changeHandler}
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                            <div className="sppl_mn_tgl">
                              <span>Active</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        href="javascript:void(0);"
                        className="btn_add_supplier"
                        //   onClick={() => navigate("/purchase/add-supplier")}
                        //   onClick={addSupplier}
                      >
                        Add Supplier
                      </button>
                    </form> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div>
        </section>
      </main> */}
    </>
  );
};

export default AddSupplier;
