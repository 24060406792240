import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { addSellerApi, adminLoginApi } from "../../constants/Api/Api";
import { Bounce, toast } from "react-toastify";


 export const LoninAsAAdmin=createAsyncThunk('LoninAsAAdmin',async(data,thunkAPI)=>{
    try {
        // console.log(data)
        const res= await axios.post(adminLoginApi,data?.val);
        //   console.log(res?.data);
        localStorage.setItem('token',res?.data?.token);
        localStorage.setItem('user','admin');

        
    swal("Done!",res?.data.message, "success");
 

      data?.navigate('/adminUI')



    } catch (error) {
        console.warn(error);
        toast.error(error.response.data.message, {
            className: "toast-message",
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Bounce
          });        
    }
 })


 export const AddNewSeller=createAsyncThunk('LoninAsAAdmin',async(data,thunkAPI)=>{
   try {

    // console.log(data)
    const res=await  axios.post(addSellerApi,{...data?.val},{
        headers:{Authorization:'Bearer '+localStorage.getItem('token')}
    })

    if (res?.data?.success) {
        swal("Done!",res?.data.message, "success");
        return res?.data    
        }
    
   } catch (error) {
    console.warn(error);
    toast.error(error.response.data.message, {
        className: "toast-message",
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Bounce
      });  
      return thunkAPI.rejectWithValue(error.response.data); 
   }
 })


export const AboutAdminSlice=createSlice({
    name:"AboutAdmin",
    initialState:{
        loading:false,
        data:''
    },
    extraReducers: (builder) => {
        builder
          .addCase(AddNewSeller.pending, (state) => {
            state.loading=true
          })
          .addCase(AddNewSeller.fulfilled, (state, action) => {
            state.loading=false
    //   console.log(action?.payload)            
          })
          .addCase(AddNewSeller.rejected, (state, action) => {
            state.loading=false
    //   console.log(action?.payload)             
          });
      }
 })