import { createSlice } from '@reduxjs/toolkit'
import ToastAlert from '../../components/ToastAlert';


const isDublicate=(s,a)=>{
    let res=-2;
    if (s.length>0) {
        res=s.findIndex(r=>r.sku==a.sku)
    }
    return res
}


const BucketMenegeSlice = createSlice({
    name:'BucketMenegeSlice',
    initialState:[],
    reducers:{
        addNewProductToBucket:(st,ac)=>{
            // let forUPdatingState=st
           const flag= isDublicate(st,ac?.payload)
           if (flag<0) {
            return [...st,{...ac.payload,qn:1}]
           }else{
            if (ac?.payload?.currentStock<=st[flag].qn) {
                ToastAlert('error','Stock Finished')
            }else{
                if (st[flag].qn>=10) {
                    ToastAlert('error','you can take maximum 10 items at once ')
                }else{                    
                    st[flag].qn=st[flag].qn+1;
                    return st 
                }
        }
           }
        },
        updateQuentityofBucket:(st,ac)=>{
           const flag= isDublicate(st,ac?.payload?.obj)
            st[flag].qn=ac?.payload?.num;
            return st
        },
        deleteProductFromBucket:(st,ac)=>{
           const flag= isDublicate(st,ac?.payload)
            st.splice(flag,1);
            return st
        },
        makeBucketEmpty:(st,ac)=>{
            return []
        },
        fillBucketBymanypro:(st,ac)=>{
            return [...ac?.payload]
        }
    }
})


const BucketMenegeAction=BucketMenegeSlice.actions;
export {BucketMenegeAction,BucketMenegeSlice,isDublicate}