import { faBell, faStar, faUser } from "@fortawesome/free-regular-svg-icons";
import { faGear, faHeadphones } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forStoringInRadis } from "../../constants/Api/Api";
import { HoldBucketMenegAction } from "../../redux/posSlice/HoldBucketMeneg";
import { SelectedCustomerAction } from "../../redux/posSlice/SelectedCustomer";
import { BucketMenegeAction } from "../../redux/posSlice/BucketMenegeSlice";

const PosTopBar = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const holdBill = useSelector((a) => a.HoldBilling);
  const {
    firstName,
    lastName,
    phone,
    userId: IdofUser,
  } = useSelector((res) => res.TheCustomer);
  const totalBucket = useSelector((a) => a.BucketControl);

  return (
    <>
      <div className="outr_parent_nav">
        <div className="lft_p_nv_ottr">
          <div className="tg_br">
            <img
              src={require("../../assets/images-pos/mnu_icn.png")}
              alt="img"
            />
          </div>
          <div className="top_nav_r">
            <div className="nav_top cmn_hdr">
              <h2>POS System</h2>
              <p>Simplify Your Sale</p>
            </div>
            <div className="tp_u">
              <div className="need_sup">
                <a href="javascript:void(0);" className="cmn_btn">
                  <span>
                    {/* <i className="fa-solid fa-headphones"></i> */}
                    <FontAwesomeIcon icon={faHeadphones} size="2xl" />
                  </span>{" "}
                  Need Support?
                </a>
              </div>
              <span className="bell">
                {/* <i className="fa-solid fa-bell"></i> */}
                <FontAwesomeIcon icon={faBell} size="2xl" />
              </span>
              <div className="tp_r">
                <div className="u___details">
                  <span
                    className="tp_uu"
                    onClick={async () => {
                      const flag = window.confirm("are you sure for logout?");
                      if (flag) {
                        if (firstName && totalBucket.length >= 1) {
                          // dispatch(
                          //   HoldBucketMenegAction.addtoHoldBucket({
                          //     firstName,
                          //     lastName,
                          //     phone,
                          //     IdofUser,
                          //     bucketProduct: totalBucket,
                          //   })
                          // );
                          dispatch(HoldBucketMenegAction.makeEmptyHOldBill());

                          const dataToSend = {
                            bucketData: JSON.parse(
                              JSON.stringify([
                                ...holdBill,
                                {
                                  firstName,
                                  lastName,
                                  phone,
                                  IdofUser,
                                  bucketProduct: totalBucket,
                                },
                              ])
                            ),
                            token: localStorage.getItem("token"),
                          };
                          const blob = new Blob([JSON.stringify(dataToSend)], {
                            type: "application/json",
                          });
                          // const url = `${BASE_URL}/api/order/testPostAPI`;
                          navigator.sendBeacon(forStoringInRadis, blob);
                          event.returnValue = "";
                        } else {
                          dispatch(HoldBucketMenegAction.makeEmptyHOldBill());

                          // localStorage.setItem("hh", JSON.stringify(holdBill));
                          const dataToSend = {
                            bucketData: JSON.parse(JSON.stringify(holdBill)),
                            token: localStorage.getItem("token"),
                          };
                          const blob2 = new Blob([JSON.stringify(dataToSend)], {
                            type: "application/json",
                          });
                          // const url = `${BASE_URL}/api/order/testPostAPI`;
                          navigator.sendBeacon(forStoringInRadis, blob2);
                          event.returnValue = "";
                        }

                        dispatch(SelectedCustomerAction.deleteCustomer());
                        dispatch(BucketMenegeAction.makeBucketEmpty());

                        localStorage.clear();
                        nav("/");
                      }
                    }}
                  >
                    {/* <i className="fa-solid fa-user"></i> */}
                    <FontAwesomeIcon icon={faUser} size="2xl" />
                  </span>
                  <div className="cmn_hdr">
                    <h3>David Paul</h3>
                    <p>Admin</p>
                  </div>
                  <div>
                    <img src={require("../../assets/images-pos/doen_i.png")} />
                  </div>
                </div>
                <div className="on_c_drop">
                  <ul>
                    <li>
                      <span>
                        <FontAwesomeIcon icon={faGear} size="2xl" />
                      </span>
                      Settings
                    </li>
                    <div>
                      <a href="javascript:void(0);" className="cmn_btn">
                        Logout
                      </a>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PosTopBar;
