import { Password } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";



const EmailPassWord=createSlice({
    name:'emailPassWord',
    initialState:{email:'',Password:''},
    reducers:{
        setEmailPassWord:(st,ac)=>{
            console.log(st,ac.payload)
        }
    }
})

const EmailPassWordAction=EmailPassWord.actions
export {EmailPassWord,EmailPassWordAction};