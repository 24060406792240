import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faEye,
  faMagnifyingGlass,
  faPlus,
  faStar,
  faTrash,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ordr_img1 from "../../../assets/images/ordr_img1.png";

import { Bounce, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/Sidebar";
import {
  logout,
  sellerProduct,
} from "../../../redux/features/sellers/sellerLoginSlice";
import {
  allProducts,
  allVariants,
} from "../../../redux/features/sellers/sellerProductSlice";
import axios from "axios";
import NavBar from "../../common/Nav/NavBar";
import {
  addSupplierInfo,
  allSuppliers,
  createPurchaseOrder,
} from "../../../redux/features/sellers/sellerPurchaseSlice";
import {
  addWarehouse,
  allWarehouse,
} from "../../../redux/features/sellers/sellerWarehouseSlice";
import {
  BASE_URL,
  Seller_products,
  showpoNumber,
} from "../../../constants/Api/Api";
import { VerticalAlignBottom } from "@mui/icons-material";
import uniqid from "uniqid";
import Select from "react-select";
import { cls } from "react-image-crop";

const PurchaseOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  console.log(selectedOption, "selectedOption");
  const [orderDetail, setOrderDetail] = useState({
    supplierId: "",
    warehouseId: "",
    purchaseOrderNumber: "",
    dateOfPurchaseOrder: "",
    expectedDeliveryDate: "",
    paymentTerm: "",
    paymentType: "",
    products: [],
  });

  const [supplierData, setSupplierData] = useState();
  const [warehouseData, setWarehouseData] = useState();
  const [variantPrice, setVariantPrice] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  // const [selectedValue, setSelectedValue] = useState();

  const { suppliers } = useSelector((state) => state.sellerPurchase);
  const { warehouseDetail } = useSelector((state) => state.sellerWarehouse);
  console.log(warehouseDetail);
  const { products, allVariant } = useSelector((state) => state.sellerProducts);

  const [tableRow, setTableRow] = useState([
    {
      id: uniqid(),
      products: [],
      productId: "",
      variants: [],
      variantId: "",
      quantity: 1,
      rate: 0.0,
      amount: 0,
    },
  ]);
  const finalAmmount = tableRow?.reduce((val, each) => {
    // let df = Number(each.quantity) * Number(each.rate);
    // val += Number(df);
    return (val += each.quantity * each.rate);
  }, 0);
  console.log(tableRow, "tableRow");

  const [poNum, setPoNum] = useState("");

  const addedRow = () => {
    console.log(tableRow, "test");

    setTableRow([
      ...tableRow,
      {
        id: uniqid(),
        products: tableRow[0].products,
        productId: "",
        variants: [],
        variantId: "",
        quantity: 1,
        rate: 0.0,
        amount: 0.0,
      },
    ]);
  };

  useEffect(() => {
    dispatch(allSuppliers());
  }, []);

  useEffect(() => {
    dispatch(allWarehouse());
  }, []);

  useEffect(() => {
    dispatch(allProducts()).then((res) => {
      console.log(res, "test");
      setTableRow((prevData) => {
        const tableInfo = [...prevData];
        tableInfo[0].products = res.payload?.map(
          (product) => product.productInfo.name
        );

        return tableInfo;
      });
    });
  }, []);

  useEffect(() => {
    axios
      // .get("https://api.perfectcreate.com/api/po/showPOnumber", {
      .get(`${showpoNumber}`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        //console.log(res, "responseForPoNum");
        setPoNum(res.data.data);
      })
      .catch((error) => {
        //console.log(error, "error");
      });
  }, []);

  const supplierHandler = (e) => {
    // console.log(e.target.value, "valueSupplier");
    const obj = suppliers.find((supplier) => supplier.name === e.target.value);
    // console.log(obj, "obj111");
    setOrderDetail({ ...orderDetail, supplierId: obj?._id });
    setSupplierData(obj);
  };

  const deliveryHandler = (e) => {
    // console.log(warehouseDetail[0], "999");
    const obj = warehouseDetail.find(
      (warehouse) => warehouse.warehouseName === e.target.value
    );
    // console.log(obj, "obj222");
    setOrderDetail({ ...orderDetail, warehouseId: obj?._id });
    setWarehouseData(obj);
  };

  const changeInputHandler = (e) => {
    if (e.target.name === "purchase_order_number") {
      setOrderDetail({ ...orderDetail, purchaseOrderNumber: e.target.value });
    } else if (e.target.name === "purchase_order_date") {
      setOrderDetail({ ...orderDetail, dateOfPurchaseOrder: e.target.value });
    } else if (e.target.name === "expected_delivery_date") {
      setOrderDetail({ ...orderDetail, expectedDeliveryDate: e.target.value });
    } else if (e.target.name === "paymentTerm") {
      setOrderDetail({ ...orderDetail, paymentTerm: e.target.value });
    } else if (e.target.name === "paymentType") {
      setOrderDetail({ ...orderDetail, paymentType: e.target.value });
    }
  };

  const handleSelectChange = (e, ind) => {
    // console.log(e, "value9852632");
    const targetProd = products.find(
      (product) => product.productInfo.name === e.value
    );

    //FOR UPDATING PRODUCT ID IN SATE
    setTableRow((prevData) => {
      const tableInfo = [...prevData];
      tableInfo[ind].productId = targetProd?._id;
      tableInfo[ind].variantId = "";
      tableInfo[ind].rate = 0;

      return tableInfo;
    });
    dispatch(allVariants(targetProd._id)).then((res) => {
      setTableRow((prev) => {
        const tableInfo = [...prev];
        tableInfo[ind].variants = res.payload?.map((variant) => ({
          ...variant.variant,
          variantID: variant?._id,
        }));

        console.log(tableInfo, "aaa");
        return [...tableInfo];
      });
    });
  };
  console.log(tableRow, "aaa");

  const selectHandler = (e, ind) => {
    // console.log(tableRow, "test");
    // console.log(e.target.value, "selectname");
    // if (e.target.name === "product") {
    // console.log("called product");
    // setSelectedValue(e.target.value);

    // const targetProd = products.find(
    //   (product) => product.productInfo.name === e.value
    // );

    // //FOR UPDATING PRODUCT ID IN SATE
    // setTableRow((prevData) => {
    //   const tableInfo = [...prevData];
    //   tableInfo[ind].productId = targetProd?._id;
    //   return tableInfo;
    // });
    // dispatch(allVariants(targetProd._id)).then((res) => {
    //   console.log(res, "resVar");
    //   setTableRow((prev) => {
    //     const tableInfo = [...prev];
    //     tableInfo[ind].variants = res.payload?.map(
    //       (variant) => variant.variant
    //     );
    //     return tableInfo;
    //   });
    // });
    // }
    // console.log(allVariant, "iddd");
    // console.log(e, "test");
    if (e.target.name === "variant") {
      //console.log("called variant");
      //console.log(e.target.value, "variantValue");
      // setVariantPrice(e.target.value);
      const targetVarObj = allVariant.find(
        (variant) =>
          // console.log(variant, "variant")
          variant?.variant?.sku === e.target.value
      );
      // console.log(tableRow, "test");
      // console.log(targetVarObj, "test");
      // const isAlreadyhas = tableRow?.findIndex(
      //   (v) => v?.variantId == targetVarObj?._id
      // );
      // if (isAlreadyhas !== -1) {
      //   alert("This item is already selected");

      // } else {
      // }
      setTableRow((prevData) => {
        const tableInfo = [...prevData];
        // console.log(tableInfo[ind], "test");
        tableInfo[ind].variantId = targetVarObj?._id;
        tableInfo[ind].rate = targetVarObj?.variant.Price;
        tableInfo[ind].amount = targetVarObj?.variant.Price * 1;
        // console.log(tableInfo, "test");
        return tableInfo;
      });
    }
  };

  const quantityHandler = (e, ind) => {
    setTableRow((prevData) => {
      const tableInfo = [...prevData];
      tableInfo[ind].quantity = Math.floor(e.target.value);
      tableInfo[ind].amount = tableInfo[ind].rate * e.target.value;
      return tableInfo;
    });

    setTimeout(() => {
      let result = 0;
      for (let elem of tableRow) {
        result += elem.amount;
      }
      setTotalAmount(result.toFixed(2));
    }, 100);
  };

  const deleteProduct = (e, index) => {
    if (tableRow.length > 1) {
      const arr = tableRow.filter((_, i) => i !== index);
      //console.log(arr, "deleteAfterArr");
      setTableRow((prevRows) => prevRows.filter((_, i) => i !== index));

      let result = 0;
      for (let elem of arr) {
        result += elem.amount;
      }
      setTotalAmount(result);
    }
  };

  const filterOption = ({ label, value }, string) => {
    if (string === "") return true;
    const parsedString = string.split(/[, ]+/);
    for (const string of parsedString) {
      // Need to check of string is not empty after the split
      if (
        string !== "" &&
        (label.toLowerCase().includes(string.toLowerCase()) ||
          value.includes(string))
      )
        return true;
    }

    return false;
  };

  const submitOrder = (e) => {
    e.preventDefault();

    const newArr = tableRow.map((elem) => {
      return {
        productId: elem.productId,
        variantId: elem.variantId,
        quantity: elem.quantity,
        unitCost: elem.rate,
        totalCost: elem.amount,
      };
    });
    const newObj = {
      supplierId: orderDetail.supplierId,
      warehouseId: orderDetail.warehouseId,
      purchaseOrderNumber: poNum,
      dateOfPurchaseOrder: orderDetail.dateOfPurchaseOrder,
      expectedDeliveryDate: orderDetail.expectedDeliveryDate,
      paymentTerm: orderDetail.paymentTerm,
      paymentType: orderDetail.paymentType,
      products: newArr,
      totalAmount: finalAmmount,
    };

    // console.log(newObj, "abc");
    dispatch(createPurchaseOrder({ newObj, navigate })).then((res) => {
      console.log(res, "res");
    });
  };

  const updateTotalAmmount = () => {};

  return (
    <>
      {/* <main>
        <section className="total_parent_element">
          <div className="left_parent_element">
            <div className="total_upper_left">
              <div className="logo_area">
                <img
                  style={{ width: "82%", height: "50px", margin: "auto" }}
                  src={require("../../../assets/images/bank.png")}
                />
              </div>
              <div className="nav_area">
                <div className="outr_dashboard_nav">
                  <h4>Dashboards</h4>
                  <div className="parent_nav_menu">
                    <Sidebar />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="right_parent_element">
            <NavBar /> */}

      <div className="outr-right-content splr_frm_cntnt nw_prchs_ordr">
        <form onSubmit={(e) => submitOrder(e)} name="ff">
          <div className="splr_frm_ttl">
            <div className="splr_frm_main">
              <div className="splr_frm_flx">
                <div className="form-group">
                  <label>Supplier Name</label>
                  <select
                    className="form-control"
                    onChange={(e) => supplierHandler(e)}
                  >
                    <option>Select supplier name</option>
                    {suppliers &&
                      suppliers.map((supplier, i) => {
                        // console.log(supplier, "supp", i);
                        if (supplier?.status?.isActive) {
                          return (
                            <option key={i} value={supplier?.name}>
                              {supplier?.name}
                            </option>
                          );
                        }
                      })}
                  </select>
                  <div className="bnds_adrs">
                    {supplierData && (
                      <div className="adrs_dtls">
                        <h6>
                          <b>SUPPLIER ADDRESS</b>
                        </h6>
                        <p>
                          {supplierData?.address?.length > 0 &&
                            supplierData?.address[0]?.street}
                          <br />
                          {supplierData?.address?.length > 0 &&
                            supplierData?.address[0]?.city}
                          <br />
                          {supplierData?.address?.length > 0 &&
                            supplierData?.address[0]?.state}
                          <br />
                          {supplierData?.address?.length > 0 &&
                            supplierData?.address[0]?.country}
                          <br />
                          Phone: {supplierData?.phoneNumber}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>Delivery Address</label>
                  <select
                    className="form-control"
                    onChange={(e) => deliveryHandler(e)}
                  >
                    <option value="">Select delivery address</option>
                    {warehouseDetail &&
                      warehouseDetail.map((warehouse, i) => {
                        //console.log(warehouse, "warehouse");
                        return (
                          <option key={i} value={warehouse.warehouseName}>
                            {warehouse.warehouseName}
                          </option>
                        );
                      })}
                  </select>
                  <div className="bnds_adrs">
                    {warehouseData && (
                      <div className="adrs_dtls">
                        <h6>
                          <b>DELIVERY ADDRESS</b>
                        </h6>
                        <p>
                          {warehouseData?.address?.street}
                          <br />
                          {warehouseData?.address?.city}
                          <br />
                          {warehouseData?.address?.state}
                          <br />
                          {warehouseData?.address?.country}
                          <br />
                          Phone: {warehouseData?.phone}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>Purchase Order Number</label>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    name="purchase_order_number"
                    value={poNum}
                    // onChange={(e) => changeInputHandler(e)}
                  />
                </div>
                <div className="fordate">
                  <div className="form-group">
                    <label>Purchase Order Date</label>
                    <input
                      type="date"
                      name="purchase_order_date"
                      className="form-control"
                      onChange={(e) => changeInputHandler(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Expected Delivery Date</label>
                    <input
                      type="date"
                      name="expected_delivery_date"
                      className="form-control"
                      onChange={(e) => changeInputHandler(e)}
                    />
                  </div>
                </div>
                <div className="forpmnt">
                  <div className="form-group">
                    <label>Payment Terms</label>
                    <select
                      name="paymentTerm"
                      className="form-control"
                      onChange={(e) => changeInputHandler(e)}
                    >
                      <option value="">Select payment terms</option>
                      <option value="COD">COD</option>
                      <option value="Net 30">Net 30</option>
                      <option value="Net 45">Net 45</option>
                      <option value="Net 60">Net 60</option>
                      <option value="Net 75">Net 75</option>
                    </select>
                  </div>
                  {/* <div className="form-group">
                          <label>Shipment Preference</label>
                          <input type="text" className="form-control" />
                        </div> */}
                  <div className="form-group">
                    <label>Payment Type</label>
                    <select
                      name="paymentType"
                      className="form-control"
                      onChange={(e) => changeInputHandler(e)}
                    >
                      <option value="">Select payment types</option>
                      <option value="COD">COD</option>
                      <option value="cash">Cash</option>
                      <option value="credit">Credit</option>
                      <option value="debit">Debit</option>
                      <option value="check">Check</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="prchs_itm_tbl show_drop">
              <table>
                <thead>
                  <tr>
                    <th>
                      <b>PRODUCTS</b>
                    </th>
                    <th>
                      <b>QUANTITY</b>
                    </th>
                    <th>
                      <b>RATE</b>
                    </th>
                    <th>
                      <b>AMOUNT</b>
                    </th>
                    <th>
                      <b>ACTION</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableRow &&
                    tableRow.map((data, index) => {
                      //console.log(data, "data111");
                      const productOptions = data?.products?.map((product) => ({
                        value: product,
                        label: product, // Customize the label as needed
                      }));
                      //console.log(productOptions, "productOptions");

                      return (
                        <tr key={data.id}>
                          <td>
                            {/* <select
                                    name="product"
                                    // value={selectedValue}
                                    onChange={(e) => selectHandler(e, index)}
                                  >
                                    <option value="">Select product</option>
                                    {data.products?.map((elem, i) => {
                                      return (
                                        <option key={i} value={elem}>
                                          {elem}
                                        </option>
                                      );
                                    })}
                                  </select> */}
                            <Select
                              required
                              filterOption={filterOption}
                              name="product"
                              onChange={(e) => handleSelectChange(e, index)}
                              options={productOptions}
                            />
                            <select
                              required
                              name="variant"
                              onChange={(e) => selectHandler(e, index)}
                            >
                              <option value="">Select variant</option>
                              {data.variants?.map((elem, i) => {
                                return (
                                  <option
                                    disabled={tableRow
                                      .reduce((acc, val, ind) => {
                                        return [...acc, val?.variantId];
                                      }, [])
                                      ?.find((v, i) => v == elem?.variantID)}
                                    key={elem?.sku}
                                    value={elem?.sku}
                                  >
                                    {`Price: ${elem.Price} Color: ${elem.Color}`}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                          <td>
                            <input
                              type="number"
                              required
                              name="quantity"
                              value={data.quantity}
                              // onWheel={(e) => e.target.blur()}
                              onChange={(e) => quantityHandler(e, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={data.rate}
                              onChange={(e) => {
                                setTableRow((pre) => {
                                  let dub = [...pre].map((vv, ii) => ({
                                    ...vv,
                                  }));
                                  dub[index].rate = e.target.value;
                                  dub[index].amount =
                                    e.target.value * dub[index].quantity;
                                  return dub;
                                });
                              }}
                            />
                          </td>

                          <td>{data.amount}</td>

                          <td>
                            <FontAwesomeIcon
                              icon={faXmark}
                              size="2xl"
                              style={{
                                color: "#e00b0b",
                                cursor: "pointer",
                              }}
                              onClick={(e) => deleteProduct(e, index)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="prchs_rows">
              <div className="prchs_add_btn">
                <button type="button" className="edit" onClick={addedRow}>
                  Add New Row
                </button>
              </div>
              <div className="prchs_ttl">
                <span className="prchs_ttl_left">
                  <b>Total</b>
                </span>
                <span className="prchs_ttl_right">
                  {/* <b>{totalAmount}</b> */}
                  <b>{finalAmmount}</b>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <button type="submit" className="edit">
              Submit
            </button>
          </div>
        </form>
      </div>
      {/* </div>
        </section>
      </main> */}
    </>
  );
};

export default PurchaseOrder;
