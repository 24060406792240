import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as rules from "yup";
import { LoninAsAAdmin } from "../../redux/AdminSlice/AdminSlicer";
import { useNavigate } from "react-router-dom";

const LoginAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = rules.object().shape({
    email: rules
      .string()
      .email("Invalid email format")
      .required("Please type the email"),
    password: rules
      .string()
      .required("Please type the Password")
      .min(4, "Minimum 4 characters are needed."),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (val) => {
      dispatch(LoninAsAAdmin({ val, navigate }));
    },
  });
  return (
    <>
      <section className="sign_up">
        <div className="sign_up_otr">
          <div className="sign_up_innr">
            <div className="sgn_hdr text-center">
              <h3>Log In Admin</h3>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="frm_innr">
                <div className="inpt">
                  <input
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.email && touched.email ? errors.email : null}
                </div>
                <div className="inpt">
                  <input
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.password && touched.password ? errors.password : null}
                </div>

                <div className="">
                  <input type="submit" value={"Submit"} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginAdmin;
