import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import { PosRoute } from "../PosRoute";

const PosSideBar = () => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="lft_prnt_elmnt">
        <div className="total_upper_left">
          <div className="logo_area">
            <a href="jvascript:void(0);">
              <div className="nav_btm_logo">
                <img
                  src={require("../../assets/images-pos/logo.png")}
                  alt="logo"
                />
              </div>
            </a>
          </div>
          <div className="nav_area">
            <div className="outr_dashboard_nav">
              <div className="parent_nav_menu">
                <div className="pnmul_ottr">
                  {/* <button className="cmn_btn">Dashboard</button> */}
                  {/* <a href="javascript:void(0);" className="cmn_btn">
                    Dashboard
                  </a> */}
                  <div className="pnmul_one menu" id="menu">
                    {PosRoute?.length > 0 &&
                      PosRoute.map((v, i) => {
                        return (
                          <>
                            {/* <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                            >
                              <ListItemButton onClick={handleClick}>
                              
                                <ListItemText>
                                  <h6 style={{ width: "100%" }}>
                                    {v?.mainName}
                                  </h6>
                                </ListItemText>
                                {open ? <ExpandLess /> : <ExpandMore />}
                              </ListItemButton>
                              {v?.sub?.length > 0 &&
                                v?.sub?.map((vc, ci) => {
                                  return (
                                    <>
                                      <Collapse
                                        in={open}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <List component="div" disablePadding>
                                          <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                              <StarBorder />
                                            </ListItemIcon>
                                            <ListItemText>
                                              <ul
                                                className="pnmul lists active"
                                                id="ddl"
                                              >
                                                <li className="nav-item">
                                                  <a
                                                    href="add_employee.html"
                                                    className="nav-link"
                                                  >
                                                    <div className="icon_box">
                                                      <img
                                                        src={require(`../../assets/images-pos/${vc?.imgLink}`)}
                                                        alt="img"
                                                      />
                                                    </div>
                                                    <span className="icon_text">
                                                      <NavLink
                                                        style={{
                                                          color: "black",
                                                        }}
                                                        to={vc?.routeLink}
                                                      >
                                                        {vc?.subName}
                                                      </NavLink>
                                                    </span>
                                                  </a>
                                                </li>
                                              </ul>
                                            </ListItemText>
                                          </ListItemButton>
                                        </List>
                                      </Collapse>
                                    </>
                                  );
                                })}
                            </List> */}

                            <Accordion key={i} sx={{ boxShadow: "none" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <h6 style={{ width: "100%" }}>
                                  {v?.mainName}
                                  <span className="onn">
                                    <FontAwesomeIcon
                                      icon={faCaretDown}
                                      size="2xl"
                                    />
                                  </span>{" "}
                                  <span className="nn">
                                    <FontAwesomeIcon
                                      icon={faCaretUp}
                                      size="2xl"
                                    />
                                  </span>
                                </h6>
                              </AccordionSummary>
                              {v?.sub?.length > 0 &&
                                v?.sub?.map((vc, ci) => {
                                  return (
                                    <AccordionDetails key={ci}>
                                      <NavLink
                                        style={{ color: "black" }}
                                        to={vc?.routeLink}
                                      >
                                        <ul
                                          className="pnmul lists active"
                                          id="ddl"
                                        >
                                          <li className="nav-item">
                                            <a
                                              // href="add_employee.html"
                                              className="nav-link"
                                            >
                                              <div className="icon_box">
                                                <img
                                                  src={require(`../../assets/images-pos/${vc?.imgLink}`)}
                                                  alt="img"
                                                />
                                              </div>
                                              <span className="icon_text">
                                                {vc?.subName}
                                              </span>
                                            </a>
                                          </li>
                                        </ul>
                                      </NavLink>
                                    </AccordionDetails>
                                  );
                                })}
                            </Accordion>
                          </>
                        );
                      })}
                  </div>

                  {/* <div className="pnmul_one menu" id="menu">
                    <h6>
                      POS{" "}
                      <span className="onn">
                        <FontAwesomeIcon icon={faCaretDown} size="2xl" />
                      </span>{" "}
                      <span className="nn">
                        <FontAwesomeIcon icon={faCaretUp} size="2xl" />
                      </span>
                    </h6>
                    <ul className="pnmul lists active" id="ddl">
                      <li className="nav-item">
                        <a href="add_employee.html" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_1.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_1.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text">Items</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_2.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_2.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Order </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_3.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_3.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Billing </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_4.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_4.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Reports </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_5.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_5.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Invoice </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_6.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_6.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text">Transactions </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_7.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_7.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Gift Cards </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="inventory.html" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_8.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_8.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Inventory </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="pnmul_one menu" id="menu1">
                    <h6>
                      Settings{" "}
                      <span className="onn">
                        <FontAwesomeIcon icon={faCaretDown} size="2xl" />
                      </span>{" "}
                      <span className="nn">
                        <FontAwesomeIcon icon={faCaretUp} size="2xl" />
                      </span>
                    </h6>
                    <ul className="pnmul lists" id="ddl1">
                      <li className="nav-item">
                        <a href="add_employee.html" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_1.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_1.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text">Items</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_2.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_2.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Order </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_3.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_3.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Billing </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_4.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_4.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Reports </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_5.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_5.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Invoice </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_6.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_6.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text">Transactions </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_7.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_7.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Gift Cards </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="inventory.html" className="nav-link">
                          <div className="icon_box">
                            <img
                              src={require("../../assets/images-pos/left_i_8.png")}
                              alt="img"
                            />
                            <img
                              src={require("../../assets/images-pos/left_ii_8.png")}
                              alt="img"
                            />
                          </div>
                          <span className="icon_text"> Inventory </span>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PosSideBar;
