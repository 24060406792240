export const PosRoute = [
  {
    mainName: "Dashboard",
    // sub: [
    //   {
    //     subName: "Items",
    //     imgLink: "left_i_1.png",
    //     routeLink: "/posContent",
    //   },
    // ],
  },
  ,
  {
    mainName: "POS",
    sub: [
      {
        subName: "Items",
        imgLink: "left_i_1.png",
        routeLink: "/posContent",
      },
      {
        subName: "Check-Out",
        imgLink: "left_i_1.png",
        routeLink: "/posContent/checkOut",
      },
    ],
  },
  {
    mainName: "Testing",
    sub: [
      {
        subName: "one",
        imgLink: "left_i_1.png",
        routeLink: "/posContent/one",
      },
      {
        subName: "two",
        imgLink: "left_i_1.png",
        routeLink: "/posContent/two",
      },
    ],
  },
];
