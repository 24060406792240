import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import NavBar from "./Nav/NavBar";
import PosSideBar from "../../PosComponent/posSidebar/PosSideBar";
import PosTopBar from "../../PosComponent/PosTopBar/PosTopBar";
import SellerSideBar from "./SellerSideBar";
import SellerTopBar from "./SellerTopBar";

const SideAndTopOfSeller = () => {
  return (
    <>
      {/* <main>
          <section
            className="total_parent_element"
            // style={{ background: "#008080" }}
          >
            <div
              className="left_parent_element"
              // style={{ height: "100vh" }}
            >
              <div className="total_upper_left">
                <div className="logo_area">
                  <img
                    style={{ width: "82%", height: "50px", margin: "auto" }}
                    src={require("../../assets/images/bank.png")}
                  />
                </div>
                <div className="nav_area">
                  <div className="outr_dashboard_nav">
                    <div className="parent_nav_menu">
                      <Sidebar />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right_parent_element">
              <NavBar />

              <Outlet />
            </div>
          </section>
        </main> */}
      <main>
        <section className="ttl_prnt_elmnt new_ttl_prnt_elmnt">
          <SellerSideBar />

          <div className="rght_prnt_elmnt">
            <SellerTopBar />

            <div style={{ height: "91px" }}></div>
            <Outlet />
          </div>
        </section>
      </main>
      {/* <Outlet /> */}
    </>
  );
};

export default SideAndTopOfSeller;
