import { Bounce, toast } from "react-toastify";
const ToastAlert = (type, message) => {
  // console.log(type,message);
  const toastOptions = {
    className: "toast-message",
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Bounce,
  };
  switch (type) {
    case "error":
      toast.error(message, toastOptions);
      break;
    case "warn":
      toast.warn(message, toastOptions);
      break;
    default:
      toast.success(message, toastOptions);
      break;
  }
};
export default ToastAlert;
