import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../../common/Sidebar";
import NavBar from "../../common/Nav/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faCloudArrowUp,
  faEye,
  faImage,
  faLayerGroup,
  faMinus,
  faPenToSquare,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allBrand,
  childCatUpdatePage,
  prodCatUpdatePage,
  productCategory,
  productChildCat,
  singleProductDetail,
  updateInfo,
  updatePotency,
} from "../../../redux/features/sellers/sellerProductSlice";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Seller_products } from "../../../constants/Api/Api";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import Variation from "./Variation.js";

const ProductDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [main, setMain] = useState([
    { potency: [{ key: "", value: "" }], val: "" },
  ]);

  // console.log(main, "main555");
  const [inputFields, setInputFields] = useState([{ Price: "" }]);
  console.log(inputFields, "inputFields");
  const [arr, setArr] = useState([]);
  // console.log(arr, "1009");
  const [title, setTitle] = useState(["Price", "Color", "Weight"]);
  const [tabs, setTabs] = useState("product_info");
  const [modal, setModal] = useState(false);
  const [value, setValue] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  console.log(modalHeading, "modalHeading");
  const [createTitleName, setCreateTitleName] = useState("");
  const [showCreateTitle, setShowCreateTitle] = useState(false);
  const [catName, setCatName] = useState("");
  const [subCatName, setSubCatName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditForPotency, setIsEditForPotency] = useState(false);
  const [show, setShow] = useState(false);
  const [imgurl, setImgurl] = useState("");

  // For showing name in dropdown
  const [dropDownData, setDropDownData] = useState({
    categoryName: "",
    subCategoryName: "",
    childCategoryName: "",
    brandName: "",
  });

  const [fileForProductInput, setFileForProductInput] = useState([]);
  console.log(fileForProductInput, "fileForProductInput");
  const [fileForBannerInput, setFileForBannerInput] = useState([]);
  const [fileForProduct, setFileForProduct] = useState([]);
  const [fileForBanner, setFileForBanner] = useState([]);
  const [productPictures, setProductPictures] = useState([]);
  console.log(productPictures, "productPictures");
  const [bannerPictures, setBannerPictures] = useState([]);
  //store index of array -> Arr
  const [indexOfArr, setIndexOfArr] = useState();
  const [variantts, setVariantts] = useState([]);

  //USE UseSelector
  const {
    loading,
    productDetail,
    category,
    subCategory,
    childCategory,
    brand,
    allVariants,
  } = useSelector((state) => state.sellerProducts);

  //State for Product Info
  const [productInfo, setProductInfo] = useState({});

  // console.log(productInfo, "productInfo");

  //FOR dispatch action
  useEffect(() => {
    dispatch(singleProductDetail(id));
    dispatch(productCategory());
    dispatch(allBrand());
  }, []);

  //GET SINGLE PRODUCT DETAILS
  useEffect(() => {
    axios
      .get(`${Seller_products}/${id}`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res?.data, "bbb");
        setVariantts([...res?.data?.data?.variants]);
        //console.log(res.data.data, "detail222");
        //UPDATE 'main' STATE
        setMain(res.data.data.otherDescription);

        //UPDATE 'productInfo' STATE
        //console.log(res.data.data.productInfo, "infodetails");
        setProductInfo(res.data.data.productInfo);

        //FOR inputField
        //console.log(res.data.data.variants[0], "vvvv");
        const variant = res.data.data.variants[0].variant;
        const arrForInputFields = [];
        for (let key in variant) {
          arrForInputFields.push({ [key]: variant[key] });
        }
        arrForInputFields.push({
          id: res.data.data.variants[0]["_id"],
        });
        arrForInputFields.push({
          "Product Image": res.data.data.variants[0]["productPictures"],
        });
        arrForInputFields.push({
          "Banner Image": res.data.data.variants[0]["bannerPictures"],
        });
        // arrForInputFields.push({
        //   id: res.data.data.variants[0]["_id"],
        // });
        //console.log(arrForInputFields, "arrForInputFields");
        setInputFields(arrForInputFields);

        //FOR arr
        const newVariants = res.data.data.variants.slice(1);
        //console.log(newVariants, "newVariants");
        const newArray = newVariants.map((v) => {
          return {
            ...v.variant,
            id: v._id,
            "Product Image": [...v["productPictures"]],
            "Banner Image": [...v["bannerPictures"]],
            // id: v._id,
          };
        });

        setArr(newArray);
      })
      .catch((error) => {
        //console.log(error);
      });
  }, [show]);

  const toggle = (heading, val, arrInd) => {
    setModal(!modal);
    setModalHeading(heading);
    setValue(val);
    // setObj(object);
    if (val === "inputFields") {
      if (heading === "Product Image") {
        setProductPictures(inputFields[arrInd]["Product Image"]);
        setFileForProductInput(inputFields[arrInd]["Product Image"]);
        // setProductPictures(inputViewProdPic);
      } else if (heading === "Banner Image") {
        setBannerPictures(inputFields[arrInd]["Banner Image"]);
        setFileForBannerInput(inputFields[arrInd]["Banner Image"]);
        // setBannerPictures(inputViewBannrPic);
      }
    }

    if (val === "arr") {
      if (heading === "Product Image") {
        setProductPictures(arr[arrInd]["Product Image"]);
        setFileForProduct(arr[arrInd]["Product Image"]);
      } else if (heading === "Banner Image") {
        setBannerPictures(arr[arrInd]["Banner Image"]);
        setFileForBanner(arr[arrInd]["Banner Image"]);
      }
    }
  };

  const addPotency = (passData) => {
    if (passData === "main") {
      setMain((prev) => {
        let duplicateMain = [...prev];
        return [...duplicateMain, { potency: [{ key: "", value: "" }] }];
      });
    } else {
      setMain((prev) => {
        let duplicateChild = [...prev];
        duplicateChild[passData]["potency"].push({ key: "", value: "" });
        return duplicateChild;
      });
    }
  };

  const removePotency = (d, index, childInd) => {
    if (d === "main") {
      if (index > 0) {
        const mainAfterRemove = main.filter((m, i) => i !== index);
        setMain(mainAfterRemove);
      }
    } else {
      if (childInd > 0) {
        //console.log(index, "hjcvghjs");
        const mainAfterRemoveChild = main[index]["potency"].filter(
          (c, i) => i !== childInd
        );
        setMain((prev) => {
          const arr = [...prev];
          arr[index]["potency"] = mainAfterRemoveChild;
          return arr;
        });
      }
    }
  };

  const changeProductInfo = (e) => {
    if (e.target.type === "text") {
      setProductInfo({ ...productInfo, [e.target.name]: e.target.value });
      if (e.target.name === "shippingCharge") {
        setProductInfo({
          ...productInfo,
          shippingDetails: {
            ...productInfo.shippingDetails,
            shippingCharge: e.target.value,
          },
        });
      }
    } else if (e.target.type === "checkbox") {
      if (e.target.name === "status") {
        setProductInfo({ ...productInfo, status: !productInfo?.status });
      }
      if (e.target.name === "freeShipping") {
        setProductInfo({
          ...productInfo,
          shippingDetails: {
            ...productInfo.shippingDetails,
            freeShipping: !productInfo?.shippingDetails?.freeShipping,
          },
        });
      }
    } else {
      if (e.target.name === "category") {
        //console.log(e.target.value, "valueTarget");
        setCatName(e.target.value);
        const catSingleObj = category.find(
          (catObj) => catObj.name === e.target.value
        );
        setProductInfo({
          ...productInfo,
          category: { _id: catSingleObj?._id },
          subCategory: { name: "" },
          childCategory: { name: "" },
        });
        dispatch(prodCatUpdatePage(e.target.value));
      }

      if (e.target.name === "subCategory") {
        setSubCatName(e.target.value);
        //console.log(catName, "catName");

        if (!catName) {
          const catSingleObj = category.find(
            (catObj) => catObj.name === productInfo?.category?.name
          );
          const subCatSingleObj = catSingleObj?.subCategory?.find(
            (subCatObj) => subCatObj.subCategoryName === e.target.value
          );
          setProductInfo({
            ...productInfo,
            subCategory: { _id: subCatSingleObj?._id },
            childCategory: { _id: "" },
          });
        } else {
          const catSingleObj = category.find(
            (catObj) => catObj.name === catName
          );
          const subCatSingleObj = catSingleObj?.subCategory?.find(
            (subCatObj) => subCatObj.subCategoryName === e.target.value
          );
          setProductInfo({
            ...productInfo,
            subCategory: { _id: subCatSingleObj?._id },
            childCategory: { _id: "" },
          });
        }

        dispatch(childCatUpdatePage(e.target.value));
      }

      if (e.target.name === "childCategory") {
        if (!subCatName) {
          const subCatSingleObj = subCategory?.find(
            (subCatObj) =>
              subCatObj.subCategoryName === productInfo?.subCategory?.name
          );
          const childCatSingleObj = subCatSingleObj?.childCategory?.find(
            (childCatObj) => childCatObj.childCategoryName === e.target.value
          );
          setProductInfo({
            ...productInfo,
            childCategory: { _id: childCatSingleObj?._id },
          });
        } else {
          const subCatSingleObj = subCategory?.find(
            (subCatObj) => subCatObj.subCategoryName === subCatName
          );
          const childCatSingleObj = subCatSingleObj?.childCategory?.find(
            (childCatObj) => childCatObj.childCategoryName === e.target.value
          );
          setProductInfo({
            ...productInfo,
            childCategory: { _id: childCatSingleObj?._id },
          });
        }
      }
    }

    if (e.target.name === "brand") {
      const brandSingleObj = brand?.find(
        (brandObj) => brandObj.name === e.target.value
      );
      setProductInfo({ ...productInfo, brand: { _id: brandSingleObj?._id } });
    }
  };

  const submitProductInfo = (e) => {
    e.preventDefault();
    const productInfoUpdate = {
      name: productInfo.name,
      status: productInfo.status,
      category: productInfo.category._id,
      subCategory: !productInfo?.subCategory?._id
        ? ""
        : productInfo.subCategory._id,
      childCategory: !productInfo?.childCategory?._id
        ? ""
        : productInfo?.childCategory?._id,
      brand: productInfo?.brand?._id,
      description: productInfo.description,
      shippingCharge: productInfo.shippingDetails.shippingCharge,
      freeShipping: productInfo.shippingDetails.freeShipping,
    };

    // console.log(productInfoUpdate, "productInfoUpdate");

    dispatch(updateInfo({ productInfoUpdate, id }));
  };

  const headingChange = (e, ind, main_id) => {
    // console.log(main_id, "main_id");
    if (main_id) {
      setMain((prev) => {
        const head = [...prev];
        head[ind]["val"] = e.target.value;
        head[ind]["_id"] = main_id;
        return head;
      });
    } else {
      setMain((prev) => {
        const head = [...prev];
        head[ind]["val"] = e.target.value;
        return head;
      });
    }
  };

  const changeHandler = (e, mainInd, childInd, child_id) => {
    if (child_id) {
      setMain((prev) => {
        const tableInfo = [...prev];
        tableInfo[mainInd]["potency"][childInd][e.target.name] = e.target.value;
        tableInfo[mainInd]["potency"][childInd]["_id"] = child_id;
        return tableInfo;
      });
    } else {
      setMain((prev) => {
        const tableInfo = [...prev];
        tableInfo[mainInd]["potency"][childInd][e.target.name] = e.target.value;
        return tableInfo;
      });
    }
  };

  const savePotencyData = () => {
    // console.log("saved potency");
    // console.log(main, "mainPotency");
    const potencyData = {
      others: main,
      prodId: id,
    };
    // console.log(potencyData, "potencyData");
    dispatch(updatePotency(potencyData)).then((res) => {
      //console.log(res);
      setShow(!show);
    });
  };

  const createTitle = () => {
    setShowCreateTitle(true);
  };

  const addTitle = (e) => {
    e.preventDefault();

    setShowCreateTitle(false);
    if (title.includes(createTitleName)) {
      alert("already exist");
    } else {
      const newArray = [...title];
      const insertIndex = newArray.length - 2;
      newArray.splice(insertIndex, 0, createTitleName);

      setTitle(newArray);
    }
    setCreateTitleName("");
  };

  const getTitle = (titleName) => {
    const result = inputFields
      .map((obj, index) => {
        return Object.keys(obj);
      })
      .flat();

    if (result.includes(titleName)) {
      alert("already exist");
    } else {
      const newModArr = [...inputFields];
      //console.log(newModArr, "newModArr");
      const inInd = newModArr.length - 2;
      newModArr.splice(inInd, 0, { [titleName]: "" });
      setInputFields(newModArr);

      /////////////////////////New Try//////////////////
      const addNew = arr.map((obj) => {
        const entries = Object.entries(obj);
        const getInd = entries.length - 2;
        const x = {
          [titleName]: "",
        };
        // entries.splice(getInd, 0, x);
        entries.splice(getInd, 0, Object.entries(x).flat());

        const newObject = Object.fromEntries(entries);
        return newObject;
      });

      setArr(addNew);
    }
  };

  const addField = () => {
    // inputFields.pop();
    const combinedData = inputFields.reduce(
      (acc, obj) => ({ ...acc, ...obj }),
      {}
    );
    // console.log(combinedData, "combinedData");
    const newObj = {};
    for (let key in combinedData) {
      if (key !== "id") {
        newObj[key] = combinedData[key];
      }
    }
    // console.log(newObj, "newObj");
    // setArr([...arr, combinedData]);
    setArr([...arr, newObj]);
  };

  const chngeFirstIndexData = (e, index) => {
    // console.log(index, "iiiiInput");
    // console.log(e.target.name, "namme");
    inputFields[index][e.target.name] = e.target.value;
  };

  const changeOtherIndexData = (e, index) => {
    // console.log(index, "iiiiARR");
    arr[index][e.target.name] = e.target.value;
  };

  const handleImageChange = (e, modalHeading) => {
    if (value === "inputFields") {
      if (modalHeading === "Product Image") {
        const filesForProductInp = Array.from(e.target.files);
        //console.log(filesForProductInp, "filesForProductInp");
        // setFileForProductInput(filesForProduct);
        // setFileForProductInput([...productPictures, ...fileForProduct]);
        const allFilesProduct = fileForProductInput.concat(filesForProductInp);
        setFileForProductInput(allFilesProduct);
        const newImages = filesForProductInp.map((file) =>
          URL.createObjectURL(file)
        );
        setProductPictures((prevImages) => [...prevImages, ...newImages]);
      } else if (modalHeading === "Banner Image") {
        const filesForBanner = Array.from(e.target.files);
        const allFilesBanner = fileForBannerInput.concat(filesForBanner);
        setFileForBannerInput(allFilesBanner);
        const newImages = filesForBanner.map((file) =>
          URL.createObjectURL(file)
        );
        setBannerPictures((prevImages) => [...prevImages, ...newImages]);
      }
    }

    if (value === "arr") {
      if (modalHeading === "Product Image") {
        const filesForProduct = Array.from(e.target.files);
        //console.log(filesForProduct, "filesForProduct");
        const allFilesProductOther = fileForProduct.concat(filesForProduct);
        setFileForProduct(allFilesProductOther);
        const newImages = filesForProduct.map((file) =>
          URL.createObjectURL(file)
        );
        setProductPictures((prevImages) => [...prevImages, ...newImages]);
      } else if (modalHeading === "Banner Image") {
        const filesForBanner = Array.from(e.target.files);
        const allFilesBannerOther = fileForBanner.concat(filesForBanner);
        setFileForBanner(allFilesBannerOther);
        const newImages = filesForBanner.map((file) =>
          URL.createObjectURL(file)
        );
        setBannerPictures((prevImages) => [...prevImages, ...newImages]);
      }
    }
  };

  const removePoductImage = (index) => {
    const updatedProductImage = productPictures.filter((p, i) => i !== index);
    setProductPictures(updatedProductImage);

    //FOR inputField
    const updatedProductFile = fileForProductInput.filter(
      (f, i) => i !== index
    );
    setFileForProductInput(updatedProductFile);

    //FOR arr
    const updatedFile = fileForProduct.filter((p, i) => i !== index);
    setFileForProduct(updatedFile);
  };

  const removeBannerImage = (index) => {
    const updatedBannerImage = bannerPictures.filter((b, i) => i !== index);
    setBannerPictures(updatedBannerImage);

    //FOR inputField
    const updatedBannerFile = fileForBannerInput.filter((b, i) => i !== index);
    setFileForBannerInput(updatedBannerFile);

    //For arr
    const updatedFile = fileForBanner.filter((b, i) => i !== index);
    setFileForBanner(updatedFile);
  };

  const saveImages = () => {
    // console.log(value, "900");
    if (value === "inputFields") {
      setInputFields((prevField) => {
        const updatedField = [...prevField];
        const indexForProductImage = updatedField.findIndex((obj) =>
          obj.hasOwnProperty("Product Image")
        );
        // console.log(indexForProductImage, "indexForProductImage");
        const indexForBannerImage = updatedField.findIndex((obj) =>
          obj.hasOwnProperty("Banner Image")
        );
        if (modalHeading === "Product Image") {
          if (fileForProductInput.length > 3) {
            alert("Product image not more than 3");
          } else {
            //console.log(fileForProductInput, "fileForProductInput");
            updatedField[indexForProductImage]["Product Image"] =
              fileForProductInput;
            return updatedField;
          }
          return updatedField;
        }
        if (modalHeading === "Banner Image") {
          //console.log("hello world");
          //console.log(fileForBannerInput, "0998");
          //console.log(indexForBannerImage, "indexForBannerImage");
          if (fileForBannerInput.length > 4) {
            alert("Banner image not more than 4");
          } else {
            updatedField[indexForBannerImage]["Banner Image"] =
              fileForBannerInput;
            return updatedField;
          }
          return updatedField;
        }
      });
    }

    if (value === "arr") {
      const otherFields = arr.map((field, index) => {
        // console.log(indexOfArr, index, "indexOfArr");
        if (index === indexOfArr && modalHeading === "Product Image") {
          if (fileForProduct.length > 3) {
            alert("Product image not more than 3");
          } else {
            return {
              ...field,
              "Product Image": fileForProduct,
              // "Banner Image": fileForBanner,
            };
          }
        }
        if (index === indexOfArr && modalHeading === "Banner Image") {
          if (fileForBanner.length > 4) {
            alert("Banner image not more than 4");
          } else {
            return {
              ...field,
              // "Product Image": fileForProduct,
              "Banner Image": fileForBanner,
            };
          }
        }
        return field;
      });

      setArr(otherFields);
    }

    toggle();
    setProductPictures([]);
    setBannerPictures([]);
    setFileForProductInput([]);
    setFileForBannerInput([]);
    setFileForProduct([]);
    setFileForBanner([]);
  };

  const modalCancel = () => {
    toggle();
  };

  const submitPriceVariant = (e) => {
    e.preventDefault();
    setIsEdit(true);
    const ArrayOfdata = [
      inputFields.reduce((acc, obj) => ({ ...acc, ...obj }), {}),
    ].concat(arr);
    // console.log(ArrayOfdata, "ArrayOfdata");

    ArrayOfdata.forEach((dataObj) => {
      // const formData = dataObj;
      // dispatch(priceVarInfo({ formData, productId }));
    });
  };

  return (
    <>
      <>
        <Modal
          className="prdct_mdl"
          isOpen={modal}
          toggle={toggle}
          centered
          fade
          size="lg"
          backdrop
        >
          <ModalHeader toggle={toggle}>
            {`${
              modalHeading === "Product Image"
                ? " Upload Product Images (Max 3)"
                : "Upload Banner Images (Max 4)"
            }`}
          </ModalHeader>
          <ModalBody>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {modalHeading === "Product Image" &&
                productPictures.length > 0 &&
                productPictures.map((image, index) => {
                  return (
                    <div className="projImg" key={index}>
                      <img
                        src={image}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          margin: "10px",
                        }}
                        alt=""
                      />
                      {isEdit && (
                        <button
                          className="times"
                          onClick={() => removePoductImage(index)}
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            background: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      )}
                    </div>
                  );
                })}
              {modalHeading === "Banner Image" &&
                bannerPictures.length > 0 &&
                bannerPictures.map((image, index) => {
                  return (
                    <div className="projImg" key={index}>
                      <img
                        src={image}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          margin: "10px",
                        }}
                        alt=""
                      />
                      {isEdit && (
                        <button
                          className="times"
                          onClick={() => removeBannerImage(index)}
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            background: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      )}
                    </div>
                  );
                })}
            </div>

            {isEdit && (
              <input
                type="file"
                name="Product Image"
                accept="image/*"
                onChange={(e) => handleImageChange(e, modalHeading)}
                multiple
              />
            )}
          </ModalBody>
          <ModalFooter>
            {isEdit && (
              <Button
                color="primary"
                className="modalBtn"
                // onClick={saveImages(obj, modalHeading)}
                onClick={saveImages}
              >
                Save Images
              </Button>
            )}{" "}
            {isEdit && (
              <Button
                color="secondary"
                className="modalBtn"
                onClick={modalCancel}
              >
                Cancel
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </>

      <div className="outr-right-content prdct_info">
        <div className="inner_tbl_bkng">
          <div className="tb_top pdtc">
            <div className="tb_lft product_i">
              <button
                className={`tabs_c ${tabs === "product_info" ? "active" : ""}`}
                onClick={() => setTabs("product_info")}
              >
                <span>
                  <FontAwesomeIcon icon={faBagShopping} />
                </span>
                Product Info
              </button>
              <button
                className={`tabs_c ${tabs === "prices" ? "active" : ""}`}
                onClick={() => setTabs("prices")}
              >
                <span>
                  {/* <FontAwesomeIcon icon={faSackDollar} /> */}
                  <FontAwesomeIcon icon={faLayerGroup} />
                </span>{" "}
                Variation
              </button>
              {/* <button
                      className={`tabs_c ${
                        tabs === "inventory" ? "active" : ""
                      }`}
                      onClick={() => setTabs("inventory")}
                    >
                      <span>
                        <FontAwesomeIcon icon={faBagShopping} />
                      </span>
                      Inventory
                    </button> */}
              {/* <button
                      className={`tabs_c ${tabs === "batches" ? "active" : ""}`}
                      onClick={() => setTabs("batches")}
                    >
                      <span>
                        <FontAwesomeIcon icon={faImage} />
                      </span>{" "}
                      Batches
                    </button> */}
              <button
                className={`tabs_c ${tabs === "images" ? "active" : ""}`}
                onClick={() => {
                  setTabs("images");
                  setImgurl("");
                }}
              >
                <span>
                  <FontAwesomeIcon icon={faImage} />
                </span>{" "}
                Images
              </button>
            </div>
          </div>

          {/* /// PRODUCT INFO */}

          {tabs === "product_info" && (
            <div id="product_info" className="tb_c">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!isEdit) {
                    submitProductInfo(e);
                  }
                }}
              >
                <div className="p_info">
                  <div className="e-edit">
                    {isEdit ? (
                      <button
                        type="submit"
                        className="edit"
                        onClick={() => setIsEdit(false)}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="edit"
                        onClick={() => setIsEdit(true)}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </div>
                <div className="total_p_rows">
                  {/*  */}
                  <div className="p_rows">
                    <div className="p_total" id="table_body1"></div>
                    <div className="p_total" id="table_body2">
                      <div className="p_c_lft">
                        <label htmlFor="name">Product Name</label>
                        <input
                          disabled={!isEdit}
                          required
                          id="name"
                          name="name"
                          value={productInfo?.name}
                          type="text"
                          placeholder="10Bandz"
                          onChange={(e) => changeProductInfo(e)}
                        />
                      </div>
                    </div>

                    <div className="p_total" id="table_body4">
                      <div className="p_c_lft">
                        <label htmlFor="status">Status</label>
                        <div className="form-check form-switch">
                          <label className="switch">
                            <input
                              disabled={!isEdit}
                              name="status"
                              type="checkbox"
                              checked={productInfo?.status}
                              onChange={(e) => changeProductInfo(e)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="p_total" id="table_body5">
                      <div className="p_c_lft">
                        <label>Category</label>
                        <select
                          disabled={!isEdit}
                          required
                          name="category"
                          id="category"
                          value={productInfo?.category?.name}
                          onChange={(e) => changeProductInfo(e)}
                        >
                          <option value="">Select Category</option>
                          {category &&
                            category.map((cat, i) => {
                              return (
                                <option value={cat.name} key={i}>
                                  {cat.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="p_total" id="table_body6">
                      <div className="p_c_lft">
                        <label>Sub Category</label>
                        <select
                          disabled={!isEdit}
                          name="subCategory"
                          id="subCategory"
                          value={productInfo?.subCategory?.name}
                          onChange={(e) => changeProductInfo(e)}
                        >
                          <option value="">Select sub category</option>

                          {subCategory &&
                            subCategory.map((subCat, i) => {
                              return (
                                <option value={subCat.subCategoryName} key={i}>
                                  {subCat.subCategoryName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="p_total" id="table_body7">
                      <div className="p_c_lft">
                        <label>Child Category</label>
                        <select
                          disabled={!isEdit}
                          name="childCategory"
                          id="childCategory"
                          value={productInfo?.childCategory?.name}
                          onChange={(e) => changeProductInfo(e)}
                        >
                          <option value="">Select child category</option>
                          {childCategory?.length > 0 &&
                            childCategory.map((childCatItem) => {
                              return (
                                <option
                                  key={childCatItem._id}
                                  value={childCatItem.childCategoryName}
                                >
                                  {childCatItem.childCategoryName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="p_total" id="table_body21">
                      <div className="p_c_lft">
                        <label>Brand</label>
                        <select
                          required
                          disabled={!isEdit}
                          name="brand"
                          id="brand"
                          value={productInfo?.brand?.name}
                          onChange={(e) => changeProductInfo(e)}
                        >
                          <option value="">Select brand</option>
                          {brand &&
                            brand.map((b, i) => {
                              return (
                                <option value={b.name} key={i}>
                                  {b.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="p_total" id="table_body25">
                      <div className="p_c_lft">
                        <label htmlFor="description">Description</label>
                        <input
                          required
                          disabled={!isEdit}
                          id="description"
                          name="description"
                          type="text"
                          value={productInfo?.description}
                          placeholder="Introducing 10 Bandz"
                          onChange={(e) => changeProductInfo(e)}
                        />
                      </div>
                    </div>
                    <div className="p_total" id="table_body26">
                      <div className="p_c_lft">
                        <label htmlFor="shippingCharge">Shipping Charge</label>
                        <input
                          required
                          disabled={!isEdit}
                          id="shippingCharge"
                          name="shippingCharge"
                          value={productInfo?.shippingDetails?.shippingCharge}
                          type="text"
                          placeholder="10"
                          onChange={(e) => changeProductInfo(e)}
                        />
                      </div>
                    </div>
                    <div className="p_total" id="table_body26">
                      <div className="p_c_lft">
                        <label htmlFor="freeShipping">Free Shipping</label>

                        <div className="form-check form-switch">
                          <label className="switch">
                            <input
                              disabled={!isEdit}
                              name="freeShipping"
                              type="checkbox"
                              onChange={(e) => changeProductInfo(e)}
                              checked={
                                productInfo?.shippingDetails?.freeShipping
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* potency */}
                  <div className="main_p_inform" id="tbDiv001">
                    <div className="e-edit">
                      {isEditForPotency ? (
                        <button
                          type="button"
                          className="edit"
                          onClick={(e) => {
                            setIsEditForPotency(false);
                            savePotencyData(e);
                          }}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="edit"
                          onClick={() => {
                            setIsEditForPotency(true);
                          }}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                    {main?.map((v, ind) => {
                      return (
                        <div className="p_inform" key={ind}>
                          <div className="p_total p_hdng" id="table_body">
                            <div className="p_c_lft ext">
                              <input
                                disabled={!isEditForPotency}
                                required
                                type="text"
                                placeholder="Title"
                                value={v.val}
                                className="table_body01"
                                onChange={(e) => headingChange(e, ind, v._id)}
                              />
                              <div className="otr_ttl_adbtn">
                                <span
                                  className="add_btn"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (isEditForPotency === true) {
                                      addPotency("main");
                                    }
                                  }}
                                >
                                  <div className="click_me" />
                                  <FontAwesomeIcon icon={faPlus} size="xl" />
                                </span>
                                <span
                                  className="add_btn"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (isEditForPotency === true) {
                                      removePotency("main", ind);
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon icon={faMinus} size="xl" />
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="p_rows">
                            <div className="p_total" id="table_body29">
                              {v.potency?.map((c, ci) => {
                                return (
                                  <div className="p_c_lft_dup ext" key={ci}>
                                    <input
                                      required
                                      disabled={!isEditForPotency}
                                      name="key"
                                      type="text"
                                      className="addMain"
                                      placeholder="Title"
                                      value={c.key}
                                      onChange={(e) =>
                                        changeHandler(e, ind, ci, c._id)
                                      }
                                    />
                                    <input
                                      required
                                      disabled={!isEditForPotency}
                                      name="value"
                                      type="text"
                                      className="addPrefer"
                                      value={c.value}
                                      placeholder="Description"
                                      onChange={(e) =>
                                        changeHandler(e, ind, ci, c._id)
                                      }
                                    />
                                    <div className="otr_ttl_adbtn">
                                      <span
                                        className="add_btn"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          if (isEditForPotency === true) {
                                            addPotency(ind);
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faPlus}
                                          size="xl"
                                        />
                                      </span>
                                      <span
                                        className="add_btn"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faMinus}
                                          size="xl"
                                          onClick={() => {
                                            if (isEditForPotency === true) {
                                              removePotency("other", ind, ci);
                                            }
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </form>
            </div>
          )}

          {/* /// PRICES */}
          {tabs === "prices" && (
            <Variation />
            // <div
            //   id="prices"
            //   className="tb_c"
            //   // style={{ display: "none" }}
            // >
            //   <div className="title-bx" id="price_tab">
            //     <div className="outr_title">
            //       <ul id="list" className="listitem">
            //         {title &&
            //           title.map((t, i) => (
            //             <div key={i}>
            //               <li
            //                 className="litext"
            //                 value={t}
            //                 onClick={() => {
            //                   if (isEdit === true) {
            //                     getTitle(t);
            //                   }
            //                 }}
            //               >
            //                 {t}
            //               </li>
            //               {/* <span>
            //                 <FontAwesomeIcon
            //                   icon={faMinus}
            //                   onClick={() => deleteTitle(t)}
            //                 />
            //               </span> */}
            //             </div>
            //           ))}
            //       </ul>
            //       <button
            //         type=""
            //         className="add_title tab_lst_add"
            //         onClick={() => {
            //           if (isEdit === true) {
            //             createTitle();
            //           }
            //         }}
            //       >
            //         <FontAwesomeIcon icon={faPlus} />
            //       </button>
            //     </div>

            //     <div
            //       className={`${
            //         showCreateTitle
            //           ? "title_add_outr"
            //           : "title_add_outr hidden"
            //       }`}
            //     >
            //       <form onSubmit={addTitle}>
            //         <input
            //           required
            //           type="text"
            //           // defaultValue=""
            //           value={createTitleName}
            //           className="input-field"
            //           onChange={(e) => {
            //             const inputVal = e.target.value;
            //             const capitalizedVal =
            //               inputVal.charAt(0).toUpperCase() +
            //               inputVal.slice(1).toLocaleLowerCase();
            //             setCreateTitleName(capitalizedVal);
            //           }}
            //         />
            //         <button
            //           // type="button"
            //           type="submit"
            //           className="add__btn"
            //           // onClick={addTitle}
            //         >
            //           ADD
            //         </button>
            //       </form>
            //     </div>
            //     <div id="pro_header">
            //       <form onSubmit={submitPriceVariant}>
            //         {/* <form > */}
            //         <div className="outr_all_header">
            //           <div className="all_otr">
            //             <div className="all_title">
            //               {/* {arr.length > 0 ? arr.map} */}
            //               {inputFields &&
            //                 inputFields.map((input, i) => {
            //                   return (
            //                     <>
            //                       <div
            //                         className="input-field-outr"
            //                         key={i}
            //                       >
            //                         <input
            //                           name={Object.keys(input)[0]}
            //                           type="text"
            //                           defaultValue={
            //                             input[Object.keys(input)[0]]
            //                           }
            //                           onChange={(e) =>
            //                             chngeFirstIndexData(e, i)
            //                           }
            //                         />
            //                         <button
            //                           type="button"
            //                           className="edit"
            //                         >
            //                           Save
            //                         </button>
            //                         <button
            //                           type="button"
            //                           className="edit"
            //                         >
            //                           Upload Image
            //                         </button>
            //                       </div>
            //                     </>
            //                   );
            //                 })}
            //               <button
            //                 type="button"
            //                 className="add_row tab_lst_add"
            //                 onClick={() => {
            //                   if (isEdit === true) {
            //                     addField();
            //                   }
            //                 }}
            //               >
            //                 <FontAwesomeIcon icon={faPlus} />
            //               </button>
            //             </div>

            //             {arr &&
            //               arr.map((obj, objIndex) => {
            //                 const arrFromObj = Object.keys(obj);

            //                 return (
            //                   <div className="all_title">
            //                     {arrFromObj.map((key, keyIndex) => {
            //                       return (
            //                         <>
            //                           {key !== "id" && (
            //                             <div
            //                               className="input-field-outr"
            //                               key={objIndex}
            //                             >
            //                               {key === "Product Image" ||
            //                               key === "Banner Image" ? (
            //                                 <button
            //                                   type="button"
            //                                   href="javascript:void(0);"
            //                                   className="edit"
            //                                   onClick={() => {
            //                                     toggle(
            //                                       key,
            //                                       "arr",
            //                                       objIndex
            //                                     );
            //                                     setIndexOfArr(objIndex);
            //                                     // showImageNum(obj, key);
            //                                   }}
            //                                 >
            //                                   {isEdit ? "Upload" : "View"}{" "}
            //                                   {isEdit && (
            //                                     <FontAwesomeIcon
            //                                       icon={faCloudArrowUp}
            //                                       // size="xl"
            //                                     />
            //                                   )}{" "}
            //                                   {key === "Product Image" &&
            //                                     obj["Product Image"]
            //                                       .length}
            //                                   {key === "Banner Image" &&
            //                                     obj["Banner Image"]
            //                                       .length}
            //                                 </button>
            //                               ) : (
            //                                 <input
            //                                   disabled={!isEdit}
            //                                   // required
            //                                   type="text"
            //                                   name={key}
            //                                   defaultValue={obj[key]}
            //                                   key={keyIndex}
            //                                   onChange={(e) =>
            //                                     changeOtherIndexData(
            //                                       e,
            //                                       objIndex
            //                                     )
            //                                   }
            //                                 />
            //                               )}
            //                             </div>
            //                           )}
            //                         </>
            //                       );
            //                     })}

            //                     <button
            //                       type="button"
            //                       className="add_row tab_lst_add"
            //                       onClick={() => {
            //                         if (isEdit === true) {
            //                           addField();
            //                         }
            //                       }}
            //                     >
            //                       <FontAwesomeIcon icon={faPlus} />
            //                     </button>
            //                   </div>
            //                 );
            //               })}
            //           </div>

            //           <br />
            //           {/* <button
            //           type="button"
            //           className="delete_btn hidden tab_lst_add"
            //           onClick="removeItem(this)"
            //         >
            //           <FontAwesomeIcon icon={faMinus} />
            //         </button> */}
            //           <button
            //             type="submit"
            //             // type="button"
            //             className="edit"
            //             // onClick={submitPriceVariant}
            //           >
            //             {isEdit ? "Save" : "Edit"}
            //           </button>
            //         </div>
            //       </form>
            //     </div>
            //   </div>
            // </div>
          )}

          {/* /// INVENTORY */}
          {tabs === "inventory" && (
            <div id="inventory" className="tb_c" style={{ display: "none" }}>
              {/* ayon paul start / 05.03.2024 */}
              <div className="ivnt_ottr">
                <div className="ivnt_tp_btns">
                  <div className="ivnt_lft_btn">
                    <a href="javascript:void(0);" className="edit">
                      Show Inventory per Region
                    </a>
                  </div>
                  <div className="ivnt_rght_btn">
                    <a href="javascript:void(0);" className="edit">
                      Refresh Inventory
                    </a>
                  </div>
                </div>
                <div className="ivnt_units">
                  <span>Total Stock Remaining: 3.00 units</span>
                  <span>Total Committed (Online): 0 units</span>
                </div>
                <div className="ivnt_tbl_ttl">
                  <div className="ivnt_tbl_hdng">
                    <h5>Inventory Transfer</h5>
                  </div>
                  <form className="ivnt_tbl_form">
                    <div className="ivnt_tbl_strt">
                      <div className="ivnt_tbl_prt">
                        <label>From</label>
                        <select name="transfer_from" className="form-control">
                          <option value="from_value">Select</option>
                          <option value="from_value">Safe</option>
                          <option value="from_value">Exchange</option>
                        </select>
                      </div>
                      <div className="ivnt_tbl_prt">
                        <label>To</label>
                        <select name="transfer_to" className="form-control">
                          <option value="to_value">Select</option>
                          <option value="to_value">Safe</option>
                          <option value="to_value">Exchange</option>
                        </select>
                      </div>
                      <div className="ivnt_tbl_prt" id="ivnt_tb_amnt">
                        <label>Amount</label>
                        <input
                          type="number"
                          name="transfer_amount"
                          className="form-control"
                          defaultValue={0}
                        />
                      </div>
                    </div>
                    <div className="ivnt_tbl_chk">
                      <input type="checkbox" name="trns_chk" id="trns_chk" />
                      <label>Transfer By Batch</label>
                    </div>
                  </form>
                  <div className="ivnt_trns_actv" id="ivnt_actv">
                    <table className="ivnt_trns_tbl">
                      <thead>
                        <tr>
                          <th>Batch</th>
                          <th>Start Inventory</th>
                          <th>Transfer Amount</th>
                          <th />
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className="payment_filter ivnt_filter">
                    <div className="payment_filter_both">
                      <div className="payment_filter_lft">
                        <label> Active Inventories </label>
                      </div>
                    </div>
                  </div>
                  <div className="oder_history dbrd_inv_history">
                    <div className="orders dbrd_inv_orders">
                      <div className="ordr_tbl dbrd_inv_ordr_tbl ivnt_td_ottr">
                        <table>
                          <thead>
                            <tr>
                              <th>Inventory</th>
                              <th>Current Quantity</th>
                              <th>On Hold</th>
                              <th>Pending Transfer</th>
                              <th>Send Low Inventory Notification</th>
                              <th>Low Inventory Threshold</th>
                              <th>Inventory PAR Level</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Safe</td>
                              <td>2 units</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>No</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>
                                <div className="dbrd_inv_cl_btn">
                                  <a href="javascript:void(0);">Edit</a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Blaze Test Inv</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>No</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>
                                <div className="dbrd_inv_cl_btn">
                                  <a href="javascript:void(0);">Edit</a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Employee Orders</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>No</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>
                                <div className="dbrd_inv_cl_btn">
                                  <a href="javascript:void(0);">Edit</a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Safe</td>
                              <td>2 units</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>No</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>
                                <div className="dbrd_inv_cl_btn">
                                  <a href="javascript:void(0);">Edit</a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Blaze Test Inv</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>No</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>
                                <div className="dbrd_inv_cl_btn">
                                  <a href="javascript:void(0);">Edit</a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Employee Orders</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>No</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>
                                <div className="dbrd_inv_cl_btn">
                                  <a href="javascript:void(0);">Edit</a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Safe</td>
                              <td>2 units</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>No</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>
                                <div className="dbrd_inv_cl_btn">
                                  <a href="javascript:void(0);">Edit</a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Blaze Test Inv</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>No</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>
                                <div className="dbrd_inv_cl_btn">
                                  <a href="javascript:void(0);">Edit</a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Employee Orders</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>No</td>
                              <td>0 unit</td>
                              <td>0 unit</td>
                              <td>
                                <div className="dbrd_inv_cl_btn">
                                  <a href="javascript:void(0);">Edit</a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ivnt_ottr">
                <div className="payment_filter ivnt_filter">
                  <div className="payment_filter_both ivnt_filter_btn">
                    <a href="javascript:void(0);" className="edit">
                      Show Inventory per Region
                    </a>
                  </div>
                </div>
                <div className="oder_history dbrd_inv_history">
                  <div className="orders dbrd_inv_orders">
                    <div className="ordr_tbl dbrd_inv_ordr_tbl ivnt_bttm_td_ottr">
                      <table>
                        <thead />
                        <tbody>
                          <tr>
                            <td>Send Low Inventory Notification</td>
                            <td>No</td>
                            <td />
                          </tr>
                          <tr>
                            <td>Low Inventory Threshold</td>
                            <td>0</td>
                            <td />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* ayon paul end / 05.03.2024 */}
            </div>
          )}

          {/* /// BATCHES */}
          {tabs === "batches" && (
            <div id="batches" className="tb_c" style={{ display: "none" }}>
              <div className="p_info batch">
                <div className="batch_top">
                  <div className="p_info_hdr">
                    <h6>Batches</h6>
                  </div>
                  <div className="e-edit">
                    <a href="javascript:void(0);" className="edit">
                      Refresh Inventory
                    </a>
                  </div>
                </div>
                <div className="batch_info">
                  <table>
                    <thead>
                      <tr>
                        <th>Purchase Date</th>
                        <th>Batch Id</th>
                        <th>Unique #</th>
                        <th>Expiration Date</th>
                        <th>Status</th>
                        <th>Tracking System</th>
                        <th>Purchase Quantity</th>
                        <th>Current Quantity</th>
                        <th>Unit Cost</th>
                        <th>Price</th>
                        <th>Others</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>10/05/2023</td>
                        <td>1A4060300032964000221615</td>
                        <td>H231BAT1</td>
                        <td>09/23/2024</td>
                        <td>Active</td>
                        <td>
                          METRC
                          <br />
                          1A4060300032964000221615
                        </td>
                        <td>320</td>
                        <td>
                          3 <span id="myBtn">More Quantity</span>
                        </td>
                        <td>$25.00</td>
                        <td>$8000.00</td>
                        <td>
                          <div className="btns">
                            <a
                              href="javascript:void(0);"
                              className="edit return"
                            >
                              Return To Vendor
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="edit split"
                            >
                              Split
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="edit finish"
                            >
                              Finish
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {/* /// IMAGES */}
          {tabs === "images" && (
            <div
              id="images"
              className="tb_c"
              style={{
                // display: "none",
                marginTop: "20px",
              }}
            >
              {/* {console.log(variantts, "abc")} */}

              <select
                onChange={(e) => {
                  console.log(e.target.value, "abc");
                  setImgurl(e.target.value);
                }}
                style={{ fontSize: "25px", marginBottom: "50px" }}
              >
                <option value="">--Select an variant --</option>
                {variantts?.map((eachvar) => {
                  return (
                    <option
                      key={eachvar?._id}
                      // value={eachvar?.productPictures?.[0]?.url ?? "no pic"}
                      // value={
                      //   eachvar?.productPictures?.filter((img) => img.thumbnail)
                      //     .length === 0
                      //     ? "no pic"
                      //     : eachvar?.productPictures?.filter(
                      //         (img) => img.thumbnail
                      //       )[0].url
                      // }
                      value={
                        eachvar?.productPictures.length < 1
                          ? "no pic"
                          : eachvar?.productPictures.filter(
                              (v) => v.thumbnail
                            )[0]?.url ?? "no pic"
                      }
                    >{`Price: ${eachvar?.variant.Price} Color: ${eachvar?.variant.Color}`}</option>
                  );
                })}
              </select>

              {imgurl == "no pic" ? (
                <h1>No thumbnail image is available for this variant.</h1>
              ) : (
                <img src={imgurl} />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
