import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as rules from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import { AddNewSeller } from "../../redux/AdminSlice/AdminSlicer";
import { Bounce, toast } from "react-toastify";

const AddSeller = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, firstAdminCreated } = useSelector((a) => a.AboutAdminn);
  const validationSchema = rules.object().shape({
    email: rules
      .string()
      .email("Invalid email format")
      .required("Please type the email"),
    password: rules
      .string()
      .required("Please type the Password")
      .min(4, "Minimum 4 characters are needed."),
    phone: rules
      .string()
      .matches(
        /^(?:\(\d{3}\)\s?|\d{3}[-.\s]?)(\d{3})[-.\s]?(\d{4})$/,
        "Invalid phone number format"
      )
      .required("Phone number is required"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      phone: "",
      //   conPass: "",
    },
    validationSchema,
    onSubmit: (val, ac) => {
      dispatch(AddNewSeller({ val, navigate })).then((a) => {
        if (a?.payload?.success) {
          ac.resetForm();
        }
      });
      //     if (val?.password === val?.conPass) {
      //   } else {
      //     toast.error(
      //       "Confirmation password doesn’t match. Please enter the same password.",
      //       {
      //         className: "toast-message",
      //         position: "top-center",
      //         autoClose: 5000,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //         transition: Bounce,
      //       }
      //     );
      //   }
    },
  });
  return (
    <>
      <section className="sign_up">
        <div className="sign_up_otr">
          <div className="sign_up_innr">
            <div className="sgn_hdr text-center">
              <h3>Add new seller</h3>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="frm_innr">
                <div className="inpt">
                  <input
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.email && touched.email ? errors.email : null}
                </div>
                <div className="inpt">
                  <input
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.password && touched.password ? errors.password : null}
                </div>
                {/* <div className="inpt">
                  <input
                    name="conPass"
                    placeholder="Confirm password"
                    value={values.conPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.conPass && touched.conPass ? errors.conPass : null}
                </div> */}
                <div className="inpt">
                  <input
                    type="number"
                    name="phone"
                    placeholder="Phone number"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.phone && touched.phone ? errors.phone : null}
                </div>

                <div className="">
                  <input
                    type="submit"
                    value={loading ? "Loading..." : "Submit"}
                  />
                </div>
                <div className="">
                  <input
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/choseuser");
                    }}
                    type="submit"
                    value={"Go to seller log in page"}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddSeller;
