import React from "react";

const BillingCheckOut = () => {
  return (
    <>
      <div className="outr-rght-content">
        <div className="sls_flx_cntnt bl_chk_cntnt">
          <div className="sls_lft_drv">
            <div className="top_drv">
              <div className="top_billchk_top">
                <div className="billchk_brdcrm" aria-label="breadcrumb">
                  <ol className="breadcrumb billchk_brdcrm_list">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">POS</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Billing</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href="javascript:void(0);">Checkout</a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <form className="pmnt_dtls_frm">
              <div className="pmnt_dtls_ttl">
                <div className="pmnt_dtls_lft">
                  <div className="pmnt_dtls_lft_top">
                    <h6>Payments</h6>
                    <div className="pmnt_dtls_lft_rdos">
                      <div className="pmnt_lft_rdo_grp">
                        <div className="pmnt_lft_rdo_optn">
                          <input type="radio" defaultValue="rd" />
                        </div>
                        <label>Full payment</label>
                      </div>
                      <div className="pmnt_lft_rdo_grp">
                        <div className="pmnt_lft_rdo_optn">
                          <input type="radio" defaultValue="rd" />
                        </div>
                        <label>Split payment</label>
                      </div>
                    </div>
                  </div>
                  <div className="pmnt_dtls_lft_mn active">
                    <div className="pmnt_dtls_lft_crds">
                      <button className="pmnt_dtls_crds_btn">
                        <label className="cntnr">
                          <input defaultChecked="" type="checkbox" />
                          <div className="checkmark" />
                        </label>
                        <span className="bl_chk_txt">Cards</span>
                        <span className="bl_chk_img">
                          <img
                            src={require("../../../assets/images-pos/bl_pmnt_1.png")}
                            alt="img"
                          />
                          {/* <img src={require('../../../assets/images')} /> */}
                        </span>
                      </button>
                    </div>
                    <div className="pmnt_dtls_rght_crds">
                      <input type="text" className="dllr" />
                    </div>
                  </div>
                  <div className="pmnt_dtls_lft_mn">
                    <div className="pmnt_dtls_lft_crds">
                      <button className="pmnt_dtls_crds_btn">
                        <label className="cntnr">
                          <input type="checkbox" />
                          <div className="checkmark" />
                        </label>
                        <span className="bl_chk_txt">Gift Cards</span>
                        <span className="bl_chk_img">
                          <img
                            src={require("../../../assets/images-pos/bl_pmnt_2.png")}
                            alt="img"
                          />
                        </span>
                      </button>
                    </div>
                    <div className="pmnt_dtls_rght_crds">
                      <input type="text" className="dllr" />
                    </div>
                  </div>
                  <div className="pmnt_dtls_lft_mn">
                    <div className="pmnt_dtls_lft_crds">
                      <button className="pmnt_dtls_crds_btn">
                        <label className="cntnr">
                          <input type="checkbox" />
                          <div className="checkmark" />
                        </label>
                        <span className="bl_chk_txt">Credits</span>
                        <span className="bl_chk_img">
                          <img
                            src={require("../../../assets/images-pos/bl_pmnt_3.png")}
                            alt="img"
                          />
                        </span>
                      </button>
                    </div>
                    <div className="pmnt_dtls_rght_crds">
                      <input type="text" className="dllr" />
                    </div>
                  </div>
                  <div className="pmnt_dtls_lft_mn">
                    <div className="pmnt_dtls_lft_crds">
                      <button className="pmnt_dtls_crds_btn">
                        <label className="cntnr">
                          <input type="checkbox" />
                          <div className="checkmark" />
                        </label>
                        <span className="bl_chk_txt">Cash</span>
                        <span className="bl_chk_img">
                          <img
                            src={require("../../../assets/images-pos/bl_pmnt_4.png")}
                            alt="img"
                          />
                        </span>
                      </button>
                    </div>
                    <div className="pmnt_dtls_rght_crds">
                      <input type="text" className="dllr" />
                    </div>
                  </div>
                </div>
                <div className="pmnt_dtls_rght">
                  <div className="pmnt_dtls_lft_top">
                    <h6>Card Details</h6>
                    <div className="pmnt_crd_mthd_ttls">
                      <div className="pmnt_dtls_crds_mthd">
                        <span>
                          <img
                            src={require("../../../assets/images-pos/visa.png")}
                            alt="img"
                          />
                        </span>
                      </div>
                      <div className="pmnt_dtls_crds_mthd">
                        <span>
                          <img
                            src={require("../../../assets/images-pos/mstr_crd.png")}
                            alt="img"
                          />
                        </span>
                      </div>
                      <div className="pmnt_dtls_crds_mthd">
                        <span>
                          <img
                            src={require("../../../assets/images-pos/cruis.png")}
                            alt="img"
                          />
                        </span>
                      </div>
                      <div className="pmnt_dtls_crds_mthd">
                        <span>
                          <img
                            src={require("../../../assets/images-pos/dis.png")}
                            alt="img"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="pmnt_dtls_rght_mn">
                    <div className="form-group">
                      <label>Card No:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0000 0000 0000 0000"
                        maxLength={16}
                      />
                    </div>
                    <div className="form-group">
                      <label>Name on Card:</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="cvv_prt">
                      <div className="form-group">
                        <label>Expiry Date (MM/YY):</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label>CVV:</label>
                        <input type="number" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
                HOME
                <div className="ship_info_ottr">
                  <div className="pmnt_dtls_lft_top">
                    <h6>Shipping Information</h6>
                    <div className="pos_ordr_dtls_bttm">
                      <button className="chk_ot">
                        Add New{" "}
                        <span>
                          <i className="fas fa-plus-circle" />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="ship_info_form_ottr">
                    <div className="ship_info_form_inr">
                      <div className="form-group">
                        <label>
                          Country/Region<span>*</span>
                        </label>
                        <select className="form-control">
                          <option value="Country/Region">-select-</option>
                          <option value="Country/Region">-select-</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Company (optional)</label>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone No"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Address<span>*</span>
                        </label>
                        <input type="tel" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label>
                          City<span>*</span>
                        </label>
                        <select className="form-control">
                          <option value="City">-select-</option>
                          <option value="City">-select-</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>
                          Postcode<span>*</span>
                        </label>
                        <input type="tel" className="form-control" />
                      </div>
                      <div className="form-group">
                        <div className="cnt_shp_bttn_flx">
                          <div className="cnt_shp_lft">
                            <input type="checkbox" className="bl_chkbx" />
                            <span>Save this information for next time.</span>
                          </div>
                          <div className="cnt_shp_bttn">
                            <div className="pos_ordr_dtls_bttm">
                              <button className="chk_ot">
                                Continue Shipping
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="sls_rght_drv">
            <div className="rcsnt_trnsct">
              <div className="odd_smry">
                <h6>Order Summary</h6>
                <div className="cpn_apply">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Gift card or coupon code"
                  />
                  <div className="cpn_apply_bttn">
                    <div className="pos_ordr_dtls_bttm">
                      <button className="chk_ot">Apply</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos_ordr_dtls">
                <div className="pos_ordr_dtls_uppr">
                  <div className="ordr_dtls_top">
                    <h6>Order details</h6>
                    <div className="ordr_dtls_top_icns">
                      <button className="nw_bl_svg">
                        <img
                          src={require("../../../assets/images-pos/printer.png")}
                          alt="img"
                        />
                      </button>
                      <button className="nw_bl_svg">
                        <img
                          src={require("../../../assets/images-pos/dwnd.png")}
                          alt="img"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="bll_chk_prdt_prt">
                    <div className="pos_prdt_flx bll_chk_prdt_flx">
                      <div className="pos_prdt_flx_lft">
                        <span>1.</span>
                        <div className="pos_prdt_nme">
                          <label>Product Name goes here</label>
                        </div>
                      </div>
                      <div className="pos_prdt_flx_rght">
                        <span className="mn_prc">$25.00</span>
                      </div>
                    </div>
                    <div className="bll_chk_prdt_dtls">
                      <div className="bll_chk_dtls_lft">
                        <label className="bll_chk_dtls_lbl">
                          Size: <span>500g</span>
                        </label>
                      </div>
                      <div className="bll_chk_dtls_rght">
                        <span className="rd_mrk">55% off</span>
                        <span className="del_wrk">
                          <del>$45.00</del>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bll_chk_prdt_prt">
                    <div className="pos_prdt_flx bll_chk_prdt_flx">
                      <div className="pos_prdt_flx_lft">
                        <span>1.</span>
                        <div className="pos_prdt_nme">
                          <label>Product Name goes here</label>
                        </div>
                      </div>
                      <div className="pos_prdt_flx_rght">
                        <span className="mn_prc">$25.00</span>
                      </div>
                    </div>
                    <div className="bll_chk_prdt_dtls">
                      <div className="bll_chk_dtls_lft">
                        <label className="bll_chk_dtls_lbl">
                          Size: <span>500g</span>
                        </label>
                      </div>
                      <div className="bll_chk_dtls_rght">
                        <span className="rd_mrk">55% off</span>
                        <span className="del_wrk">
                          <del>$45.00</del>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bll_chk_prdt_prt">
                    <div className="pos_prdt_flx bll_chk_prdt_flx">
                      <div className="pos_prdt_flx_lft">
                        <span>1.</span>
                        <div className="pos_prdt_nme">
                          <label>Product Name goes here</label>
                        </div>
                      </div>
                      <div className="pos_prdt_flx_rght">
                        <span className="mn_prc">$25.00</span>
                      </div>
                    </div>
                    <div className="bll_chk_prdt_dtls">
                      <div className="bll_chk_dtls_lft">
                        <label className="bll_chk_dtls_lbl">
                          Size: <span>500g</span>
                        </label>
                      </div>
                      <div className="bll_chk_dtls_rght">
                        <span className="rd_mrk">55% off</span>
                        <span className="del_wrk">
                          <del>$45.00</del>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bll_chk_prdt_prt">
                    <div className="pos_prdt_flx bll_chk_prdt_flx">
                      <div className="pos_prdt_flx_lft">
                        <span>1.</span>
                        <div className="pos_prdt_nme">
                          <label>Product Name goes here</label>
                        </div>
                      </div>
                      <div className="pos_prdt_flx_rght">
                        <span className="mn_prc">$25.00</span>
                      </div>
                    </div>
                    <div className="bll_chk_prdt_dtls">
                      <div className="bll_chk_dtls_lft">
                        <label className="bll_chk_dtls_lbl">
                          Size: <span>500g</span>
                        </label>
                      </div>
                      <div className="bll_chk_dtls_rght">
                        <span className="rd_mrk">55% off</span>
                        <span className="del_wrk">
                          <del>$45.00</del>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos_ordr_dtls_lwr">
                  <div className="pos_ordr_ttl_dtls">
                    <label>
                      Sub-total: <span>$135.00</span>
                    </label>
                  </div>
                  <div className="pos_ordr_ttl_dtls">
                    <label>
                      Discount: <span className="red">- $55.00</span>
                    </label>
                  </div>
                </div>
                <div className="pos_ordr_lst_dtls">
                  <label>
                    Total: <span> $75.00</span>
                  </label>
                </div>
                <div className="pos_ordr_dtls_bttm">
                  <button className="chk_ot">Complete Order</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingCheckOut;
